import React, { useEffect, useState } from "react";
import { Layout, Button, Input, Select, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { get } from "../../services/Apiclient";
import Datatable from "../../components/Datatable";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const { Option } = Select;
const { Content } = Layout;

interface ProductData {
  key: React.Key;
  productcode: string;
  productname: string;
  category: string;
  selling_price: string;
  status: string;
}

const columns = [
  {
    title: "Product Code",
    dataIndex: "productcode",
    key: "productcode",
    width: 150,
    sorter: (a: ProductData, b: ProductData) =>
      a.productcode.localeCompare(b.productcode),
  },
  {
    title: "Product Name",
    dataIndex: "productname",
    key: "productname",
    width: 200,
    sorter: (a: ProductData, b: ProductData) =>
      a.productname.localeCompare(b.productname),
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    width: 150,
  },
  {
    title: "Selling Price",
    dataIndex: "selling_price",
    key: "selling_price",
    width: 150,
    sorter: (a: ProductData, b: ProductData) =>
      parseFloat(a.selling_price) - parseFloat(b.selling_price),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 100,
  },
  {
    title: "Action",
    key: "operation",
    fixed: "right" as "right",
    width: 100,
    render: (): React.ReactNode => <a>Action</a>,
  },
];

function CategoryList() {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<ProductData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `/api/product/limit/${currentPage}/${pageSize}/null/productname/DESC`;
        const response = await get(url);
        if (response?.data?.success) {
          const apiData = response.data.data.map((item: any) => ({
            key: item.id,
            productcode: item.productcode,
            productname: item.productname,
            category: item.category,
            selling_price: item.selling_price,
            status: item.status,
          }));
          setDataSource(apiData);
          setTotalRecords(response.data.totalRecords);
        } else {
          console.log("Error in API call: ", response);
        }
      } catch (error) {
        console.error("Fetch error: ", error);
      }
    };

    fetchData();
  }, [currentPage, pageSize]);

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  return (
    <Content style={{ margin: "16px" }}>
      {/* Header Section */}
      <Row justify="space-between" align="middle">
        <Col>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>Category List</h2>
          </div>
        </Col>
        <Col>
          <Button type="primary" icon={<PlusOutlined />}>
            Add
          </Button>
        </Col>
      </Row>

      {/* Content Section */}
      <Content
        style={{
          marginTop: "10px",

          backgroundColor: "#f6f7f9",
          borderRadius: "12px",
          padding: "24px 24px 10px",
          border: "1px solid #e0e7fe",
          boxSizing: "border-box",
        }}
      >
        {/* Filters */}
        <Row gutter={16} style={{ marginBottom: "16px" }}>
          <Col flex="auto">
            <Input.Search
              placeholder="Search by customer code, name, phone number, email"
              allowClear
              style={{ width: "30%" }}
            />
          </Col>
        </Row>

        {/* Table */}
        <Datatable<ProductData>
          columns={columns}
          dataSource={dataSource}
          currentPage={currentPage}
          pageSize={pageSize}
          totalRecords={totalRecords}
          onPageChange={handlePageChange}
          scroll={{ y: 500 }}
          showCheckbox // Custom scroll settings for this instance
        />

        {/* Buttons */}
        <Row justify="start">
          <Col>
            <Button disabled type="default" style={{ marginRight: "10px" }}>
              Delete all selected
            </Button>
          </Col>
          <Col>
            <Button type="primary">Duplicate & Distribute</Button>
          </Col>
        </Row>
      </Content>
    </Content>
  );
}

export default CategoryList;
