import React, { useState } from "react";
import { Modal, Button, Form, Input } from "antd";

interface ManagerAuthorizationnModelProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (data: { authorizationcode: number }) => void;
  staffcode: number;
  authorizationcode: number
}

const ManagerAuthorizationModel: React.FC<ManagerAuthorizationnModelProps> = ({
  visible,
  onCancel,
  onSave,
  staffcode,
  authorizationcode
}) => {
  const [formData, setFormData] = useState({
    staffcode: staffcode,
    authorizationcode: authorizationcode,
  });

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    
  };

  const handleSave = () => {
    onSave(formData);
  };

  return (
    <Modal
      title="Manager authorization"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
      width={400}
      className="add-model"
    >
      <Form layout="vertical" style={{marginTop:"20px"}}>
        <Form.Item
          label="Staff Code"
          style={{ marginLeft: "24px", marginRight: "24px" }}
        >
          <Input
            name="staffCode" 
            value={formData.staffcode}
            onChange={handleFieldChange}
            disabled
          />
        </Form.Item>
        <Form.Item
          label="Authorization Code"
          style={{ marginLeft: "24px", marginRight: "24px" }}
        >
          <Input
          type="number"
            name="authorizationcode" 
            placeholder="Please enter the 6-digit authorization code"
            value={formData.authorizationcode}
            onChange={handleFieldChange}
            maxLength={6} 
          />
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "12px",
            marginTop: "40px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            type="primary"
            onClick={handleSave}
            style={{ marginRight: "24px" }}
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ManagerAuthorizationModel;
