import React from "react";
import { Modal, Row, Col, InputNumber, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

interface ReminderModalProps {
  title: string;
  reminders: Array<{ interval: string; value: string | number }>;
  visible: boolean;
  onCancel: () => void;
  onSave: () => void;
  handleReminderChange: (
    index: number,
    field: string,
    value: string | number,
    type: string
  ) => void;
  addReminder: (type: string) => void;
  removeReminder: (index: number, type: string) => void;
  type: string;
  maxReminders: number;
}
const ReminderModal: React.FC<ReminderModalProps> = ({
  title,
  reminders,
  visible,
  onCancel,
  onSave,
  handleReminderChange,
  addReminder,
  removeReminder,
  type,
  maxReminders,
}) => {
  return (
    <Modal
      title={<h3 style={{ fontWeight: "bold" }}>{title}</h3>}
      open={visible}
      onOk={onSave}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel} style={{ borderRadius: "8px" }}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSave}
          style={{ borderRadius: "8px" }}
        >
          Save
        </Button>,
      ]}
    >
      {reminders.map((reminder, index) => (
        <Row gutter={16} key={index} style={{ marginBottom: 16 }}>
          <Col span={20}>
            <InputNumber
              value={reminder.value}
              min={1}
              type="number"
              max={365}
              onChange={(value) =>
                handleReminderChange(index, "value", value ?? 0, "consumption")
              }
              placeholder="Day"
              style={{ width: "100%", borderRadius: "8px" }}
            />
          </Col>
          <Col span={4} style={{ textAlign: "right" }}>
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => removeReminder(index, type)}
              disabled={reminders.length <= 1}
              style={{ color: "#999", padding: 0 }}
            />
          </Col>
        </Row>
      ))}
      {reminders.length < maxReminders && (
        <Button
          type="link"
          onClick={() => addReminder(type)}
          style={{ padding: 0, marginTop: "8px", color: "#FF6F00" }}
        >
          + Add
        </Button>
      )}
    </Modal>
  );
};
export default ReminderModal;
