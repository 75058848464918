import React, { useState } from "react";
import { Modal, Typography, Switch, Input, Button, Space } from "antd";
import {
  InfoCircleOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  CheckCircleOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;

interface ChangePasswordModalProps {
  visible: boolean;
  onClose: () => void;
  showPassword: boolean;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  visible,
  onClose,
  showPassword,
}) => {
  const [status, setStatus] = useState(false);

  return (
    <Modal
      visible={visible}
      title={
        <Title level={4} style={{ margin: 0 }}>
          Change Password
        </Title>
      }
      onCancel={onClose}
      footer={null}
      centered
      width={500}
    >
      <div style={{ marginBottom: "20px" }}>
        <Space align="center">
          <Text>Status</Text>
          <Switch checked={status} onChange={() => setStatus(!status)} />
          {!showPassword && (
            <Text style={{ color: "#faad14" }}>
              <InfoCircleOutlined style={{ marginRight: "5px" }} />
              Password not set
            </Text>
          )}
          {showPassword && (
            <Text
              type="success"
              style={{ display: "flex", alignItems: "center", gap: "5px" }}
            >
              <CheckCircleOutlined />
              Password already set
            </Text>
          )}
        </Space>
      </div>

      {status && (
        <div style={{ marginBottom: "20px" }}>
          <Text strong>* New Password</Text>
          <Input.Password
            placeholder="Enter new password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            style={{ marginTop: "5px", backgroundColor: "#f0f5ff" }}
          />
        </div>
      )}

      {status && (
        <div style={{ marginBottom: "20px" }}>
          <Text strong>* Verify Password</Text>
          <Input.Password
            placeholder="Please enter"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            style={{ marginTop: "5px", backgroundColor: "#f0f5ff" }}
          />
        </div>
      )}

      <div style={{ textAlign: "right" }}>
        <Button type="primary" onClick={onClose}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
