import { Card } from "antd";
import { Link } from "react-router-dom";

const SettingCard = ({ Image, title, link, bgColor }: any) => (
  <Link to={link} style={{ textDecoration: "none" }}>
    <Card
      hoverable
      style={{
        width: "200px",
        height: "65px",
        borderRadius: "8px",
        border: "1px solid #e0e7fe",
        display: "flex",
        alignItems: "center",
      }}
      bodyStyle={{
        display: "flex",
        alignItems: "center",
        padding: "16px",
      }}
    >
      <div
        style={{
          marginRight: "16px",
          fontSize: "20px",
          color: "#000",
          display:"flex",
          alignItems: "center",
        }}
      >
        <img
          src={Image}
          alt={"Image"}
          style={{
            width: "35px",
            height: "35px",
            borderRadius: "50%", // Make the image circular
            objectFit: "cover",
          }}
        />
      </div>
      <div>
        <div style={{ fontSize: "13px", fontWeight: "700", color: "#666" }}>
          {title}
        </div>
      </div>
    </Card>
  </Link>
);

export default SettingCard;
