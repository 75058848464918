import React, { useEffect, useState } from "react";
import { Upload, Typography, message } from "antd";
import { CameraOutlined, CloseOutlined } from "@ant-design/icons";
import "./ImageUpload.css";

const { Text } = Typography;

const ImageUpload = ({
  prefilledImages = [],
  onImagesChange,
  onDeleteImage,
}: any) => {
  const [images, setImages] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageFiles, setImageFiles] = useState<File[]>([]);

  useEffect(() => {
    // Pre-fill images if provided
    if (prefilledImages.length > 0) {
      setImages(prefilledImages); // Set the prefilled images
    }
  }, [prefilledImages]);

  const handleUpload = async (fileList: any) => {
    const currentImageCount = images.length;

    if (currentImageCount + fileList.length > 5) {
      message.error("You can only upload up to 5 images.");
      return false;
    }

    const newImages = Array.from(fileList).map((file: any) =>
      URL.createObjectURL(file)
    );

    const updatedImages: any = [...images, ...newImages];
    const updatedImageFiles: any = [...imageFiles, ...fileList];

    setImages(updatedImages);
    setImageFiles(updatedImageFiles);

    const formData = new FormData();
    Array.from(updatedImageFiles).forEach((file: any) => {
      formData.append("file", file);
    });

    onImagesChange(formData);

    return false;
  };

  const handleRemoveImage = (index: any) => {
    // Remove image and its corresponding file from the state
    const updatedImages = images.filter((_, imgIndex) => imgIndex !== index);
    const updatedImageFiles = imageFiles.filter(
      (_, imgIndex) => imgIndex !== index
    );

    setImages(updatedImages);
    setImageFiles(updatedImageFiles);

    const formData = new FormData();
    updatedImageFiles.forEach((file: any) => {
      formData.append("file", file);
    });

    onImagesChange(formData);
    onDeleteImage(updatedImages);
  };

  const uploadProps = {
    showUploadList: false,
    beforeUpload: (file: any, fileList: any[]) => {
      if (!loading) {
        handleUpload(fileList);
      } else {
        message.warning("Images are currently uploading, please wait.");
      }
      return false;
    },
  };

  return (
    <div className="image-upload-container">
      <div className="upload-grid">
        {images.map((imgUrl, index) => (
          <div key={index} className="image-preview">
            <img src={imgUrl} alt={`Uploaded ${index}`} />
            <CloseOutlined
              className="remove-icon"
              onClick={() => handleRemoveImage(index)}
            />
          </div>
        ))}
        {images.length < 5 && (
          <Upload {...uploadProps} multiple={true} accept=".jpg,.png">
            <div className="upload-box">
              <CameraOutlined className="upload-icon" />
            </div>
          </Upload>
        )}
      </div>
      {loading && <Text type="warning">Uploading images...</Text>}
      <Text type="secondary">
        Suggested file formats: png, jpg, pixel 750 x 750
      </Text>
    </div>
  );
};

export default ImageUpload;
