import React, { useState } from "react";
import { Modal, Radio, Typography, Button, Divider } from "antd";

const { Text } = Typography;

interface StoreSelectionModalProps {
  visible: boolean;
  onClose: () => void;
}

const StoreSelectionModal: React.FC<StoreSelectionModalProps> = ({
  visible,
  onClose,
}) => {
  const [selectedStore, setSelectedStore] = useState<string | null>(null);

  const handleStoreChange = (e: any) => {
    setSelectedStore(e.target.value);
  };

  const handleSave = () => {
    console.log("Selected store:", selectedStore);
    onClose(); // Close the modal after saving
  };

  return (
    <Modal
      visible={visible}
      title="Stores"
      onCancel={onClose}
      footer={null}
      centered
      bodyStyle={{ padding: "0px", display: "flex", flexDirection: "column" }}
    >
      <Radio.Group
        onChange={handleStoreChange}
        value={selectedStore}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Radio value="Suilady">Suilady</Radio>
        <Radio value="alvi">alvi</Radio>
        <Radio value="abc store">abc store</Radio>
      </Radio.Group>
      <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
      <div style={{ textAlign: "right" }}>
        <Button onClick={onClose} style={{ marginRight: "8px" }}>
          Cancel
        </Button>
        <Button type="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default StoreSelectionModal;
