import React, { useEffect, useState } from "react";
import { Modal, Button, Input, message } from "antd";
import Datatable from "../../Datatable";
import { get } from "../../../services/Apiclient"; // Assuming you're using axios for API calls

interface CommissionPlanModalProps {
  title: string;
  visible: boolean;
  onCancel: () => void;
  onSave: (selectedData: any[]) => void;
}

const CommissionPlanModal: React.FC<CommissionPlanModalProps> = ({
  title,
  visible,
  onCancel,
  onSave,
}) => {
  const [commissionData, setCommissionData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  // Fetch commission plans from the API
  const fetchCommissionPlans = async () => {
    setLoading(true);
    try {
      const response = await get("/title/commission/list/1/4/plancode/DESC/"); // Replace with actual API endpoint
      if (response.data.success) {
        setCommissionData(response.data.data);
        setFilteredData(response.data.data); // Initialize filteredData with full data
      } else {
        message.error("Failed to fetch commission plans.");
      }
    } catch (error) {
      console.error("Error fetching commission plans:", error);
      message.error("Error fetching commission plans.");
    } finally {
      setLoading(false);
    }
  };

  // Filter data based on search text
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchText(value);
    const filtered = commissionData.filter(
      (item) =>
        item.plancode.toLowerCase().includes(value.toLowerCase()) ||
        item.planname.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  // Update commission data when modal becomes visible
  useEffect(() => {
    if (visible) {
      fetchCommissionPlans();
    }
  }, [visible]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const handleSave = () => {
    const selectedData = filteredData.filter((item) =>
      selectedRowKeys.includes(item.plancode) // Assuming plancode is unique and can be used as key
    );
    onSave(selectedData);
    onCancel();
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <Input
        placeholder="Search by plan code / name"
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: 16 }}
      />
      <Datatable
        rowSelection={rowSelection}
        columns={[
          { title: "Plan code", dataIndex: "plancode", key: "plancode" },
          { title: "Plan name", dataIndex: "planname", key: "planname" },
          { title: "Creation date", dataIndex: "creationdate", key: "creationdate" },
          { title: "Staff linked to", dataIndex: "stafflinkedto", key: "stafflinkedto" },
          { title: "Note", dataIndex: "note", key: "note" },
        ]}
        dataSource={filteredData.map((item) => ({ ...item, key: item.plancode }))}
        showCheckbox={true} // Assuming `Datatable` supports this prop for showing checkboxes
      />
      <div
        style={{
          marginTop: 16,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button key="cancel" onClick={onCancel} style={{ marginRight: 10 }}>
          Cancel
        </Button>
        <Button key="save" type="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default CommissionPlanModal;
