import React from "react";
import { Table, Empty, Typography } from "antd";
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;

const { Text } = Typography;

const AppointmentsHistory: React.FC = () => {
  const columns = [
    { title: "Service", dataIndex: "service", key: "service" },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Store", dataIndex: "store", key: "store" },
    { title: "Duration", dataIndex: "duration", key: "duration" },
    { title: "Staff", dataIndex: "staff", key: "staff" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Note", dataIndex: "note", key: "note" },
    { title: "Action", dataIndex: "action", key: "action" },
  ];

  return (
    <div
      style={{
        backgroundColor: "#f5f7fa",
        borderRadius: "8px",
      }}
    >
      {/* Table with No Data */}
      <Table
        columns={columns}
        dataSource={[]}
        locale={{
          emptyText: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              <img
                src={emptyImageUrl}
                alt="No data available"
                style={{ marginBottom: "8px" }}
              />
              <Text type="secondary">No data available</Text>
            </div>
          ),
        }}
        pagination={false}
        bordered
      />
    </div>
  );
};

export default AppointmentsHistory;
