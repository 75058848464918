import React, { useState, useEffect } from "react";
import { Modal, Table, Input, Button, Form, message } from "antd";
import type { TableColumnsType } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import ConfirmDeleteModal from "./DeleteModal";

interface MainmodelProps<T> {
  columns: TableColumnsType<T>;
  dataSource: T[];
  title: string;
  addModalTitle: string;
  visible: boolean;
  onCancel: () => void;
  onAdd: (name: string) => void;
  scroll?: { x?: string | number; y?: string | number };
  label: string;
  type: string;
  createApi: (name: string) => Promise<void>;
  handleEditApi: (id: string, name: string) => Promise<void>;
  handleDeleteApi: (id: string) => Promise<void>;
  fetchDetails?: (id: string) => Promise<void>;
  updateSequence?: any;
  confirmDeleteMessage: string;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

function Mainmodel<T extends { key: React.Key }>(props: MainmodelProps<T>) {
  const {
    columns,
    dataSource,
    title,
    addModalTitle,
    visible,
    onCancel,
    onAdd,
    label,
    type,
    createApi,
    handleEditApi,
    handleDeleteApi,
    fetchDetails,
    updateSequence,
    scroll = { x: 0, y: 300 },
    confirmDeleteMessage,
  } = props;

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [editItemName, setEditItemName] = useState<string>("");
  const [editingRecord, setEditingRecord] = useState<any>(null);

  const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [itemName, setItemName] = useState<string>("");

  const [dragDataSource, setDragDataSource] = useState<T[]>(dataSource);

  useEffect(() => {
    setDragDataSource(dataSource);
  }, [dataSource]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredDataSource = dragDataSource.filter((item, idx) =>
    Object.values(item).some((val) =>
      String(val).toLowerCase().includes(searchTerm)
    )
  );

  const openEditModal = async (record: any) => {
    setEditingRecord(record);
    setEditItemName(record.name);

    if (fetchDetails) {
      await fetchDetails(record.key);
    }
    setIsEditModalVisible(true);
  };

  const closeEditModal = () => {
    setIsEditModalVisible(false);
    setEditingRecord(null);
    setEditItemName("");
  };

  const openAddModal = () => setIsAddModalVisible(true);
  const closeAddModal = () => {
    setIsAddModalVisible(false);
    setItemName("");
  };

  const openDeleteModal = () => setIsDeleteModalVisible(true);
  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const handleConfirm = () => {
    handleDeleteApi(editingRecord.key);
    closeDeleteModal();
    closeEditModal();
  };

  const handleSaveEdit = async () => {
    if (editItemName.trim() && editingRecord) {
      try {
        await handleEditApi(editingRecord.key, editItemName);
        message.success(`${type} updated successfully`);
        closeEditModal();
      } catch (error) {
        message.error(`Error updating ${type}`);
      }
    } else {
      message.error(`${type} name cannot be empty`);
    }
  };

  const handleDelete = async () => {
    if (editingRecord) {
      try {
        openDeleteModal();
      } catch (error) {
        message.error(`Error deleting ${type}`);
      }
    }
  };

  const handleSave = async () => {
    if (itemName.trim()) {
      await createApi(itemName);
      closeAddModal();
    } else {
      message.error(`${type} name cannot be empty`);
    }
  };

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const draggedRow = dragDataSource[dragIndex];
    const newDataSource = update(dragDataSource, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, draggedRow],
      ],
    });
    setDragDataSource(newDataSource);
    const updatedSequence = newDataSource.map((item: any, index) => ({
      id: item.key,
      sequence: index + 1,
    }));

    updateSequence(updatedSequence);
  };

  const DraggableBodyRow = (props: any) => {
    const { index, moveRow, className, style, ...restProps } = props;
    const ref = React.useRef<HTMLTableRowElement>(null);
    const [, drop] = useDrop({
      accept: "row",
      hover(item: DragItem, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        moveRow(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: "row",
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drag(drop(ref));

    return (
      <tr
        ref={ref}
        style={{ ...style, opacity: isDragging ? 0.5 : 1 }}
        {...restProps}
      >
        {props.children}
      </tr>
    );
  };

  const columnsWithIndex = [...columns];

  return (
    <DndProvider backend={HTML5Backend}>
      <Modal
        title={title}
        open={visible}
        onCancel={onCancel}
        footer={null}
        width={800}
        centered
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
            marginTop: "32px",
          }}
        >
          <Input
            placeholder={`Search by ${type} name`}
            prefix={
              <SearchOutlined
                style={{
                  color: "rgba(0,0,0,.45)",
                  marginRight: "2px",
                  alignItems: "center",
                }}
              />
            }
            onChange={handleSearch}
            style={{ width: "30%", borderRadius: "4px" }}
          />
          <Button
            onClick={openAddModal}
            type="text"
            style={{ color: "#FF971E" }}
          >
            + Add
          </Button>
        </div>

        <Table
          columns={columnsWithIndex}
          dataSource={filteredDataSource}
          pagination={false}
          components={{
            body: {
              row: DraggableBodyRow,
            },
          }}
          scroll={scroll}
          rowClassName={() => "custom-row"}
          onRow={(record, index) => ({
            index,
            className: "",
            style: {},
            moveRow,
            onClick: (event) => {
              const clickedElement = event.target as HTMLElement;
              if (clickedElement.closest("a")?.innerText === "Edit") {
                openEditModal(record);
              }
            },
          })}
        />
      </Modal>

      <Modal
        title={addModalTitle}
        open={isAddModalVisible}
        onCancel={closeAddModal}
        footer={null}
        centered
        width={400}
        className="add-model"
      >
        <Form layout="vertical">
          <Form.Item
            label={label}
            style={{ marginLeft: "24px", marginRight: "24px" }}
            required
          >
            <Input
              placeholder={`Enter ${type} name`}
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
              allowClear
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "12px",
              marginTop: "40px",
              border: "1px solid #D9D9D9",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Button onClick={closeAddModal}>Cancel</Button>
            <Button
              type="primary"
              onClick={handleSave}
              style={{ marginRight: "24px" }}
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title={`Edit ${type}`}
        open={isEditModalVisible}
        onCancel={closeEditModal}
        footer={null}
        centered
        width={400}
        className="add-model"
      >
        <Form layout="vertical">
          <Form.Item
            label={label}
            style={{ marginLeft: "24px", marginRight: "24px" }}
            required
          >
            <Input
              value={editItemName}
              onChange={(e) => setEditItemName(e.target.value)}
              allowClear
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "12px",
              marginTop: "40px",
              border: "1px solid #D9D9D9",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Button
              type="primary"
              danger
              onClick={handleDelete}
              style={{
                marginRight: "30%",
                background: "#fff",
                color: "red",
                borderColor: "red",
              }}
            >
              Delete
            </Button>
            <div style={{ display: "flex", gap: "10px" }}>
              <Button onClick={closeEditModal}>Cancel</Button>
              <Button
                type="primary"
                onClick={handleSaveEdit}
                style={{ marginRight: "24px" }}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      </Modal>

      <ConfirmDeleteModal
        visible={isDeleteModalVisible}
        onCancel={closeDeleteModal}
        // onConfirm={() => handleDeleteApi(editingRecord.key)}
        onConfirm={handleConfirm}
        message={confirmDeleteMessage}
      />
    </DndProvider>
  );
}

export default Mainmodel;
