import React, { FC, useEffect, useState } from "react";
import {
  Modal,
  Tabs,
  Input,
  Button,
  Empty,
  Row,
  Card,
  Col,
  Avatar,
  Typography,
} from "antd";
import { PhoneOutlined, HomeOutlined } from "@ant-design/icons";
import { get } from "../../../services/Apiclient";

const emptyIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noResults.png`;
const StaffIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_Avatar_EmpM.png`;

interface ReferrerModalProps {
  visible: boolean;
  onClose: () => void;
  onSelect: (selectedObject: any) => void;
}

interface StaffMember {
  id: string;
  name: string;
  profile_pic: string;
  staffcode: string;
}

interface Designation {
  id: number;
  designationName: string;
  data: StaffMember[];
}

const ReferrerModal: FC<ReferrerModalProps> = ({
  visible,
  onClose,
  onSelect,
}) => {
  const { TabPane } = Tabs;
  const [staffData, setStaffData] = useState<Designation[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [customerData, setCustomerData] = useState([]);

  const handleCardClick = (selectedObject: any) => {
    onSelect(selectedObject);
    onClose();
  };

  // Fetch data when the modal is opened
  useEffect(() => {
    if (visible) {
      get("/staff/staffwithdest")
        .then((response) => {
          if (response.data.success) {
            setStaffData(response.data.data);
          }
        })
        .catch((error) => console.error("Error fetching staff data:", error));
    }
  }, [visible]);

  useEffect(() => {
    if (searchTerm && searchTerm.length > 0) {
      get(`/customer/searchcustomers/${searchTerm}`)
        .then((response) => {
          if (response.data.success) {
            setCustomerData(response.data.data);
          }
        })
        .catch((error) => console.error("Error fetching staff data:", error));
    } else {
      setCustomerData([]);
    }
  }, [searchTerm]);

  // Filter staff members based on the search term
  const filterStaff = (staffList: StaffMember[]) =>
    staffList.filter((staff) =>
      staff.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const renderStaffCards = (staffList: StaffMember[]) =>
    filterStaff(staffList).map((staff) => (
      <Card
        key={staff.id}
        onClick={() => handleCardClick(staff)}
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
        }}
        bodyStyle={{
          padding: "10px 15px",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        {staff.profile_pic && staff.profile_pic !== "" && (
          <img
            src={staff.profile_pic || StaffIcon}
            alt="Staff Avatar"
            style={{ width: 40, borderRadius: "50%" }}
          />
        )}
        {staff.profile_pic == "" && (
          <img
            src={StaffIcon}
            alt="Staff Avatar"
            style={{ width: 40, borderRadius: "50%" }}
          />
        )}
        <div>
          <div>{staff.name}</div>
          <div style={{ fontSize: "12px", color: "#888" }}>
            {staff.staffcode}
          </div>
        </div>
      </Card>
    ));

  const handleSearch = (data: any) => {
    return data.filter((item: any) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const renderCustomerCards = () =>
    handleSearch(customerData).map((customer: any) => (
      <Card
        key={customer.id}
        onClick={() => handleCardClick(customer)}
        style={{
          backgroundColor: "#f5f7fa",
          borderRadius: "8px",
          padding: "10px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          width: "100%",
          maxWidth: "600px",
          margin: "auto",
        }}
        bodyStyle={{ padding: "0px" }}
      >
        <Row align="middle">
          {/* Left Side - Avatar and Basic Info */}
          <Col span={4}>
            <Avatar size={48} style={{ backgroundColor: "#c3d0e8" }} />
          </Col>
          <Col span={20}>
            <Typography.Text
              strong
              style={{ fontSize: "16px", display: "block" }}
            >
              {customer.name}
            </Typography.Text>
            <Typography.Text style={{ fontSize: "14px", color: "#888888" }}>
              {customer.customercode}
            </Typography.Text>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
                color: "#888888",
              }}
            >
              <PhoneOutlined style={{ marginRight: "5px" }} />
              <Typography.Text style={{ fontSize: "14px", color: "#888888" }}>
                +{customer.phonenumber ? customer.phonenumber : "-"}
              </Typography.Text>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "#888888",
              }}
            >
              <HomeOutlined style={{ marginRight: "5px" }} />
              <Typography.Text style={{ fontSize: "14px", color: "#888888" }}>
                -
              </Typography.Text>
            </div>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={12}>
            <Typography.Text style={{ fontSize: "14px", color: "#888888" }}>
              Available balance:
            </Typography.Text>
            <Typography.Text
              strong
              style={{ fontSize: "16px", marginLeft: "5px" }}
            >
              $0
            </Typography.Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Typography.Text style={{ fontSize: "14px", color: "#888888" }}>
              Last consumption date:
            </Typography.Text>
            <Typography.Text
              style={{ fontSize: "14px", color: "#888888", marginLeft: "5px" }}
            >
              -
            </Typography.Text>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "15px",
            borderTop: "1px solid #e0e0e0",
            paddingTop: "10px",
          }}
        >
          <Col span={24}>
            <Typography.Text style={{ fontSize: "14px", color: "#888888" }}>
              Note:
            </Typography.Text>
            <Typography.Text
              style={{ fontSize: "14px", color: "#888888", marginLeft: "5px" }}
            >
              {customer.notes ? customer.notes : "-"}
            </Typography.Text>
          </Col>
        </Row>
      </Card>
    ));

  return (
    <Modal
      visible={visible}
      title="Referrer"
      onCancel={() => {
        onClose();
        setSearchTerm("");
        setCustomerData([]);
        setStaffData([]);
      }}
      footer={null}
      width={500}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Customer" key="1">
          <div style={{ display: "flex", marginBottom: 20 }}>
            <Input
              placeholder="Select by name/phone number/email"
              style={{ marginRight: 10 }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button type="primary">Search</Button>
          </div>
          {customerData && customerData.length == 0 && (
            <Empty
              image={emptyIcon}
              imageStyle={{ height: 100 }}
              description="No results found"
            />
          )}
          {customerData && customerData.length > 0 && (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {renderCustomerCards()}
            </div>
          )}
        </TabPane>
        <TabPane tab="Staff" key="2">
          <Tabs defaultActiveKey="1" size="small" className="scrollable-tabs">
            {staffData.length > 0 ? (
              staffData.map((designation: any) => (
                <TabPane tab={designation.designationName} key={designation.id}>
                  <Row gutter={[16, 16]}>
                    {renderStaffCards(designation.data)}
                  </Row>
                </TabPane>
              ))
            ) : (
              <Empty
                image={emptyIcon}
                imageStyle={{ height: 100 }}
                description="No results found"
              />
            )}
          </Tabs>
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default ReferrerModal;
