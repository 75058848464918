import React from "react";
import { Select, Tabs, Typography, Empty } from "antd";
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;

const { TabPane } = Tabs;
const { Text } = Typography;

const GiftcardSection: React.FC = () => {
  return (
    <div
      style={{
        backgroundColor: "#f5f7fa",
        borderRadius: "8px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Select defaultValue="All" style={{ width: 120, marginBottom: "20px" }}>
          <Select.Option value="All">All</Select.Option>
          {/* Add more options as needed */}
        </Select>

        {/* Tabs for Status */}
        <Tabs defaultActiveKey="1" type="card" style={{ marginBottom: "20px" }}>
          <TabPane tab="Active (0)" key="1" />
          <TabPane tab="Inactive (0)" key="2" />
          <TabPane tab="Exhausted (0)" key="3" />
          <TabPane tab="Expired (0)" key="4" />
        </Tabs>
      </div>

      {/* No Data Available Message */}
      <div style={{ textAlign: "center", paddingTop: "50px" }}>
        <Empty
          image={emptyImageUrl} // Replace with actual image URL
          imageStyle={{ height: 80 }}
          description={
            <Text type="secondary">No membership currently available</Text>
          }
        />
      </div>
    </div>
  );
};

export default GiftcardSection;
