import React from "react";
import { Routes, Route } from "react-router-dom";
import OutletList from "../pages/outlet/OutletList";
import NotFound from "../pages/NotFound";
import Home from "../pages/Home";
import MainLayout from "../layouts/MainLayout";
import SignInForm from "../pages/signup/SignInForm";
import ProtectedRoute from "../routes/ProtectedRoute";
import { userRoles } from "../constants/userRoles";
import Settings from "../pages/settings/Settings";
import ProductList from "../pages/product/ProductList";
import ServiceList from "../pages/service/ServiceList";
import RoomList from "../pages/room/RoomList";
import CategoryList from "../pages/category/CategoryList";
import CustomerList from "../pages/customer/CustomerList";
import MerchantForm from "../pages/merchant/MerchantForm";
import OutletForm from "../pages/outlet/OutletForm";
import RoomAdd from "../pages/room/RoomAdd";
import StaffList from "../pages/staff/StaffList";
import StaffAdd from "../pages/staff/StaffAdd";
import MerchantList from "../pages/merchant/MerchantList";
import ProductAdd from "../pages/product/ProductAdd";
import ServiceAdd from "../pages/service/ServiceAdd";
import StockInList from "../pages/stock/StockInList";
import StockOutList from "../pages/stock/StockOutList";
import StockTakesList from "../pages/stock/StockTakesList";
import Inventory from "../pages/inventory/Inventory";
import StockInAdd from "../pages/stock/StockInAdd";
import AddStockTakes from "../pages/stock/StockTakesAdd";
import CommonData from "../pages/commonData/CommonData";
import StockOutAdd from "../pages/stock/StockOutAdd";
import StaffInfo from "../pages/staff/StaffInfo";
import StockInDetailView from "../pages/stock/StockInDetailView";
import StockOutDetailView from "../pages/stock/StockOutDetailView";
import StockTakesDetailView from "../pages/stock/StockTakesDetailView";
import CustomerForm from "../pages/customer/CustomerForm";
import CustomerProfile from "../pages/customer/CustomerProfile";

const AppRoutes: React.FC = () => (
  <Routes>
    <Route element={<MainLayout />}>
      <Route path="/" element={<Home />} />
      <Route path="customer" element={<CustomerList />} />

      <Route
        path="merchant/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <MerchantList />
          </ProtectedRoute>
        }
      />
      <Route path="settings" element={<Settings />} />
      <Route path="inventory" element={<Inventory />} />
    </Route>

    <Route path="settings/product/list" element={<ProductList />} />
    <Route path="settings/product/add" element={<ProductAdd />} />
    <Route path="settings/product/edit/:productId" element={<ProductAdd />} />
    <Route
      path="settings/product/duplicate/:productId"
      element={<ProductAdd />}
    />

    <Route path="settings/service/list" element={<ServiceList />} />
    <Route path="settings/service/add" element={<ServiceAdd />} />
    <Route path="settings/service/edit/:serviceId" element={<ServiceAdd />} />

    <Route path="settings/room/list" element={<RoomList />} />
    <Route path="settings/room/add" element={<RoomAdd />} />
    <Route path="settings/room/edit/:roomId" element={<RoomAdd />} />
    <Route path="settings/room/duplicate/:roomId" element={<RoomAdd />} />

    <Route path="outlet" element={<OutletList />} />
    <Route path="outlet/add" element={<OutletForm />} />
    <Route path="outlet/:id" element={<OutletForm />} />

    <Route path="settings/business-details" element={<OutletList />} />
    <Route path="settings/business-details/add" element={<OutletForm />} />
    <Route path="settings/business-details/:id" element={<OutletForm />} />

    <Route path="common-data" element={<CommonData />} />

    <Route path="inventory/stockin/list" element={<StockInList />} />
    <Route path="inventory/stockin/Add" element={<StockInAdd />} />
    <Route path="inventory/stockin/edit/:id" element={<StockInAdd />} />
    <Route path="inventory/stockout/list" element={<StockOutList />} />
    <Route path="inventory/stockout/Add" element={<StockOutAdd />} />
    <Route path="inventory/stockout/edit/:id" element={<StockOutAdd />} />
    <Route path="inventory/stocktakes/list" element={<StockTakesList />} />
    <Route path="inventory/stocktakes/add" element={<AddStockTakes />} />
    <Route path="inventory/stocktakes/edit/:id" element={<AddStockTakes />} />
    <Route
      path="inventory/stockindetails/view/:id"
      element={<StockInDetailView />}
    />
    <Route
      path="inventory/stockoutdetails/view/:id"
      element={<StockOutDetailView />}
    />
    <Route
      path="inventory/stocktakesdetails/view/:id"
      element={<StockTakesDetailView />}
    />

    <Route path="settings/staff/list" element={<StaffList />} />
    <Route path="settings/staff/add" element={<StaffAdd />} />

    <Route path="customer/add" element={<CustomerForm />} />
    <Route path="customer/view/:id" element={<CustomerProfile />} />
    <Route path="customer/edit/:id" element={<CustomerForm />} />

    <Route path="merchant/add" element={<MerchantForm />} />
    <Route path="merchant/edit/:id" element={<MerchantForm />} />
    <Route path="merchant/list" element={<MerchantList />} />
    <Route path="settings/staff/info/:id" element={<StaffInfo />} />
    <Route path="settings/category/list" element={<CategoryList />} />

    <Route path="/login" element={<SignInForm />} />

    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default AppRoutes;
