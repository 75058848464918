import React, { useEffect, useState } from "react";
import {
  Form,
  Layout,
  Button,
  Input,
  Select,
  Row,
  Col,
  Dropdown,
  Menu,
  message,
  Modal,
  Switch,
  Checkbox,
} from "antd";
import {
  PlusOutlined,
  ArrowLeftOutlined,
  SettingOutlined,
  PictureOutlined,
  SettingFilled,
  UploadOutlined,
  DownloadOutlined,
  UnorderedListOutlined,
  SketchOutlined,
  TruckOutlined,
  SearchOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { deleteData, get, post, put } from "../../services/Apiclient";
import Datatable from "../../components/Datatable";
import { useNavigate } from "react-router-dom";
import "../../styles/global.css";
import Mainmodel from "../../components/model/Mainmodel";
import SupplierList from "../supplier/SupplierList";
import ImportModal from "../../components/model/ImportModal";
import DuplicateDistributeModel from "../../components/model/DuplicateDistributeModel";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
// import axios from 'axios';

const { Option } = Select;
const { Content } = Layout;

interface ProductData {
  key: React.Key;
  productcode: string;
  productname: string;
  category: string;
  selling_price: string;
  status: string;
}

interface categoryData {
  key: React.Key;
  id: string;
  name: string;
  type: string;
  outlet_id: string;
  status: number;
}

function ProductList() {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<ProductData[]>([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [isMultipleDeleteModalVisible, setIsMultipleDeleteModalVisible] =
    useState(false);
  const [singleDeleteModalVisible, setSingleDeleteModalVisible] =
    useState(false);
  const [selectedProduct, setselectedProduct] = useState<ProductData | null>(
    null
  );
  const [dataSourceCategory, setDataSourceCategory] = useState<categoryData[]>(
    []
  );
  const [type, setType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [supplierCurrentPage, setSupplierCurrentPage] = useState(1);
  const [supplierPageSize, setSupplierPageSize] = useState(10);
  const [supplierTotalRecords, setSupplierTotalRecords] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [categoryVisible, setCategoryvisible] = useState(false);
  const [Brandvisible, setBrandvisible] = useState(false);
  const [visibleCategory, setvisibleCategory] = useState(false);
  const [supplierModalOpen, setSupplierModalOpen] = useState(false);
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [originalDataSource, setOriginalDataSource] = useState<ProductData[]>(
    []
  );
  const [DeleteDuplicateModelOpen, setDeleteDuplicateModelOpen] =
    useState(false);

  // const [categorycode, setCategorycode] = useState("null");
  // const [outletcode, setOutletcode] = useState("null");
  const [searchText, setSearchText] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [outletData, setOutletData] = useState<any[]>([]);
  const [selectedoutletValue, setSelectedoutletValue] = useState(
    localStorage.getItem("outlet_id") || null
  );
  const [selectedcategoryValue, setSelectedcategoryValue] =
    useState<string>("null");

  const handleDeleteAllSelected = () => {
    setIsMultipleDeleteModalVisible(true); // Show delete confirmation modal
  };

  const handleDeleteConfirm = async () => {
    try {
      const productarr = selectedRowKeys;
      const body = { productarr };
      const response = await put("/product/removemultiple", body); // Call API

      if (response?.status === 200 && response.data.success) {
        // Remove deleted items from the data source
        const updatedDataSource = dataSource.filter(
          (item) => !selectedRowKeys.includes(item.key)
        );
        setDataSource(updatedDataSource);
        setSelectedRowKeys([]);
        message.success("Selected items deleted successfully");
      } else {
        message.error("Failed to delete selected items");
      }
    } catch (error) {
      message.error("An error occurred while deleting items");
      console.error("Error deleting items:", error);
    } finally {
      setIsMultipleDeleteModalVisible(false); // Close the modal
    }
  };

  const handleDeleteCancel = () => {
    setIsMultipleDeleteModalVisible(false); // Hide delete confirmation modal
  };

  const handleDeleteClick = (record: ProductData) => {
    setselectedProduct(record); // Set the room that is selected for deletion
    setSingleDeleteModalVisible(true); // Show the delete modal
  };
  // Confirm deletion
  const handleSingleConfirmDelete = async () => {
    if (selectedProduct) {
      try {
        const url = `/product/remove/${selectedProduct.key}`;
        const response = await deleteData(url);
        if (response?.status === 200 || response?.status === 204) {
          // Assuming the room was successfully deleted
          console.log("Deleted product with key:", selectedProduct.key);
          // Update the dataSource to remove the deleted room from the table
          setDataSource((prevData) =>
            prevData.filter((item) => item.key !== selectedProduct.key)
          );
          // Close the delete modal
          setSingleDeleteModalVisible(false);
          message.success("Deleted successfully");
        } else {
          console.error("Error deleting room:", response);
          // Handle the error scenario here (e.g., show a notification or error message)
        }
      } catch (error) {
        console.error("Error during delete operation:", error);
      }
    }
  };
  const handleSingleCancelDelete = () => {
    setSingleDeleteModalVisible(false); // Close the modal without deleting
  };

  const showImportModal = () => {
    setIsImportModalVisible(true);
  };

  const handleImportModalCancel = () => {
    setIsImportModalVisible(false);
  };

  const apiEndpoint = "/product/importproductcsv";

  const handleImportModalSave = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await post(apiEndpoint, formData, true); // Using dynamic endpoint
      if (response?.data?.success) {
        message.success("File uploaded successfully");
        setIsImportModalVisible(false);
      } else {
        message.error(response?.data?.message || "Failed to upload the file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error("Error uploading the file");
    }
  };

  const filterIconStyle = {
    color: status.length > 0 ? "#1890ff" : "#aaa",
    cursor: "pointer",
    marginLeft: "8px",
  };

  const handleEmploymentStatusChange = (checkedValues: any) => {
    setStatus(checkedValues);
  };

  const applyFilter = () => {
    if (status.length === 0) {
      setDataSource(originalDataSource);
    } else {
      const filteredData = originalDataSource.filter((item) =>
        status.includes(item.status)
      );
      setDataSource(filteredData);
    }
    setDropdownVisible(false);
  };

  const resetFilter = () => {
    setStatus([]);
    setDataSource(originalDataSource);
  };

  const categoryColumns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: "20%",
      render: (text: any, record: any, index: number) => (
        <span>
          <span
            className="drag-handle-icon"
            style={{ marginRight: 3, fontWeight: "600" }}
          >
            {index + 1}.☰
          </span>
          {text}
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "Count of products included",
      dataIndex: "productCount",
      key: "productCount",
      width: "22%",
      render: (count: number) => count || 0,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (onlinebooking: boolean, record: any) => (
        <Switch
          checked={onlinebooking}
          onChange={async (checked) => {
            try {
              await handleCategoryStatusChange(checked, record.key);
            } catch (error) {
              message.error("Failed to update brand status.");
              console.error("Error updating status:", error);
            }
          }}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: () => (
        <a
          style={{
            color: "#2e56f2",
            textDecoration: "underline",
            fontWeight: 700,
            marginRight: "16px",
          }}
        >
          Edit
        </a>
      ),
    },
  ];

  const brandcolumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
      render: (text: any, record: any, index: number) => (
        <span>
          <span
            className="drag-handle-icon"
            style={{ marginRight: 8, fontWeight: "600" }}
          >
            {index + 1}.☰
          </span>
          {text}
        </span>
      ),
    },
    {
      title: "Product Include",
      dataIndex: "productInclude",
      key: "productInclude",
      width: "20%",
      render: (text: any) => `${text} products`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (onlinebooking: boolean, record: any) => (
        <Switch
          checked={onlinebooking}
          onChange={async (checked) => {
            try {
              await handleBrandStatusChange(record.key, checked);
            } catch (error) {
              message.error("Failed to update brand status.");
              console.error("Error updating status:", error);
            }
          }}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: () => (
        <a
          style={{
            color: "#2e56f2",
            textDecoration: "underline",
            fontWeight: 700,
            marginRight: "16px",
          }}
        >
          Edit
        </a>
      ),
    },
  ];

  const updateBrandSequence = async (updatedBrands: any[]) => {
    try {
      const url = `/brand/sequenceupdate`;
      const response = await put(url, {
        brands: updatedBrands,
      });
      if (response?.data?.success) {
        console.log("Brand sequence updated successfully!");
      } else {
        message.error("Failed to update sequence");
      }
    } catch (error) {
      console.error("Error updating sequence: ", error);
      message.error("Error updating sequence");
    }
  };

  const updateCategoriesSequence = async (updatedCategory: any[]) => {
    try {
      const url = `/category/updatesequence/Service`;
      const response = await put(url, {
        categories: updatedCategory,
      });
      if (response?.data?.success) {
        console.log("Category sequence updated successfully!");
      } else {
        message.error("Failed to update sequence");
      }
    } catch (error) {
      console.error("Error updating sequence: ", error);
      message.error("Error updating sequence");
    }
  };

  const handleCategoryStatusChange = async (checked: boolean, record: any) => {
    const updatedStatus = checked ? "true" : "false";
    try {
      const url = `/category/${record}`;
      const response = await put(url, { status: updatedStatus });
      if (response?.data?.success) {
        message.success("Status updated successfully");
        fetchCategory();
      } else {
        message.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status: ", error);
      message.error("Error updating status");
    }
  };

  const handleBrandStatusChange = async (record: any, checked: boolean) => {
    const updatedStatus = checked ? 1 : 0;
    try {
      const url = `/brand/${record}`;
      const response = await put(url, { status: updatedStatus });
      if (response?.data?.success) {
        message.success("Status updated successfully");
        fetchBrands();
      } else {
        message.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status: ", error);
      message.error("Error updating status");
    }
  };

  const fetchBrands = async () => {
    try {
      const response = await get("/brand/getall"); // Replace with your API endpoint
      if (response.data.success) {
        const mappedBrands = response.data.data.map((brand: any) => ({
          key: brand.id, // This will be used as the key in the table
          name: brand.name,
          productInclude: 0, // Set this field if applicable, or remove it if not used
          status: brand.status,
        }));
        setBrandData(mappedBrands);
      } else {
        message.error("Failed to fetch brands");
      }
    } catch (error) {
      console.error("Error fetching brands: ", error);
      message.error("Error fetching brands");
    }
  };

  const fetchCategory = async () => {
    try {
      const response = await get("/category/getlistwithoutpagination/Product");
      if (response.data.success) {
        const apiData = response.data.data.map((item: any) => ({
          key: item.id,
          code: item.code,
          name: item.name,
          type: item.type,
          status: item.status,
        }));

        // Update the data source state
        setDataSourceCategory(apiData);
      } else {
        message.error("Failed to fetch brands");
      }
    } catch (error) {
      console.error("Error fetching brands: ", error);
      message.error("Error fetching brands");
    }
  };

  const handleEditBrand = async (id: string, brandName: string) => {
    try {
      const response = await put(`/brand/${id}`, { name: brandName });
      if (response.data.success) {
        //message.success("Brand updated successfully!");
        fetchBrands();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error("Error updating brand");
    }
  };

  const handleDeleteBrand = async (id: string) => {
    try {
      const response = await deleteData(`/brand/remove/${id}`);
      if (response.data.success) {
        message.success("Brand deleted successfully");
        fetchBrands();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error("Error deleting brand");
    }
  };

  const handleEditCategory = async (id: string, brandName: string) => {
    try {
      const response = await put(`/category/${id}`, { name: brandName });
      if (response.data.success) {
        // message.success("Category updated successfully");
        fetchCategory();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error("Error updating Category");
    }
  };

  const handleDeleteCategory = async (id: string) => {
    try {
      const response = await deleteData(`/category/remove/${id}`);
      if (response.data.success) {
        message.success("Category deleted successfully!");
        fetchCategory();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error("Error deleting Category");
    }
  };

  const fetchCategoryDetails = async (id: string) => {
    try {
      const response = await get(`/category/${id}`);
      if (response.data.success) {
        return response.data.data;
      } else {
        message.error("Failed to fetch category details");
      }
    } catch (error) {
      message.error("Error fetching category details");
    }
  };

  const fetchBrandDetails = async (id: string) => {
    try {
      const response = await get(`/brand/${id}`);
      if (response.data.success) {
        return response.data.data;
      } else {
        message.error("Failed to fetch brand details");
      }
    } catch (error) {
      message.error("Error fetching brand details");
    }
  };

  const getProductCategoryDropdown = async () => {
    try {
      let url = "/category/dropdown/type/Product";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          data.unshift({
            id: "null",
            name: "All Categories",
          });
          setCategoryData(data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedoutletValue, selectedcategoryValue]);

  useEffect(() => {
    if (categoryVisible) {
      fetchCategory();
    }
  }, [categoryVisible]);

  useEffect(() => {
    if (Brandvisible) {
      fetchBrands();
    }
  }, [Brandvisible]);

  const fetchData = async () => {
    try {
      const url = `/product/list/${currentPage}/${pageSize}/created_at/DESC/${
        searchText || null
      }/${selectedcategoryValue || null}/${selectedoutletValue || null}`;
      //let url = `/product/list/${currentPage}/${pageSize}/productname/DESC/NULL/NULL/NULL`
      const response = await get(url);
      if (response?.data?.success) {
        const apiData = response.data.data.map((item: any) => ({
          key: item.id,
          productcode: item.productcode,
          productname: item.productname,
          category: item.categoryname,
          selling_price: "$" + item.sellprice,
          status: item.status,
        }));
        setDataSource(apiData);
        setOriginalDataSource(apiData);
        setTotalRecords(response.data.totalRecords);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  };

  // Fetch products data
  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize]);

  const handleSearch = () => {
    fetchData();
  };

  const getOutletDropdown = async () => {
    try {
      let url = "/outlet/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setOutletData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  useEffect(() => {
    getProductCategoryDropdown();
    getOutletDropdown();
  }, []);
  const handleSupplierPageChange = (page: number, pageSize?: number) => {
    setSupplierCurrentPage(page);
    if (pageSize) {
      setSupplierPageSize(pageSize);
    }
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  // Modal control functions
  const openCategorymodal = () => {
    setType("category");
    setCategoryvisible(true);
  };

  const closeCategorymodal = () => {
    setCategoryvisible(false);
  };

  const openBrandmodal = () => {
    setType("brand");
    setBrandvisible(true);
  };

  const closeBrandmodal = () => {
    setBrandvisible(false);
  };

  const openCategoryadd = () => {
    setvisibleCategory(true);
  };

  const closeCategoryadd = () => {
    setvisibleCategory(false);
  };

  const openSupplierModal = () => {
    setSupplierModalOpen(true);
  };

  const closeSupplierModal = () => {
    setSupplierModalOpen(false);
  };

  const openDuplicateDistributeModal = () => {
    setDeleteDuplicateModelOpen(true);
  };

  const closeDuplicateDistributeModal = () => {
    setDeleteDuplicateModelOpen(false);
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const handleAddCategory = async (categoryName: string) => {
    const newCategory = {
      name: categoryName,
    };

    // try {
    //   await post("/category/addcategory/Product", newCategory);
    // } catch (error) {
    //   console.error("Error creating category: ", error);
    // }
    try {
      let url = "/category/addcategory/Product";
      const response = await post(url, newCategory);
      if (response && response.data) {
        if (response.data.success) {
          message.success("Category added successfully");
          fetchCategory();
        } else {
          message.error(response.data.message);
          console.log("Error in api call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
      // Update brand data source here
    } catch (error) {
      console.error("Error creating brand: ", error);
    }
  };

  const handleAddBrand = async (brandName: string) => {
    const newBrand = {
      name: brandName,
    };

    try {
      let url = "/brand/addbrand";
      const response = await post(url, newBrand);
      if (response && response.data) {
        if (response.data.success) {
          message.success("Brand added successfully");
          fetchBrands();
        } else {
          message.error(response.data.message);
          console.log("Error in api call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
      // Update brand data source here
    } catch (error) {
      console.error("Error creating brand: ", error);
    }
  };

  const fetchSupplierData = async () => {
    try {
      const url = `/supplier/getall/${supplierCurrentPage}/${supplierPageSize}/created_at/DESC/${searchTerm}`;
      const response = await get(url);
      if (response?.data?.success) {
        const apiData = response.data.data.map((item: any) => ({
          key: item.id,
          number: item.number,
          supplierId: item.supplierid,
          supplierName: item.name,
          contact: `${item.contactfirstname} ${item.contactlastname}`.trim(),
          phone: item.phone || "",
          email: item.email || "",
          street: item.street || "",
          city: item.city || "",
          state: item.state || "",
          zip: item.zipcode || "",
          country: item.country || "",
        }));
        setSuppliers(apiData);
        setSupplierTotalRecords(response.data.totalRecords);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  };

  const handleExport = async () => {
    try {
      // console.log("Starting export process...");

      // Make the API call to fetch the CSV
      const response = await get("/product/exceldata");

      // Log the response for debugging
      // console.log("API response received:", response);

      // Check if the response indicates success
      if (response?.data) {
        // Check for data directly instead of success flag
        const blob = new Blob([response.data], { type: "text/csv" });

        // Create a link element
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `products_${Date.now()}.csv`; // Set a default file name

        // Append the link to the body and trigger the download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href); // Free up memory

        // console.log("File created successfully. Download initiated.");
        // Log the successful export
        // console.log(`CSV export successful: ${link.download}`);
        message.success("File created successfully. Downloading...");
      } else {
        const errorMessage =
          response?.data?.message || "Failed to export the file.";
        // console.error("Export failed:", errorMessage);
        console.log(errorMessage);
        message.error(errorMessage);
      }
    } catch (error) {
      console.error("Error exporting the file: ", error);

      // Use type guard to handle the error correctly
      const errorMessage =
        error instanceof Error ? error.message : "Unknown error occurred.";
      console.log(
        "An error occurred while exporting the file: " + errorMessage
      );
      message.error("An error occurred while exporting the file.");
    }
  };

  useEffect(() => {
    if (supplierModalOpen) {
      fetchSupplierData();
    }
  }, [supplierCurrentPage, supplierPageSize, supplierModalOpen, searchTerm]);

  const menuItems = [
    {
      key: "1",
      icon: <UnorderedListOutlined />,
      label: "Category management",
      onClick: openCategorymodal,
    },
    {
      key: "2",
      icon: <SketchOutlined />,
      label: "Brand management",
      onClick: openBrandmodal,
    },
    {
      key: "3",
      icon: <TruckOutlined />,
      label: "Supplier management",
      onClick: openSupplierModal,
    },
  ];

  // Dropdown menu with modal trigger
  const menu = <Menu items={menuItems} />;

  // Employment Status Menu
  const productStatus = (
    <Menu>
      <Checkbox.Group
        style={{ padding: "10px" }}
        value={status}
        onChange={handleEmploymentStatusChange}
      >
        <Row>
          <Col span={24}>
            <Checkbox value="Active">Active</Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox value="Inactive">Inactive</Checkbox>
          </Col>
        </Row>
      </Checkbox.Group>
      <Row justify="space-between" style={{ padding: "10px" }}>
        <Button onClick={resetFilter}>Reset</Button>
        <Button type="primary" onClick={applyFilter}>
          OK
        </Button>
      </Row>
    </Menu>
  );

  const columns = [
    {
      title: "Product Code",
      dataIndex: "productcode",
      key: "productcode",
      width: 150,
    },
    {
      title: "Product Name",
      dataIndex: "productname",
      key: "productname",
      width: 200,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 150,
    },
    {
      title: "Selling Price",
      dataIndex: "selling_price",
      key: "selling_price",
      width: 150,
      align: "right" as "right", // Ensures 'right' is recognized as AlignType
      render: (text: any) => (
        <span style={{ textAlign: "right", display: "block" }}>{text}</span>
      ),
    },
    {
      title: (
        <>
          Status
          <Dropdown
            overlay={productStatus}
            trigger={["click"]}
            visible={dropdownVisible}
            onVisibleChange={(flag) => setDropdownVisible(flag)}
            overlayStyle={{ width: "150px" }} // Adjust size here
          >
            <FilterOutlined style={filterIconStyle} />
          </Dropdown>
        </>
      ),
      dataIndex: "status",
      key: "status",
      width: 100,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right" as "right",
      width: 150,
      render: (text: any, record: ProductData): React.ReactNode => (
        <>
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
            }}
            onClick={() => navigate(`/settings/product/edit/${record.key}`)}
          >
            Edit
          </a>
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
            }}
            onClick={() =>
              navigate(`/settings/product/duplicate/${record.key}`)
            }
          >
            Duplicate
          </a>
          {/* <a style={{ color: 'red' }} onClick={() => handleDeleteClick(record)} > */}
          <a
            style={{
              color: "rgb(245, 63, 63)",
              fontWeight: 700,
              textDecoration: "underline",
            }}
            onClick={() => handleDeleteClick(record)}
          >
            Delete
          </a>
        </>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };
  return (
    <Content className="mainContainer">
      {/* Header Section */}
      <Row justify="space-between" align="middle">
        <Col>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <div
              onClick={() => navigate("/settings")}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>Product List</h2>
          </div>
        </Col>
        <Col>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              icon={<DownloadOutlined />}
              className="export-button"
              onClick={showImportModal}
            >
              Import
            </Button>
            <Button
              icon={<UploadOutlined />}
              className="export-button"
              onClick={handleExport}
            >
              Export
            </Button>
            <Button
              className="quick-settings"
              style={{
                color: "rgb(46, 86, 242)",
                borderColor: "rgb(46, 86, 242)",
              }}
              icon={<SettingFilled />}
            >
              Quick Settings
            </Button>
            {/* <Button className="quick-settings">Quick Settings</Button> */}
            <Button
              className="add-button"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate("/settings/product/add")}
            >
              Add
            </Button>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                type="default"
                icon={<SettingFilled />}
                className="setting-icon"
              />
            </Dropdown>
          </div>
        </Col>
      </Row>

      {/* Content Section */}
      <Content
        style={{
          marginTop: "10px",
          backgroundColor: "#f6f7f9",
          borderRadius: "12px",
          padding: "24px 24px 10px",
          border: "1px solid #e0e7fe",
          boxSizing: "border-box",
        }}
      >
        <Row gutter={16} style={{ marginBottom: "16px" }}>
          <Col>
            <Select
              placeholder="Select Outlet"
              className="dropdown"
              style={{ width: "198px", height: "40px", alignItems: "center" }}
              value={selectedoutletValue}
              onSelect={(value) => setSelectedoutletValue(value)}
              onClear={() => setSelectedoutletValue(null)}
              allowClear
            >
              {outletData.map((outlet: any) => (
                <Option
                  key={outlet.id}
                  value={outlet.id}
                  style={{
                    textAlign: "center",
                    color: selectedoutletValue === outlet.id ? "blue" : "black",
                  }}
                >
                  {outlet.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Select
              placeholder="All categories"
              style={{ width: "198px", height: "40px", alignItems: "center" }}
              value={selectedcategoryValue}
              onSelect={(value) => setSelectedcategoryValue(value)}
              onClear={() => setSelectedcategoryValue("null")}
              allowClear
            >
              {categoryData &&
                categoryData.map((category: any) => (
                  <Option
                    key={category.id}
                    value={category.id}
                    style={{
                      textAlign: "center",
                      color:
                        selectedcategoryValue === category.id
                          ? "blue"
                          : "black",
                    }}
                  >
                    {category.name}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col flex="auto">
            <Input
              placeholder="Search by product code / name"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              allowClear
              prefix={
                <SearchOutlined
                  style={{
                    color: "rgba(0,0,0,.45)",
                    marginRight: "2px",
                    alignItems: "center",
                  }}
                />
              }
              style={{ width: "280px", height: "40px", alignItems: "center" }}
            />
          </Col>
          <Col>
            <Button type="primary" onClick={handleSearch}>
              Search
            </Button>
          </Col>
        </Row>

        {/* Table */}
        <Datatable<ProductData>
          columns={columns}
          dataSource={dataSource}
          currentPage={currentPage}
          pageSize={pageSize}
          totalRecords={totalRecords}
          onPageChange={handlePageChange}
          scroll={{ y: 500 }}
          showCheckbox={true}
          rowSelection={rowSelection}
        />

        {/* Buttons */}
        <Row justify="start">
          <Col>
            <Button
              className="deleteall-btn"
              disabled={selectedRowKeys.length === 0}
              type="default"
              style={{ marginRight: "10px" }}
              onClick={handleDeleteAllSelected}
            >
              Delete all selected
            </Button>
          </Col>
          <Col>
            <Button
              className="duplicate-btn"
              type="primary"
              onClick={openDuplicateDistributeModal}
            >
              Duplicate & Distribute
            </Button>
          </Col>
        </Row>

        {/* Modal for Category Management */}
        <Mainmodel
          title="Categories of Product"
          addModalTitle="Add New Category"
          label="Category Name"
          visible={categoryVisible}
          onCancel={closeCategorymodal}
          columns={categoryColumns}
          dataSource={dataSourceCategory}
          onAdd={closeCategoryadd}
          type="Category"
          createApi={handleAddCategory}
          handleEditApi={handleEditCategory} // Function to call when editing
          handleDeleteApi={handleDeleteCategory}
          fetchDetails={fetchCategoryDetails}
          updateSequence={updateCategoriesSequence}
          confirmDeleteMessage="Delete the Category?"
        />

        <Mainmodel
          title="Brands of Product"
          addModalTitle="Add New Brands"
          visible={Brandvisible}
          label="Brand name"
          onCancel={closeBrandmodal}
          columns={brandcolumns}
          dataSource={brandData}
          onAdd={closeCategoryadd}
          type="Brand"
          createApi={handleAddBrand}
          handleEditApi={handleEditBrand} // Function to call when editing
          handleDeleteApi={handleDeleteBrand}
          fetchDetails={fetchBrandDetails}
          updateSequence={updateBrandSequence}
          confirmDeleteMessage="Delete the Brand?"
        />

        <Modal
          title="Supplier Management"
          open={supplierModalOpen}
          onCancel={closeSupplierModal}
          width={1000} // Adjust modal width as needed
          footer={null} // Remove default footer buttons
        >
          {/* Supplier List inside the modal */}

          <SupplierList
            onCancel={closeSupplierModal}
            suppliers={suppliers} // Pass suppliers state
            setSuppliers={setSuppliers} // Pass setSuppliers to manage suppliers
            currentPage={supplierCurrentPage} // Pass currentPage
            pageSize={supplierPageSize} // Pass pageSize
            totalRecords={supplierTotalRecords} // Pass totalRecords
            onPageChange={handleSupplierPageChange} // Pass page change function
            onSearch={handleSearchChange}
            fetchSupplierData={fetchSupplierData}
          />
        </Modal>

        <ImportModal
          visible={isImportModalVisible}
          onCancel={handleImportModalCancel}
          onSave={handleImportModalSave}
        />

        <Modal
          title="Duplicate & Distribute"
          open={DeleteDuplicateModelOpen}
          onCancel={closeDuplicateDistributeModal}
          width={548}
          height={525}
          footer={null}
        >
          <DuplicateDistributeModel />
        </Modal>

        {/* Delete Multiple Confirmation Modal */}
        <ConfirmDeleteModal
          visible={isMultipleDeleteModalVisible}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          message="Delete the product?"
        />

        {/* Delete Single Confirmation Modal */}
        <ConfirmDeleteModal
          visible={singleDeleteModalVisible}
          onCancel={handleSingleCancelDelete}
          onConfirm={handleSingleConfirmDelete}
          message="Delete the product?"
        />
      </Content>

      {/* <div className="product-table-container">
        <div className="table-filter-section">
          <Select defaultValue="BeautyComfort" style={{ width: 200 }}>
            <Option value="BeautyComfort">BeautyComfort</Option>
            <Option value="Other">Other</Option>
          </Select>
          <Select defaultValue="All categories" style={{ width: 200 }}>
            <Option value="All categories">All categories</Option>
            <Option value="Beauty">Beauty</Option>
            <Option value="Care">Care</Option>
          </Select>
          <Input
            placeholder="Search by product code / name"
            style={{ width: 300 }}
          />
          <Button type="primary">Search</Button>
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowClassName="custom-product-row"
        />
      </div> */}
    </Content>
  );
}

export default ProductList;
