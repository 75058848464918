import React, { useState } from "react";
import { Table, Pagination, Typography } from "antd";
import type { TableColumnsType, TableProps } from "antd";

const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;

const { Text } = Typography;

interface DatatableProps<T> {
  columns: TableColumnsType<T>;
  dataSource: T[];
  currentPage?: number;
  pageSize?: number;
  totalRecords?: number;
  onPageChange?: (page: number, pageSize?: number) => void;
  scroll?: { x?: string | number; y?: string | number };
  showCheckbox?: boolean;
  showPagination?: boolean;
  rowSelection?: TableProps<T>["rowSelection"];
  tableClass?: string;
}

function Datatable<T extends { key: React.Key }>({
  columns,
  dataSource,
  currentPage,
  pageSize,
  totalRecords,
  onPageChange,
  scroll = { x: "max-content", y: 300 },
  showCheckbox = false,
  showPagination = true,
  rowSelection,
  tableClass = "main-table",
}: DatatableProps<T>) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  return (
    <>
      <Table<T>
        rowSelection={showCheckbox ? rowSelection : undefined}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={scroll}
        className={tableClass}
        locale={{
          emptyText: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              <img
                src={emptyImageUrl}
                alt="No data available"
                style={{ marginBottom: "8px" }}
              />
              <Text type="secondary">No data available</Text>
            </div>
          ),
        }}
      />
      {showPagination && (
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={totalRecords}
          onChange={onPageChange}
          showSizeChanger
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "16px",
          }}
        />
      )}
    </>
  );
}

export default Datatable;
