import React, { useEffect, useState } from "react";
import { Modal, Checkbox, Button, message } from "antd";

const CategoryModal = ({
  visible,
  onClose,
  onSave,
  selectedCategories,
  categoriesList,
}: any) => {
  const [selected, setSelected] = useState(selectedCategories || []);

  useEffect(() => {
    if (visible) {
      setSelected(selectedCategories || []);
    }
  }, [visible, selectedCategories]);

  const handleCategoryChange = (category: any, checked: any) => {
    const updatedSelected = checked
      ? [...selected, category]
      : selected.filter((item: any) => item.id !== category.id);

    setSelected(updatedSelected);
  };

  const handleSave = () => {
    onSave(selected);
    onClose();
  };

  return (
    <Modal
      title="Category"
      open={visible}
      onCancel={onClose}
      footer={[
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginTop: "10px" }}>
            Selected: {selected.length} items
          </div>

          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>
        </div>,
      ]}
    >
      <div style={{ maxHeight: "300px", overflowY: "auto" }}>
        {categoriesList.map((category: any) => (
          <div
            key={category.id}
            style={{
              padding: "10px",
              marginBottom: "10px",
              backgroundColor: "#f5f8ff",
              border: "1px solid #e0e7fe",
              borderRadius: "6px",
            }}
          >
            <Checkbox
              checked={selected.some((item: any) => item.id === category.id)}
              onChange={(e) => handleCategoryChange(category, e.target.checked)}
              >
              {category.name}
            </Checkbox>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default CategoryModal;
