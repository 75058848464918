import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldProps } from "formik";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import {
  Input,
  Button,
  Select,
  Switch,
  Checkbox,
  InputNumber,
  Row,
  Col,
  Modal,
  TimePicker,
  Tooltip,
  message,
  Card,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { get, post, put, uploadImg } from "../../services/Apiclient";
import { useNavigate, useParams } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { encryptPassword } from "../../constants/encryptionUtils";
import PhoneInput from "react-phone-input-2";
import ImageUpload from "../../components/imageUpload/ImageUpload";
import OpeningHoursSettings from "../../components/model/merchant/OpeningHoursSettings";

const { Option } = Select;
const { Text, Link } = Typography;

interface OpeningHours {
  [key: string]: string;
}

interface FormValues {
  storename: string;
  storecode: string;
  email: string;
  secondLanguage: string;
  phone_number: string;
  username: string;
  address: string;
  country: string;
  state: string;
  password: string;
  firstlanguage: string;
  city: string;
  time_zone: string;
  language: string;
  currency: string;
  outlet_introduction: string;
  time_format: string;
  google: string;
  xiaohongshu: string;
  instagram: string;
  tiktok: string;
  postal_code: string;
  website: string;
  facebook: string;
  youtube: string;
  latitude: number | undefined;
  longitude: number | undefined;
  storestatus: boolean;
  storelevel: string;
  tax_id?: string;
  parentstore: string | null;
  business_type: string[];
  opening_hours: OpeningHours;
}

//images path
const facebookIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Facebook.png`;
const googleIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_google.png`;
const instagramIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Instagram.png`;
const tiktokIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_TikTok.png`;
const websiteIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Website.png`;
const xiaohongshuIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Xiaohongshu.png`;
const youtubeIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Youtobe.png`;

const validationSchema = Yup.object().shape({
  storename: Yup.string().required("Outlet name is required"),
  //username: Yup.string().required("Store username is required"),
  //password: Yup.string().required("Password is required"),
  storecode: Yup.string().required("Store Code is required"),
  state: Yup.string().required("State / Province is required"),
  city: Yup.string().required("City / District is required"),
  country: Yup.string().required("Country / Region is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  postal_code: Yup.string().required("Postal Code is required"),
  tax_id: Yup.string().required("Tax Id is required"),
  phone_number: Yup.string()
    .required("Phone number is required")
    .min(10, "Phone number must be at least 10 characters")
    .max(13, "Phone number must be at Max 13 characters"),
  address: Yup.string().required("Address is required"),
  latitude: Yup.number()
    .typeError("Latitude must be a number")
    .required("Latitude is required"),
  longitude: Yup.number()
    .typeError("Longitude must be a number")
    .required("Longitude is required"),
  time_zone: Yup.string().required("Time zone is required"),
  currency: Yup.string().required("Currency is required"),
  language: Yup.string().required("Default language is required"),
  firstlanguage: Yup.string().required(
    "Customer-facing language (First language) is required"
  ),
});

const sectionTitleStyle = {
  fontWeight: "bold",
  fontSize: "16px",
  marginBottom: "16px",
  marginTop: "16px",
};

const buttonStyle = {
  backgroundColor: "#1890ff",
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  padding: "6px 12px",
  margin: "5px",
};

const dayStyle = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  marginBottom: "16px",
};

const OutletForm: React.FC = () => {
  const initialValues: FormValues = {
    storename: "",
    email: "",
    secondLanguage: "",
    storecode: "",
    phone_number: "",
    address: "",
    country: "",
    postal_code: "",
    username: "",
    time_zone: "",
    password: "",
    parentstore: null,
    outlet_introduction: "",
    city: "",
    google: "",
    xiaohongshu: "",
    tiktok: "",
    instagram: "",
    time_format: "24-hour time",
    currency: "",
    website: "",
    state: "",
    facebook: "",
    firstlanguage: "",
    youtube: "",
    language: "",
    latitude: undefined,
    longitude: undefined,
    storestatus: true,
    storelevel: "Headquarter",
    tax_id: "",
    business_type: [],
    opening_hours: {
      sunday: "09:00-18:00",
      monday: "09:00-18:00",
      tuesday: "09:00-18:00",
      wednesday: "09:00-18:00",
      thursday: "09:00-18:00",
      friday: "09:00-18:00",
      saturday: "09:00-18:00",
    },
  };

  const { id } = useParams<{ id: string }>();
  const [isEditMode, setIsEditMode] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentDay, setCurrentDay] = useState<string>("");
  const [secondLanguageEnabled, setSecondLanguageEnabled] = useState(false);
  const [stateOptions, setStateOptions] = useState([]); // Store the state options
  const [selectedCountry, setSelectedCountry] = useState("");
  const [openingHours, setOpeningHours] = useState<OpeningHours>(
    initialValues.opening_hours
  );
  const [businessOptions, setBusinessOptions] = useState([]);
  const [parentStoreOptions, setParentStoreOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]); // Store the language options
  const [formValues, setFormValues] = useState(initialValues);
  const [imageUrls, setImageUrls] = useState<string[]>([]); // Store URLs of the uploaded images
  const [imageArray, setImageArray] = useState<string[]>([]);

  const navigate = useNavigate();

  const handleOpeningHoursSave = (updatedHours: any) => {
    setOpeningHours(updatedHours); // Update the opening hours state
  };

  const fetchOutletDetails = async (id: string) => {
    try {
      const response = await get(`/outlet/${id}`);
      if (response?.data?.success) {
        const outletData = response.data.data;

        // Convert outletDaysInfo to a format compatible with the opening_hours field
        const mappedOpeningHours = outletData.outletDaysInfo.reduce(
          (acc: any, curr: any) => {
            acc[
              curr.dayname.toLowerCase()
            ] = `${curr.starttime}-${curr.endtime}`;
            return acc;
          },
          {}
        );

        // Prefill images from the fetched outlet details
        const prefilledImages = outletData.outletshowcase.map(
          (img: any) => img.imageUrl
        );
        const prefilledImageNames = outletData.outletshowcase.map(
          (img: any) => img.name
        );

        // Populate form with outlet data
        // Update form values with fetched data
        setFormValues({
          storename: outletData.storename || "",
          storecode: outletData.storecode || "",
          email: outletData.email || "",
          phone_number: outletData.phone_number || "",
          address: outletData.address || "",
          country: outletData.country || "",
          state: outletData.state || "",
          city: outletData.city || "",
          postal_code: outletData.postalcode || "",
          latitude: outletData.latitude
            ? parseFloat(outletData.latitude)
            : undefined,
          longitude: outletData.longitude
            ? parseFloat(outletData.longitude)
            : undefined,
          storestatus: outletData.storestatus === 1,
          storelevel: outletData.storelevel || "Headquarter",
          tax_id: outletData.taxid || "",
          parentstore: outletData.parentstore || null,
          outlet_introduction: outletData.storeintroduction || "",
          time_zone: outletData.timezone || "",
          time_format: outletData.timeformat || "12-hour time",
          currency: outletData.currency || "",
          firstlanguage: outletData.customer_language || "",
          language: outletData.default_language || "",
          website: outletData.website || "",
          facebook: outletData.facebook || "",
          youtube: outletData.youtube || "",
          instagram: outletData.instagram || "",
          tiktok: outletData.tiktok || "",
          xiaohongshu: outletData.xiaohongshu || "",
          google: outletData.google || "",
          business_type: outletData.businesstype.map((type: string) => type),
          opening_hours: mappedOpeningHours,
          username: outletData.username || outletData.email,
          password: "", // Keep password blank for security reasons
          secondLanguage: outletData.customer_facing_language_second,
        });

        setOpeningHours(mappedOpeningHours);
        setSecondLanguageEnabled(outletData.enable_language);
        setIsEditMode(true); // We're now in edit mode
        setImageUrls(prefilledImages); // Set prefilled images in state for ImageUpload
        setImageArray(prefilledImageNames); // Set prefilled image names for form submission
      }
    } catch (error) {
      console.error("Error fetching outlet details:", error);
    }
  };

  const fetchParentStores = async () => {
    try {
      const response = await get("/outlet/dropdown");
      if (response.data.success) {
        const options = response.data.data.map((store: any) => ({
          label: store.name,
          value: store.id,
        }));
        setParentStoreOptions(options); // Update state with fetched options
      } else {
        console.error(
          "Failed to fetch parent store options:",
          response.message
        );
      }
    } catch (error) {
      console.error("Error fetching parent store options:", error);
    }
  };

  // Fetch Country List
  const fetchCountryList = async () => {
    try {
      const response = await get("/merchant/countrylist");
      if (response.data.success) {
        const options = response.data.data.map((country: any) => ({
          label: country.name,
          value: country.name,
          code: country.code,
        }));
        setCountryOptions(options);
      } else {
        console.error("Failed to fetch country options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching country options:", error);
    }
  };

  // Fetch Time Zone List
  const fetchTimeZoneList = async () => {
    try {
      const response = await get("/merchant/timezonelist");

      if (response.data.success) {
        const options = response.data.data.map((timeZone: any) => ({
          label: timeZone.name,
          value: timeZone.name,
        }));
        setTimeZoneOptions(options); // Update state with fetched time zone options
      } else {
        console.error("Failed to fetch time zone options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching time zone options:", error);
    }
  };

  // Fetch Currency List
  const fetchCurrencyList = async () => {
    try {
      const response = await get("/merchant/currencylist");
      if (response.data.success) {
        const options = response.data.data.map((currency: any) => ({
          label: currency.name,
          value: currency.name,
        }));
        setCurrencyOptions(options); // Update state with fetched currency options
      } else {
        console.error("Failed to fetch currency options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching currency options:", error);
    }
  };

  // Fetch Language List
  const fetchLanguageList = async () => {
    try {
      const response = await get("/merchant/languagelist");

      if (response.data.success) {
        const options = response.data.data.map((language: any) => ({
          label: language.name,
          value: language.name,
        }));
        setLanguageOptions(options); // Update state with fetched language options
      } else {
        console.error("Failed to fetch language options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching language options:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchOutletDetails(id);
    }
  }, [id]);

  useEffect(() => {
    fetchParentStores(); // Fetch the parent store options on component mount
    fetchCountryList();
    fetchTimeZoneList();
    fetchCurrencyList();
    fetchLanguageList();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `/business/list`;
        const response = await get(url);
        if (response.data.success) {
          const options = response.data.data.map((business: any) => ({
            label: business.name,
            value: business.id.toString(),
          }));
          setBusinessOptions(options);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const fetchStateList = async (selectedCountry: string) => {
    if (!selectedCountry) return;
    try {
      const selectCode: any = countryOptions
        .filter((x: any) => x.label == selectedCountry)
        .map((x: any) => {
          return x;
        })[0];
      const response = await get(`/merchant/statelist/${selectCode.code}`);
      if (response.data.success) {
        const options = response.data.data.map((state: any) => ({
          label: state.name,
          value: state.name,
        }));
        setStateOptions(options);
      } else {
        setStateOptions([]);
        console.error("Failed to fetch state options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching state options:", error);
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      fetchStateList(selectedCountry);
    } else {
      setStateOptions([]); // Clear state options if no country selected
    }
  }, [selectedCountry]);

  const showEditModal = () => {
    // setCurrentDay(day);
    setIsModalVisible(true);
  };

  const handleTimeChange = (times: any) => {
    if (times && times[0] && times[1]) {
      const updatedHours = {
        ...openingHours,
        [currentDay]: `${times[0].format("HH:mm")}-${times[1].format("HH:mm")}`,
      };
      setOpeningHours(updatedHours);
    }
  };

  const handleTimeChangeClosed = () => {
    const updatedHours = {
      ...openingHours,
      [currentDay]: `closed-null`,
    };
    setOpeningHours(updatedHours);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async (values: any) => {
    let newImageNames: string[] = [];
    console.log("len");

    try {
      const response = await uploadImg(
        "/outlet/uploadimage/outletlogo",
        imageUrls
      );
      if (response && response.data) {
        if (response.data.success) {
          // Extract image filenames from the response and store them
          newImageNames = response.data.data.map((img: any) => img.imagename);
          message.success("Images uploaded successfully.");
        } else {
          console.log("Error in API call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
      console.log("Upload response:", response.data);
    } catch (error) {
      message.error("Error uploading images.");
      console.error("Upload error:", error);
    }

    // Append uploaded image names to the existing image array
    const updatedImageArray = [...imageArray, ...newImageNames];

    const businessTypeArray = values.business_type.map((id: string | number) =>
      typeof id === "string" ? parseInt(id.trim(), 10) : id
    );

    const convertSchedule = (scheduleObj: { [key: string]: unknown }) => {
      return Object.entries(scheduleObj).map(([day, time]) => {
        if (typeof time === "string") {
          const [starttime, endtime] = time.split("-");
          return {
            day: day.charAt(0).toUpperCase() + day.slice(1),
            starttime,
            endtime,
          };
        } else if (
          time === null ||
          time === undefined ||
          time === "closed-null"
        ) {
          // Handle cases where the day is marked as closed or not set
          return {
            day: day.charAt(0).toUpperCase() + day.slice(1),
            starttime: "closed",
            endtime: null,
          };
        } else {
          throw new Error(
            `Expected a string for time, but received ${typeof time}`
          );
        }
      });
    };

    const formData = {
      storename: values.storename,
      storecode: values.storecode,
      parentstore: values.parentstore || null,
      storelevel: values.storelevel || "Headquarter",
      email: values.email,
      password: encryptPassword(values.password), // Assuming encryptPassword is working as expected
      username: values.email,
      phone_number: values.phone_number,
      taxid: values.tax_id || "",
      address: values.address,
      country: values.country,
      state: values.state,
      city: values.city,
      postalcode: values.postal_code,
      latitude: values.latitude,
      longitude: values.longitude,
      storestatus: values.storestatus,
      businesstype: businessTypeArray,
      outletshowcase: updatedImageArray, // You can modify this with actual image data
      outletDaysInfo: convertSchedule(openingHours),
      website: values.website,
      facebook: values.facebook,
      youtube: values.youtube,
      instagram: values.instagram,
      tiktok: values.tiktok,
      xiaohongshu: values.xiaohongshu,
      google: values.google,
      storeintroduction: values.outlet_introduction,
      storelogo: "examplelogo.jpg", // You can modify this with actual logo data
      customer_language: values.firstlanguage,
      default_language: values.language,
      currency: values.currency,
      timeformat: values.time_format,
      timezone: values.time_zone,
      enable_language: secondLanguageEnabled, // Set based on switch state
      customer_facing_language_second: values.secondLanguage,
    };

    console.log(formData, "formData");

    try {
      let response;
      if (isEditMode) {
        // Use the put method for updating
        response = await put(`/outlet/${id}`, formData);
        if (id == localStorage.getItem("outlet_id")) {
          let store_name = formData.storecode + " - " + formData.storename;
          localStorage.setItem("store_name", store_name);
        }
      } else {
        response = await post("/outlet/addoutlet", formData);
      }
      if (response.status === 201 || response.status === 200) {
        console.log("Form submitted successfully:", response.data);
        navigate("/settings/business-details");
      } else {
        message.error(response.data.message);
        console.error("Form submission failed:", response.data);
      }
    } catch (error) {
      console.error("An error occurred during form submission:", error);
    }
  };

  // Handle images selected for upload
  const handleImagesChange = (formData: any) => {
    setImageUrls(formData); // Store the image URLs
  };

  // Handle image deletion
  const handleDeleteImage = (images: any) => {
    const filenames = images.map((url: any) => url.split("/").pop());
    setImageArray(filenames); // Update image array after deletion
  };

  return (
    <Content className="mainContainer">
      <Row justify="space-between" align="middle">
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={() => navigate("/settings/business-details")}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>
              {" "}
              {isEditMode ? "Edit Outlet" : "Add new Outlet"}
            </h2>
          </div>
        </Col>
      </Row>
      <Content
        style={{
          marginTop: "10px",
          backgroundColor: "#fafafa",
          borderRadius: "12px",
          border: "1px solid #e0e7fe",
          boxSizing: "border-box",
          height: "calc(100vh - 80px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="form-scrollable-content"
          style={{ maxHeight: "calc(100vh - 110px)" }}
        >
          <Formik
            initialValues={formValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form style={{ paddingTop: "16px" }}>
                {/* Basic Info Section */}
                <div style={sectionTitleStyle}>Basic Info</div>
                <Row gutter={16}>
                  <Col xs={24} sm={12} lg={8}>
                    <label>Store name *</label>
                    <Field name="storename">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field>
                    <ErrorMessage name="storename">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                  <Col xs={24} sm={12} lg={8} style={{ marginTop: "2.5%" }}>
                    <label style={{ marginRight: "2.5%" }}>Store Status</label>
                    <Switch
                      checked={values.storestatus}
                      onChange={(checked) =>
                        setFieldValue("storestatus", checked)
                      }
                    />
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: "16px" }}>
                  <Col xs={24} sm={12} lg={8}>
                    <label>Store Code *</label>
                    <Field name="storecode">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field>
                    <ErrorMessage name="storecode">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                  <Col
                    span={8}
                    xs={24}
                    sm={12}
                    lg={8}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>Parent store</label>
                    <Select
                      value={values.parentstore}
                      onChange={(value) => setFieldValue("parentstore", value)}
                      placeholder="Select Parent store"
                    >
                      {parentStoreOptions.map((option: any) => (
                        <Option
                          key={`parentstore-${option.value}`}
                          value={option.value}
                        >
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col
                    span={8}
                    xs={24}
                    sm={12}
                    lg={8}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>Store level</label>
                    <Select
                      value={values.storelevel}
                      onChange={(value) => setFieldValue("storelevel", value)}
                      placeholder="Select Store level"
                    >
                      <Option value="Headquarter">Headquarter</Option>
                      <Option value="Region">Region</Option>
                      {/* <Option value="Area">Area</Option> */}
                      <Option value="Branch">Branch</Option>
                    </Select>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: "16px" }}>
                  <Col xs={24} sm={12} lg={8}>
                    <label>Email *</label>
                    <Field name="email">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field>
                    <ErrorMessage name="email">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>

                  <Col xs={24} sm={12} lg={8}>
                    <label>Phone Number *</label>
                    <Field name="phone_number">
                      {({ field, form }: any) => (
                        <PhoneInput
                          country={"my"} // Set default country code, change this as needed
                          value={field.value}
                          onChange={(phone) =>
                            form.setFieldValue("phone_number", phone)
                          }
                          inputStyle={{ width: "100%", height: "46px" }} // Optional: style customization
                        />
                      )}
                    </Field>
                    <ErrorMessage name="phone_number">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>

                  <Col span={8} xs={24} sm={12} lg={8}>
                    <label>Tax ID *</label>
                    <Field name="tax_id">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field>
                    <ErrorMessage name="tax_id">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: "16px" }}>
                  <Col span={8} xs={24} sm={12} lg={8}>
                    <label>Address *</label>
                    <Field name="address">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field>
                    <ErrorMessage name="address">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: "16px" }}>
                  <Col
                    span={8}
                    xs={24}
                    sm={12}
                    lg={8}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>Country / Region *</label>
                    <Select
                      value={values.country}
                      onChange={(value) => {
                        setFieldValue("country", value);
                        setSelectedCountry(value);
                      }}
                      placeholder="Select Country"
                    >
                      {countryOptions.map((option: any) => (
                        <Option
                          key={`country-${option.value}`}
                          value={option.value}
                        >
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                    <ErrorMessage name="country">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                  <Col
                    span={8}
                    xs={24}
                    sm={12}
                    lg={8}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <label>State / Province *</label>
                    {stateOptions && stateOptions.length > 0 ? (
                      <Select
                        value={values.state}
                        onChange={(value) => setFieldValue("state", value)}
                        placeholder="Select State"
                      >
                        {stateOptions.map((option: any) => (
                          <Option
                            key={`state-${option.value}`}
                            value={option.value}
                          >
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Field name="state">
                        {({ field }: any) => (
                          <Input {...field} placeholder="Please enter" />
                        )}
                      </Field>
                    )}
                    <ErrorMessage name="state">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>

                  <Col span={8} xs={24} sm={12} lg={8}>
                    <label>City / District *</label>
                    <Field name="city">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field>
                    <ErrorMessage name="city">
                      {(msg) => (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>

                <Row gutter={16} style={{ marginTop: "16px" }}>
                  <Col span={8} xs={24} sm={12} lg={8}>
                    <label>Postal code *</label>
                    <Field name="postal_code">
                      {({ field }: FieldProps) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field>
                    <ErrorMessage name="postal_code">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </Col>

                  <Col span={8} xs={24} sm={12} lg={8}>
                    <label>Location *</label>
                    <Input.Group
                      compact
                      style={{ display: "flex", marginTop: "1px" }}
                    >
                      <Field name="latitude">
                        {({ field, form }: FieldProps) => (
                          <InputNumber
                            {...field}
                            placeholder="Latitude"
                            style={{
                              width: "50%",
                              height: "46px",
                              textAlign: "center",
                            }}
                            controls={false}
                            suffix={<CloseCircleOutlined />}
                            onChange={(value) =>
                              form.setFieldValue("latitude", value)
                            }
                          />
                        )}
                      </Field>
                      <Field name="longitude">
                        {({ field, form }: FieldProps) => (
                          <InputNumber
                            {...field}
                            placeholder="Longitude"
                            style={{
                              width: "50%",
                              textAlign: "center",
                              borderLeft: "1px solid #d9d9d9",
                            }}
                            controls={false}
                            suffix={<CloseCircleOutlined />}
                            onChange={(value) =>
                              form.setFieldValue("longitude", value)
                            }
                          />
                        )}
                      </Field>
                    </Input.Group>
                    <ErrorMessage name="latitude">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                    <ErrorMessage name="longitude">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </Col>
                </Row>

                {/* More Business Info Section */}
                <div style={sectionTitleStyle}>More Business Info</div>

                <Row gutter={16} style={{ marginTop: "16px" }}>
                  <Col span={24}>
                    <label style={{ marginRight: "16px" }}>
                      Business Type :{" "}
                    </label>
                    <Checkbox.Group
                      value={values.business_type} // Prefill from form values
                      options={businessOptions} // Use fetched options
                      onChange={
                        (checkedValues: string[]) =>
                          setFieldValue("business_type", checkedValues) // Update form field with selected values
                      }
                    />
                  </Col>
                </Row>

                {/* Opening Hours Section */}
                {/* <div style={{ marginTop: "24px" }}>
                  <label style={sectionTitleStyle}>Opening hours</label>
                  <Row>
                    {Object.keys(openingHours).map((day) => (
                      <Col
                        span={3}
                        style={dayStyle}
                        xs={12}
                        sm={8}
                        md={6}
                        lg={3}
                        key={day}
                      >
                        <Button
                          style={buttonStyle}
                          onClick={() => showEditModal(day)}
                        >
                          {day.charAt(0).toUpperCase() + day.slice(1)}
                        </Button>
                        <span>{openingHours[day]}</span>
                      </Col>
                    ))}
                  </Row>
                </div> */}
                <div style={{ marginTop: "16px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <Text
                      strong
                      style={{ fontSize: "16px", marginRight: "8px" }}
                    >
                      Opening hours
                    </Text>
                    <Link
                      style={{
                        textDecoration: "underline",
                        textUnderlineOffset: "4px",
                      }}
                      onClick={() => showEditModal()}
                    >
                      Edit
                    </Link>
                  </div>
                  <Row gutter={16}>
                    {Object.keys(openingHours).map((day) => (
                      <Col xs={24} sm={12} md={6} lg={3} key={day}>
                        <Card
                          // className="day-card"
                          className={`${
                            openingHours[day] !== null &&
                            openingHours[day] !== "closed-null"
                              ? "day-card"
                              : "day-card-closed"
                          }`}
                          bodyStyle={{
                            padding: "0px 0px 20px 0px",
                            textAlign: "center",
                          }}
                        >
                          <div
                            className={`${
                              openingHours[day] !== null &&
                              openingHours[day] !== "closed-null"
                                ? "day-header"
                                : "day-header-closed"
                            }`}
                          >
                            {day.charAt(0).toUpperCase() + day.slice(1)}
                          </div>
                          <div style={{ paddingTop: "10px" }}>
                            {openingHours[day] !== null &&
                            openingHours[day] !== "closed-null"
                              ? openingHours[day]
                              : "Closed"}
                          </div>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>

                <Row gutter={16} style={{ marginTop: "16px" }}>
                  {/* Time Zone */}
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <label>Time zone *</label>
                    <Select
                      value={values.time_zone}
                      onChange={(value) => setFieldValue("time_zone", value)}
                      placeholder="Select Time Zone"
                      style={{ width: "100%" }}
                    >
                      {timeZoneOptions.map((option: any) => (
                        <Option key={`${option.value}`} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                    <ErrorMessage name="time_zone">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </Col>

                  {/* Time Format */}
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <label>Time format</label>
                    <Select
                      placeholder="Select Time Format"
                      value={values.time_format}
                      style={{ width: "100%" }}
                      onChange={(value) => setFieldValue("time_format", value)}
                    >
                      <Option value="12-hour time">12-hour time</Option>
                      <Option value="24-hour time">24-hour time</Option>
                    </Select>
                  </Col>

                  {/* Currency */}
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <label>Currency *</label>
                    <Select
                      value={values.currency}
                      onChange={(value) => setFieldValue("currency", value)}
                      placeholder="Select Currency"
                      style={{ width: "100%" }}
                    >
                      {currencyOptions.map((option: any) => (
                        <Option key={`${option.value}`} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                    <ErrorMessage name="currency">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </Col>
                </Row>

                <Row gutter={16} style={{ marginTop: "16px" }}>
                  {/* Default Language */}
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <label>Default language *</label>
                    <Select
                      placeholder="Select Language"
                      defaultValue="English"
                      style={{ width: "100%" }}
                      value={values.language}
                      onChange={(value) => setFieldValue("language", value)}
                    >
                      {languageOptions.map((option: any) => (
                        <Option key={`${option.value}`} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                    <ErrorMessage name="language">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </Col>

                  {/* Enable Second Language */}
                  <Col
                    xs={24}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <label style={{ marginRight: "8px" }}>
                      Enable second language
                    </label>
                    <Tooltip title="If you are a native English speaker, and the store's main customer groups are Spanish speakers and French speakers, then you can set the default system display language to English, the first language to Spanish and the second language to French.">
                      <InfoCircleOutlined style={{ marginRight: "8px" }} />
                    </Tooltip>
                    <Switch
                      checked={secondLanguageEnabled}
                      onChange={(checked) => setSecondLanguageEnabled(checked)}
                    />
                  </Col>

                  {/* Customer-facing Language (First Language) */}
                  {!secondLanguageEnabled && (
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <label>Customer-facing language (First language) *</label>
                      <Select
                        placeholder="Please select" // Disable if second language is not enabled
                        style={{ width: "100%" }}
                        value={values.firstlanguage}
                        onChange={(value) =>
                          setFieldValue("firstlanguage", value)
                        }
                      >
                        {languageOptions.map((option: any) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                      <ErrorMessage name="firstlanguage">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </Col>
                  )}
                </Row>

                <Row gutter={16} style={{ marginTop: "16px" }}>
                  {/* Conditionally Render Second Language Dropdown */}
                  {secondLanguageEnabled && (
                    <>
                      <Col span={8} xs={24} sm={12} lg={8}>
                        <label>
                          Customer-facing language (Second language)
                        </label>
                        <Select
                          placeholder="Please select"
                          value={values.secondLanguage}
                          onChange={(value) =>
                            setFieldValue("secondLanguage", value)
                          }
                          style={{ width: "100%" }}
                        >
                          {languageOptions.map((option: any) => (
                            <Option key={option.value} value={option.value}>
                              {option.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>

                      <Col span={8} xs={24} sm={12} lg={8}>
                        <label>
                          Customer-facing language (First language) *
                        </label>
                        <Select
                          placeholder="Please select" // Disable if second language is not enabled
                          style={{ width: "100%" }}
                          value={values.firstlanguage}
                          onChange={(value) =>
                            setFieldValue("firstlanguage", value)
                          }
                        >
                          {languageOptions.map((option: any) => (
                            <Option key={option.value} value={option.value}>
                              {option.label}
                            </Option>
                          ))}
                        </Select>
                        <ErrorMessage name="firstlanguage">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </Col>
                    </>
                  )}
                </Row>

                <Row gutter={16} style={{ marginTop: "3%" }}>
                  {/* Image Upload Section */}
                  {/* <Col span={12}>
                  <label>
                  Store logo
                        </label>
                <ImageUpload
                  prefilledImages={imageUrls} // Prefilled images (if any) for edit mode
                  onImagesChange={handleImagesChange} // Handle image selection
                  onDeleteImage={handleDeleteImage} // Handle image deletion
                />
              </Col> */}
                  <Col span={12}>
                    <label>Store showcase image</label>
                    <ImageUpload
                      prefilledImages={imageUrls} // Prefilled images (if any) for edit mode
                      onImagesChange={handleImagesChange} // Handle image selection
                      onDeleteImage={handleDeleteImage} // Handle image deletion
                    />
                  </Col>
                </Row>

                <Row gutter={16} style={{ marginTop: "3%" }}>
                  {/* Store Introduction */}
                  <Col span={24}>
                    <label>Store Introduction</label>
                    <Field name="outlet_introduction">
                      {({ field }: FieldProps) => (
                        <Input.TextArea
                          {...field}
                          placeholder="Please enter"
                          rows={5}
                        />
                      )}
                    </Field>
                  </Col>
                </Row>

                <Row gutter={16} style={{ marginTop: "16px" }}>
                  {/* Website */}
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <label>Website</label>
                    <Field name="website">
                      {({ field }: FieldProps) => (
                        <Input
                          {...field}
                          placeholder="Please enter"
                          prefix={
                            <img
                              src={websiteIcon}
                              alt="Website"
                              style={{ width: 24 }}
                            />
                          }
                        />
                      )}
                    </Field>
                  </Col>

                  {/* Facebook */}
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <label>Facebook</label>
                    <Field name="facebook">
                      {({ field }: FieldProps) => (
                        <Input
                          {...field}
                          placeholder="Please enter"
                          prefix={
                            <img
                              src={facebookIcon}
                              alt="Facebook"
                              style={{ width: 24 }}
                            />
                          }
                        />
                      )}
                    </Field>
                  </Col>

                  {/* YouTube */}
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <label>YouTube</label>
                    <Field name="youtube">
                      {({ field }: FieldProps) => (
                        <Input
                          {...field}
                          placeholder="Please enter"
                          prefix={
                            <img
                              src={youtubeIcon}
                              alt="YouTube"
                              style={{ width: 24 }}
                            />
                          }
                        />
                      )}
                    </Field>
                  </Col>
                </Row>

                <Row gutter={16} style={{ marginTop: "16px" }}>
                  {/* Instagram */}
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <label>Instagram</label>
                    <Field name="instagram">
                      {({ field }: FieldProps) => (
                        <Input
                          {...field}
                          placeholder="Please enter"
                          prefix={
                            <img
                              src={instagramIcon}
                              alt="Instagram"
                              style={{ width: 24 }}
                            />
                          }
                        />
                      )}
                    </Field>
                  </Col>

                  {/* TikTok */}
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <label>TikTok</label>
                    <Field name="tiktok">
                      {({ field }: FieldProps) => (
                        <Input
                          {...field}
                          placeholder="Please enter"
                          prefix={
                            <img
                              src={tiktokIcon}
                              alt="TikTok"
                              style={{ width: 24 }}
                            />
                          }
                        />
                      )}
                    </Field>
                  </Col>

                  {/* Xiaohongshu */}
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <label>Xiaohongshu</label>
                    <Field name="xiaohongshu">
                      {({ field }: FieldProps) => (
                        <Input
                          {...field}
                          placeholder="Please enter"
                          prefix={
                            <img
                              src={xiaohongshuIcon}
                              alt="Xiaohongshu"
                              style={{ width: 24 }}
                            />
                          }
                        />
                      )}
                    </Field>
                  </Col>
                </Row>

                <Row gutter={16} style={{ marginTop: "16px" }}>
                  {/* Google */}
                  <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <label>Google</label>
                    <Field name="google">
                      {({ field }: FieldProps) => (
                        <Input
                          {...field}
                          placeholder="Please enter"
                          prefix={
                            <img
                              src={googleIcon}
                              alt="Google"
                              style={{ width: 24 }}
                            />
                          }
                        />
                      )}
                    </Field>
                  </Col>
                </Row>

                <OpeningHoursSettings
                  visible={isModalVisible}
                  onClose={() => setIsModalVisible(false)}
                  openingHours={openingHours}
                  onSave={handleOpeningHoursSave}
                />

                {/* <Modal
                  title={`Edit ${
                    currentDay.charAt(0).toUpperCase() + currentDay.slice(1)
                  } Hours`}
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  {openingHours[currentDay] &&
                    openingHours[currentDay][0] !== "closed" && (
                      <TimePicker.RangePicker
                        format="HH:mm"
                        onChange={handleTimeChange}
                        defaultValue={[
                          dayjs(
                            openingHours[currentDay]?.split("-")[0],
                            "HH:mm"
                          ),
                          dayjs(
                            openingHours[currentDay]?.split("-")[1],
                            "HH:mm"
                          ),
                        ]}
                      />
                    )}
                </Modal> */}

                {/* Save Section */}
                <div className="save-section">
                  <Button
                    type="default"
                    onClick={() => navigate("/settings/business-details")}
                    style={{ marginRight: 8 }}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit">
                    {isEditMode ? "Update" : "Save"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Content>
    </Content>
  );
};

export default OutletForm;
