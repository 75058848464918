import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Layout,
  Row,
  Col,
  message,
} from "antd";
import * as Yup from "yup";
import { get, post, put } from "../../services/Apiclient";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import ReferrerModal from "../../components/model/customer/ReferrerModal";
import SelectStaffModal from "../../components/model/customer/SelectStaffModal";
import dayjs, { Dayjs } from "dayjs";

const { Option } = Select;
const { TextArea } = Input;
const { Content } = Layout;

const facebookIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Facebook.png`;
const instaIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Instagram.png`;
const whatsappIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_WhatsApp.png`;
interface FormValues {
  status: boolean;
  customercode: string;
  firstname: string;
  lastname: string;
  phonenumber: string;
  email: string;
  gender: string;
  physicalcard: string;
  dob: Dayjs | null | string;
  language: string;
  notes: string;
  source: string;
  referralby: string;
  firstconsultant: string;
  secondconsultant: string;
  thirdconsultant: string;
  passportid: string;
  emergency_contact: string;
  alternate_number: string;
  homeaddress: string;
  occupation: string;
  whatsapp: string;
  facebook: string;
  instagram: string;
  outlet_id?: string | null;
}

const CustomerForm: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isReferralModalVisible, setIsReferralModalVisible] = useState(false);
  const [isFirstConsultantModalVisible, setIsFirstConsultantModalVisible] =
    useState(false);
  const [isSecondConsultantModalVisible, setIsSecondConsultantModalVisible] =
    useState(false);
  const [isThirdConsultantModalVisible, setIsThirdConsultantModalVisible] =
    useState(false);
  const [referralText, setReferralText] = useState("");
  const [firstConsultantText, setFirstConsultantText] = useState("");
  const [secondConsultantText, setSecondConsultantText] = useState("");
  const [thirdConsultantText, setThirdConsultantText] = useState("");

  const isUpdate = Boolean(id);

  // Validation Schema
  const validationSchema = Yup.object().shape({
    customercode: Yup.string().required("Please enter customer code"),
    firstname: Yup.string().required("Please enter first name"),
    lastname: Yup.string().required("Please enter last name"),
    phonenumber: Yup.string()
      .required("Please enter phone number")
      .min(10, "Phone number must be at least 10 characters")
      .max(13, "Phone number must be at MAx 13 characters"),
  });

  // Initial form values
  const initialFormValues = {
    status: true,
    customercode: "",
    firstname: "",
    lastname: "",
    phonenumber: "",
    email: "",
    gender: "",
    physicalcard: "",
    dob: null,
    language: "",
    notes: "",
    source: "",
    referralby: "",
    firstconsultant: "",
    secondconsultant: "",
    thirdconsultant: "",
    passportid: "",
    emergency_contact: "",
    alternate_number: "",
    homeaddress: "",
    occupation: "",
    facebook: "",
    instagram: "",
    whatsapp: "",
    outlet_id: localStorage.getItem("outlet_id"),
  };

  const [formValues, setFormValues] = useState<FormValues>(initialFormValues);

  const handleInputChange = (name: string, value: any) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const generateCustomerCode = async () => {
    try {
      const response = await get(`/user/randomgenerated`);
      if (response?.data?.success) {
        const data = response.data.result;
        handleInputChange("customercode", data);
      }
    } catch (error) {
      message.error("Failed to generate customer code");
    }
  };

  const getFormattedDate = (date: string | Dayjs | null = dayjs()) => {
    let parsedDate: Date;

    if (date === null) {
      return ""; // Handle null date, return an empty string or a default value
    }

    // Convert Dayjs to Date if necessary
    if (dayjs.isDayjs(date)) {
      parsedDate = date.toDate();
    } else if (typeof date === "string" || typeof date === "number") {
      parsedDate = new Date(date);
    } else {
      parsedDate = date;
    }

    const day = String(parsedDate.getDate()).padStart(2, "0");
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const year = String(parsedDate.getFullYear());

    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formValues, { abortEarly: false });

      const url = isUpdate ? `/customer/${id}` : "/customer/addcustomer";
      const body = { ...formValues };
      body.dob = getFormattedDate(dayjs(body.dob));

      const response = isUpdate ? await put(url, body) : await post(url, body);

      if (response && response.data && response.data.success) {
        setFormValues(initialFormValues);
        if (isUpdate) {
          navigate(`/customer/view/${id}`);
          message.success("Successfully edited!");
        } else {
          navigate("/customer");
          message.success("Successfully added!");
        }
      } else {
        message.error(response.data.message || "Submission failed");
      }
    } catch (errors) {
      if (errors instanceof Yup.ValidationError) {
        const firstError = errors.inner[0];
        if (firstError) {
          message.error(firstError.message);
        }
      }
    }
  };

  const requiredLabel = (label: string) => (
    <span>
      <span style={{ color: "red" }}>* </span>
      {label}
    </span>
  );

  const handlePhoneNumberChange = (phone: string) => {
    handleInputChange("phonenumber", phone);
  };

  const handleAlternateNumberChange = (phone: string) => {
    handleInputChange("alternate_number", phone);
  };

  const showFirstConsultantModal = () => setIsFirstConsultantModalVisible(true);

  const showSecondConsultantModal = () =>
    setIsSecondConsultantModalVisible(true);

  const showThirdConsultantModal = () => setIsThirdConsultantModalVisible(true);

  const showReferralModal = () => setIsReferralModalVisible(true);
  const onCancel = () => {
    setIsReferralModalVisible(false);
  };

  const onCancelFirstConsultantModal = () => {
    setIsFirstConsultantModalVisible(false);
  };

  const onCancelSecondConsultantModal = () => {
    setIsSecondConsultantModalVisible(false);
  };

  const onCancelThirdConsultantModal = () => {
    setIsThirdConsultantModalVisible(false);
  };

  const handleReferrerSelect = (selectedObject: any) => {
    // setSelectedReferrer(selectedObject);
    setReferralText(selectedObject.name);
    handleInputChange("referralby", selectedObject.id);
  };

  const handleFirstConsultantSelect = (selectedObject: any) => {
    // setSelectedReferrer(selectedObject);
    if (selectedObject == null) {
      setFirstConsultantText("");
      handleInputChange("firstconsultant", "");
    } else {
      setFirstConsultantText(selectedObject.name);
      handleInputChange("firstconsultant", selectedObject.id);
    }
  };

  const handleSecondConsultantSelect = (selectedObject: any) => {
    if (selectedObject == null) {
      setSecondConsultantText("");
      handleInputChange("secondconsultant", "");
    } else {
      setSecondConsultantText(selectedObject.name);
      handleInputChange("secondconsultant", selectedObject.id);
    }
  };

  const handleThirdConsultantSelect = (selectedObject: any) => {
    // setSelectedReferrer(selectedObject);
    if (selectedObject == null) {
      setThirdConsultantText("");
      handleInputChange("thirdconsultant", "");
    } else {
      setThirdConsultantText(selectedObject.name);
      handleInputChange("thirdconsultant", selectedObject.id);
    }
  };

  useEffect(() => {
    if (isUpdate) {
      const fetchCustomerData = async () => {
        try {
          const response = await get(`/customer/${id}`);
          if (response?.data?.success) {
            const data = response.data.data;
            setFormValues({
              ...initialFormValues,
              status: data.status,
              customercode: data.customercode,
              firstname: data.firstname,
              lastname: data.lastname,
              phonenumber: data.phonenumber,
              email: data.email,
              gender: data.gender,
              physicalcard: data.physicalcard,
              dob: data.dob ? dayjs(data.dob) : null, // Convert DOB to moment object
              language: data.language,
              notes: data.notes,
              source: data.source,
              referralby: data.referralby,
              firstconsultant: data.firstconsultant,
              secondconsultant: data.secondconsultant,
              thirdconsultant: data.thirdconsultant,
              passportid: data.passportid,
              emergency_contact: data.emergency_contact,
              alternate_number: data.alternate_number,
              homeaddress: data.homeaddress,
              occupation: data.occupation,
              whatsapp: data.whatsapp,
              facebook: data.facebook,
              instagram: data.instagram,
              outlet_id: data.outlet_id,
            });
            setReferralText(data.referralname);
            setFirstConsultantText(data.firstconsultantname);
            setSecondConsultantText(data.secondconsultantname);
            setThirdConsultantText(data.thirdconsultantname);
          } else {
            message.error("Failed to fetch customer data");
          }
        } catch (error) {
          message.error("Failed to fetch customer data");
        }
      };
      fetchCustomerData();
    } else {
      generateCustomerCode(); // Generate customer code only for new customer
    }
  }, [id]);

  return (
    <Content className="mainContainer">
      <Row justify="space-between" align="middle">
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={() => navigate("/customer")}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>
              {" "}
              {isUpdate == true ? "Edit Customer Info" : "Add Customer Info"}
            </h2>
          </div>
        </Col>
      </Row>
      <Content
        style={{
          marginTop: "10px",
          backgroundColor: "#fafafa",
          borderRadius: "12px",
          border: "1px solid #e0e7fe",
          boxSizing: "border-box",
          padding: "10px 0px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          maxWidth: "1250px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div
          className="form-scrollable-content"
          style={{ scrollbarWidth: "thin" }}
        >
          <Form layout="vertical" onFinish={handleSubmit}>
            <div
              style={{ fontWeight: "bold", fontSize: "16px", margin: "16px 0" }}
            >
              Basic Info
            </div>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label={null}>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <label style={{ fontWeight: "500" }}>
                      {requiredLabel("Customer Code")}
                    </label>
                    <a
                      onClick={generateCustomerCode}
                      style={{ color: "#325df2" }}
                    >
                      Generate automatically
                    </a>
                  </div>
                  <Input
                    placeholder="Please enter"
                    value={formValues.customercode}
                    onChange={(e) =>
                      handleInputChange("customercode", e.target.value)
                    }
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item label="Name" required>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item>
                        <Input
                          placeholder="First name"
                          value={formValues.firstname}
                          onChange={(e) =>
                            handleInputChange("firstname", e.target.value)
                          }
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item>
                        <Input
                          placeholder="Last name"
                          value={formValues.lastname}
                          onChange={(e) =>
                            handleInputChange("lastname", e.target.value)
                          }
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="Phone Number" required>
                  <PhoneInput
                    country="my"
                    value={formValues.phonenumber}
                    onChange={handlePhoneNumberChange}
                    inputStyle={{ width: "100%", height: "40px" }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Email">
                  <Input
                    placeholder="Enter email"
                    value={formValues.email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Gender">
                  <Select
                    placeholder="Select gender"
                    value={formValues.gender}
                    onChange={(value) => handleInputChange("gender", value)}
                  >
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                    <Option value="Non-binary">Non-binary</Option>
                    <Option value="Prefer not to say">Prefer not to say</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="Physical card">
                  <Input
                    placeholder="Enter the card code"
                    value={formValues.physicalcard}
                    onChange={(e) =>
                      handleInputChange("physicalcard", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Date of Birth">
                  <DatePicker
                    style={{ width: "100%" }}
                    value={formValues.dob}
                    onChange={(date) => handleInputChange("dob", date)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Language">
                  <Select
                    placeholder="Select language"
                    value={formValues.language}
                    onChange={(value) => handleInputChange("language", value)}
                  >
                    <Option value="en">English</Option>
                    <Option value="ms">Malay</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item label="Notes">
                  <TextArea
                    placeholder="Please enter"
                    value={formValues.notes}
                    onChange={(e) => handleInputChange("notes", e.target.value)}
                    rows={4}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div
              style={{ fontWeight: "bold", fontSize: "16px", margin: "16px 0" }}
            >
              Customer Info
            </div>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="Customer Source">
                  <Select
                    placeholder="Please Select"
                    value={formValues.source}
                    onChange={(value) => handleInputChange("source", value)}
                  >
                    <Option value="Walk-in">Walk-in</Option>
                    <Option value="Online advertisement">
                      Online advertisement
                    </Option>
                    <Option value="Referred by a friend">
                      Referred by a friend
                    </Option>
                    <Option value="Social Media">Social Media</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Referrer">
                  <Input
                    placeholder="Please select"
                    onClick={showReferralModal}
                    readOnly
                    value={referralText}
                    suffix={<span className="textSuffix">&gt;</span>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="Consultant 1">
                  {/* <Input
                  placeholder="Enter first consultant"
                  value={formValues.firstconsultant}
                  onChange={(e) =>
                    handleInputChange("firstconsultant", e.target.value)
                  }
                /> */}
                  <Input
                    placeholder="Please select"
                    onClick={showFirstConsultantModal}
                    readOnly
                    value={firstConsultantText}
                    suffix={<span className="textSuffix">&gt;</span>}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Consultant 2">
                  {/* <Input
                  placeholder="Enter second consultant"
                  value={formValues.secondconsultant}
                  onChange={(e) =>
                    handleInputChange("secondconsultant", e.target.value)
                  }
                /> */}
                  <Input
                    placeholder="Please select"
                    onClick={showSecondConsultantModal}
                    readOnly
                    value={secondConsultantText}
                    suffix={<span className="textSuffix">&gt;</span>}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Consultant 3">
                  {/* <Input
                  placeholder="Enter third consultant"
                  value={formValues.thirdconsultant}
                  onChange={(e) =>
                    handleInputChange("thirdconsultant", e.target.value)
                  }
                /> */}
                  <Input
                    placeholder="Please select"
                    onClick={showThirdConsultantModal}
                    readOnly
                    value={thirdConsultantText}
                    suffix={<span className="textSuffix">&gt;</span>}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div
              style={{ fontWeight: "bold", fontSize: "16px", margin: "16px 0" }}
            >
              Other Info
            </div>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="Passport ID">
                  <Input
                    placeholder="Please enter"
                    value={formValues.passportid}
                    onChange={(e) =>
                      handleInputChange("passportid", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Emergency Contact">
                  <Input
                    placeholder="Please enter"
                    value={formValues.emergency_contact}
                    onChange={(e) =>
                      handleInputChange("emergency_contact", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Emergency contact number">
                  <PhoneInput
                    country="my"
                    value={formValues.alternate_number}
                    onChange={handleAlternateNumberChange}
                    inputStyle={{ width: "100%", height: "40px" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item label="Home Address">
                  <TextArea
                    placeholder="Please enter"
                    value={formValues.homeaddress}
                    onChange={(e) =>
                      handleInputChange("homeaddress", e.target.value)
                    }
                    rows={5}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="Occupation">
                  <Input
                    placeholder="Please enter"
                    value={formValues.occupation}
                    onChange={(e) =>
                      handleInputChange("occupation", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="WhatsApp">
                  <Input
                    placeholder="Please enter"
                    prefix={
                      <img
                        src={whatsappIcon}
                        alt="WhatsApp"
                        style={{ width: 20 }}
                      />
                    }
                    value={formValues.whatsapp}
                    onChange={(e) =>
                      handleInputChange("whatsapp", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Facebook">
                  <Input
                    placeholder="Please enter"
                    prefix={
                      <img
                        src={facebookIcon}
                        alt="Facebook"
                        style={{ width: 20 }}
                      />
                    }
                    value={formValues.facebook}
                    onChange={(e) =>
                      handleInputChange("facebook", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="Ins">
                  <Input
                    placeholder="Please enter"
                    prefix={
                      <img
                        src={instaIcon}
                        alt="Instagram"
                        style={{ width: 20 }}
                      />
                    }
                    value={formValues.instagram}
                    onChange={(e) =>
                      handleInputChange("instagram", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* <Form.Item>
              <div className="save-section">
                <Button type="primary" onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </Form.Item> */}
          </Form>
        </div>
        <div className="save-section">
          <Button type="primary" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </Content>
      <ReferrerModal
        visible={isReferralModalVisible}
        onClose={onCancel}
        onSelect={handleReferrerSelect}
      ></ReferrerModal>
      <SelectStaffModal
        visible={isFirstConsultantModalVisible}
        onClose={onCancelFirstConsultantModal}
        onSelect={handleFirstConsultantSelect}
      ></SelectStaffModal>
      <SelectStaffModal
        visible={isSecondConsultantModalVisible}
        onClose={onCancelSecondConsultantModal}
        onSelect={handleSecondConsultantSelect}
      ></SelectStaffModal>
      <SelectStaffModal
        visible={isThirdConsultantModalVisible}
        onClose={onCancelThirdConsultantModal}
        onSelect={handleThirdConsultantSelect}
      ></SelectStaffModal>
    </Content>
  );
};

export default CustomerForm;
