import React, { useEffect, useState } from "react";
import { Layout, Menu, Button, Input, Space, Select, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ProductUnits from "./ProductUnits";
import Storages from "./Storages";
import Taxes from "./Taxes";
import Designations from "./Designations";
import Departments from "./Departments";
import { get } from "../../services/Apiclient";
import RoomTypes from "./RoomTypes";
import Titles from "./Titles";
import CustomerSource from "./CustomerSource";

const { Sider, Content } = Layout;
const { Option } = Select;

const menuItems = [
  { key: "Customer sources", label: "Customer sources" },
  { key: "Departments", label: "Departments" },
  { key: "Designations", label: "Designations" },
  // { key: "Follow-up plans", label: "Follow-up plans" },
  // { key: "Payment methods", label: "Payment methods" },
  { key: "Product units", label: "Product units" },
  // { key: "Stock in methods", label: "Stock in methods" },
  // { key: "Stock out methods", label: "Stock out methods" },
  { key: "Storages", label: "Storages" },
  // { key: "Store levels", label: "Store levels" },
  { key: "Taxes", label: "Taxes" },
  // { key: "Time off Types", label: "Time off Types" },
  // { key: "Tips", label: "Tips" },
  { key: "Titles", label: "Titles" },
  // { key: "Service Category", label: "Service Category" },
  // { key: "Rewards & Penalties", label: "Rewards & Penalties" },
  { key: "Room types", label: "Room types" },
  // { key: "Expenses", label: "Expenses" },
  // { key: "Other income", label: "Other income" },
  // { key: "Staff service types", label: "Staff service types" },
];

const CommonData = () => {
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState("Customer sources");
  const [searchText, setSearchText] = useState("");

  const [outletData, setOutletData] = useState<any[]>([]);

  const [outletCode, setoutletCode] = useState<any>(
    localStorage.getItem("outlet_id") ? localStorage.getItem("outlet_id") : null
  );

  const handleOutletChange = (value: string) => {
    setoutletCode(value);
  };

  const renderContent = () => {
    switch (selectedKey) {
      case "Customer sources":
        return <CustomerSource selectedOutlet={outletCode} />;
      case "Departments":
        return <Departments selectedOutlet={outletCode} />;
      case "Designations":
        return <Designations selectedOutlet={outletCode} />;
      case "Product units":
        return <ProductUnits selectedOutlet={outletCode} />;
      case "Storages":
        return <Storages selectedOutlet={outletCode} />;
      case "Taxes":
        return <Taxes selectedOutlet={outletCode} />;
      case "Room types":
        return <RoomTypes selectedOutlet={outletCode} />;
      case "Titles":
        return <Titles selectedOutlet={outletCode} />;
    }
  };

  const getOutletDropdown = async () => {
    try {
      let url = "/outlet/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setOutletData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  useEffect(() => {
    getOutletDropdown();
  }, []);

  // Filter menu items based on search input
  const filteredMenuItems = menuItems.filter((item) =>
    item.label.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Layout style={{ flexDirection: "column", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "12px 20px",
          backgroundColor: "transparent",
        }}
      >
        {/* Back Button */}
        <Button
          type="link"
          icon={
            <ArrowLeftOutlined
              style={{ fontSize: "16px", color: "#2e56f2", cursor: "pointer" }}
            />
          }
          onClick={() => navigate("/settings")}
          style={{
            color: "#000",
            fontWeight: "700",
            fontSize: "18px",
            fontFamily: "SourceHanSansCN-Bold,PingFangSC-Semibold,PingFang SC",
            cursor: "text",
          }}
        >
          Common Data
        </Button>

        <Space>
          <Select
            value={outletCode}
            onChange={(value) => handleOutletChange(value)}
            style={{
              width: "224px",
              textAlign: "left",
              border: "1px solid #e0e7fe",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            {outletData.length > 0 &&
              outletData.map((outdata: any) => (
                <Option value={outdata.id} key={outdata.id}>
                  {outdata.name}
                </Option>
              ))}
          </Select>
          <Button
            style={{
              fontWeight: "700",
              height: "40px",
              marginLeft: "40px",
              padding: "5px 12px",
              borderColor: "#e0e7fe",
              color: "#333",
              borderRadius: "6px",
              background: "#fff",
              minWidth: "72px",
              width: "192px",
            }}
          >
            Duplicate & Distribute
          </Button>
        </Space>
      </div>

      {/* Sidebar menu with search functionality */}
      <div
        style={{
          display: "flex",
          padding: "0px 12px 16px 24px",
          height: "calc(100vh - 80px)",
        }}
      >
        <Sider
          width={350}
          style={{
            background: "#fff",
            padding: "0px",
            height: "calc(100vh - 120px)",
            borderRadius: "8px",
            overflowY: "auto",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              padding: "8px 16px 16px 16px",
              display: "flex",
              position: "sticky",
              top: 0,
              background: "#fff",
              width: "350px",
              zIndex: "10",
              color: "#333",
              fontFamily:
                "SourceHanSansCN-Bold,PingFangSC-Semibold,PingFang SC",
            }}
          >
            <h3>Types</h3>
          </div>

          <div
            style={{
              overflowY: "hidden",
              height: "calc(100vh - 220px)",
              width: "350px",
            }}
            className="custom-scrollbar"
            onMouseEnter={(e) => (e.currentTarget.style.overflowY = "auto")} // Show scrollbar on hover
            onMouseLeave={(e) => (e.currentTarget.style.overflowY = "hidden")} // Hide scrollbar on leave
          >
            <Input
              placeholder="Please enter"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)} // Update search text on input change
              style={{ margin: "16px 16px 0px 16px", width: "300px" }}
              allowClear
            />

            {/* Filtered Menu Items */}
            <Menu
              mode="inline"
              selectedKeys={[selectedKey]}
              onSelect={(item) => setSelectedKey(item.key)}
              style={{ margin: "16px", width: "300px" }}
            >
              {filteredMenuItems.map((item) => (
                <Menu.Item
                  key={item.key}
                  style={{
                    paddingRight: "16px",
                    color: item.key == selectedKey ? "#fff" : "#666",
                    background: item.key == selectedKey ? "#2e56f2" : "#fff",
                    fontWeight: "500",
                    borderRadius: item.key == selectedKey ? "6px" : "0px",
                  }}
                >
                  {item.label}
                </Menu.Item>
              ))}
            </Menu>
          </div>
        </Sider>

        <Layout style={{ padding: "0px 16px 24px 16px" }}>
          <Content
            style={{
              padding: "8px 24px",
              background: "#fff",
              borderRadius: "8px",
              width: "calc(100vw - 410px)",
            }}
          >
            {renderContent()}
          </Content>
        </Layout>
      </div>
    </Layout>
  );
};

export default CommonData;
