import React, { FC, useEffect, useState } from "react";
import { Modal, Tabs, Input, Card, Checkbox, Row, Col, Button } from "antd";
import { ClearOutlined, UndoOutlined } from "@ant-design/icons";
import { get } from "../../../services/Apiclient";
const StaffIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_Avatar_EmpM.png`;

interface SelectStaffModalProps {
  visible: boolean;
  onClose: () => void;
  onSelect: (selectedObject: any) => void;
}

const SelectStaffModal: FC<SelectStaffModalProps> = ({
  visible,
  onClose,
  onSelect,
}) => {
  const { TabPane } = Tabs;

  const [staffData, setStaffData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (visible) {
      get("/staff/staffwithdest")
        .then((response) => {
          if (response.data.success) {
            setStaffData(response.data.data);
          }
        })
        .catch((error) => console.error("Error fetching staff data:", error));
    }
  }, [visible]);

  const handleSearch = (designationData: any) => {
    return designationData.filter((staff: any) =>
      staff.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const handleCardClick = (selectedObject: any) => {
    onSelect(selectedObject);
    onClose();
  };

  const renderStaffCards = (designationData: any) =>
    handleSearch(designationData).map((staff: any) => (
      <Card
        key={staff.id}
        onClick={() => handleCardClick(staff)}
        style={{ width: 120, margin: 10, cursor: "pointer" }}
      >
        {staff.profile_pic && staff.profile_pic !== "" && (
          <img
            src={staff.profile_pic || StaffIcon}
            alt="Staff Avatar"
            style={{ width: 50, borderRadius: "50%" }}
          />
        )}
        {staff.profile_pic == "" && (
          <img
            src={StaffIcon}
            alt="Staff Avatar"
            style={{ width: 80, borderRadius: "50%" }}
          />
        )}
        <div style={{ fontWeight: "700" }}>{staff.name}</div>
        <div>{staff.staffcode}</div>
      </Card>
    ));

  return (
    <Modal
      visible={visible}
      title="Select Staff"
      onCancel={onClose}
      footer={null}
      width="60vw"
      // className="selection-staff-modal"
    >
      <Tabs
        defaultActiveKey="1"
        className="scrollable-tabs"
        tabBarExtraContent={
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              icon={<ClearOutlined />}
              style={{
                color: "#1890ff",
                border: "none",
                backgroundColor: "transparent",
                marginRight: 10,
              }}
              onClick={() => handleCardClick(null)}
            />
            <Button
              icon={<UndoOutlined />}
              style={{
                color: "#1890ff",
                border: "none",
                backgroundColor: "transparent",
              }}
            />
          </div>
        }
      >
        {staffData.map((designation: any) => (
          <TabPane tab={designation.designationName} key={designation.id}>
            <Input
              placeholder="Search by staff code/name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ marginBottom: 20 }}
            />
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {renderStaffCards(designation.data)}
            </div>
          </TabPane>
        ))}
      </Tabs>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 20,
        }}
      >
        <div>
          <Checkbox>Show staffs on staffing</Checkbox>
          <Checkbox>Show formerly dispatched staffs</Checkbox>
        </div>
      </div>
    </Modal>
  );
};

export default SelectStaffModal;
