import React from "react";
import { Modal, Button } from "antd";
const emptyIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;

// Define the props interface
interface TagSelectModalProps {
  visible: boolean;
  onClose: () => void;
}

const TagSelectModal: React.FC<TagSelectModalProps> = ({
  visible,
  onClose,
}) => {
  return (
    <Modal
      title="Select Tags"
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
      width={400}
    >
      <div style={{ textAlign: "center", padding: "20px" }}>
        <img
          src={emptyIcon} // Update the path if needed
          alt="No labels available"
          style={{ width: "50px", height: "50px", marginBottom: "10px" }}
        />
        <h4>No labels currently available</h4>
      </div>
      <div style={{ textAlign: "right" }}>
        <Button type="primary" onClick={onClose}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default TagSelectModal;
