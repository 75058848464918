import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import Datatable from "../../Datatable";
interface PerformancePlanModelProps {
  title: string;
  visible: boolean;
  onCancel: () => void;
  onSave: (selectedData: any[]) => void;
  selectedProducts: any[];
  footer?: React.ReactNode;
  staticData: any[]; // New prop to receive static data
}
const PerformancePlanModel: React.FC<PerformancePlanModelProps> = ({
  title,
  visible,
  onCancel,
  onSave,
  selectedProducts,
  footer,
  staticData, // Use static data passed as a prop
}) => {
  const [serviceData, setServiceData] = useState<any[]>(staticData); // Initialize with static data
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  useEffect(() => {
    setServiceData(staticData);
  }, [staticData]);

  const handleSave = () => {
    if (selectedRowKeys.length > 0) {
      const selectedData = serviceData.filter((item) =>
        selectedRowKeys.includes(item.key)
      );
      
      onSave(selectedData);
      onCancel();
      
    } else {
      onSave([]); // If nothing is selected, pass an empty array
      onCancel();
    }
  };
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      footer={footer}
      width={800}
    >
      <Datatable<any>
        rowSelection={rowSelection}
        columns={[
          { title: "Plan Code", dataIndex: "plancode" },
          { title: "Plan Name", dataIndex: "planname" },
          { title: "Creation Date", dataIndex: "creationdate" },
          { title: "Staff linked to", dataIndex: "stafflinkedto" },
          { title: "Note", dataIndex: "note" },
        ]}
        dataSource={serviceData}
        showCheckbox= {true}
        // Other props like pagination
      />
      <div
        style={{
          marginTop: 16,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div></div>
        <div>
          <Button
            key="cancel"
            onClick={onCancel}
            style={{ marginRight: "10px" }}
          >
            Cancel
          </Button>
          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default PerformancePlanModel;