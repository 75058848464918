import React from "react";
import { Tabs, Table, Empty, Typography } from "antd";
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;

const { TabPane } = Tabs;
const { Text } = Typography;

const SalesHistory: React.FC = () => {
  const columns = [
    { title: "Ref#", dataIndex: "ref", key: "ref" },
    { title: "Bill type", dataIndex: "billType", key: "billType" },
    {
      title: "Accounting date",
      dataIndex: "accountingDate",
      key: "accountingDate",
    },
    { title: "Sales time", dataIndex: "salesTime", key: "salesTime" },
    { title: "Store", dataIndex: "store", key: "store" },
    {
      title: "Item",
      children: [
        {
          title: "Service & Product sales",
          dataIndex: "serviceProductSales",
          key: "serviceProductSales",
        },
        { title: "Card sales", dataIndex: "cardSales", key: "cardSales" },
      ],
    },
    { title: "Action", dataIndex: "action", key: "action" },
  ];

  return (
    <div
      style={{
        backgroundColor: "#f5f7fa",
        borderRadius: "8px",
      }}
    >
      {/* Tabs for Transaction History */}
      <Tabs defaultActiveKey="1" style={{ marginBottom: "20px" }}>
        <TabPane tab="Transaction history" key="1" />
        <TabPane tab="Previous transaction history" key="2" />
      </Tabs>

      {/* Table with Empty Data */}
      <Table
        columns={columns}
        dataSource={[]}
        locale={{
          emptyText: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <img
                src={emptyImageUrl}
                alt="No data available"
                style={{ marginBottom: "8px" }}
              />
              <Text type="secondary">No data available</Text>
            </div>
          ),
        }}
        pagination={false}
        bordered
      />
    </div>
  );
};

export default SalesHistory;
