import React from "react";
import { Modal, Typography, Table } from "antd";
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;

const { Title } = Typography;

interface PointDetailsModalProps {
  visible: boolean;
  onClose: () => void;
}

const PointDetailsModal: React.FC<PointDetailsModalProps> = ({
  visible,
  onClose,
}) => {
  return (
    <Modal
      visible={visible}
      title={
        <Title level={4} style={{ margin: 0 }}>
          Point Details
        </Title>
      }
      onCancel={onClose}
      footer={null}
      centered
      width={600}
    >
      {/* Header with Gradient Background */}
      <div
        style={{
          background: "linear-gradient(90deg, #FFB74D, #FF5722)",
          color: "#fff",
          textAlign: "center",
          padding: "10px",
          borderRadius: "8px",
          marginBottom: "16px",
        }}
      >
        <Title level={5} style={{ color: "#fff", margin: 0 }}>
          Total points
        </Title>
        <Title level={2} style={{ color: "#fff", margin: 0 }}>
          0
        </Title>
      </div>

      {/* Table with No Data */}
      <Table
        dataSource={[]}
        locale={{
          emptyText: (
            <div style={{ textAlign: "center" }}>
              <img
                src={emptyImageUrl} // Replace with your icon URL
                alt="No data available"
                style={{ marginBottom: "8px" }}
              />
              <p>No data available</p>
            </div>
          ),
        }}
        pagination={false}
      />
    </Modal>
  );
};

export default PointDetailsModal;
