import React, { useState } from 'react';
import { Modal, Button, Upload, message } from 'antd';
import { DeleteOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import './ImportModal.css';
import { post } from '../../services/Apiclient';

const ICON_FILL_TEMPLATE_FILE = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Fill_Template_File.png`;
const ICON_UPLOAD_TEMPLATE_FILE = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Upload_Template_File.png`;
const ICON_FILL_TEMPLATE_EXCEL = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Fill_Template_Excel.png`;

interface ImportModalProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (file: File) => void;
}

const ImportModal: React.FC<ImportModalProps> = ({ visible, onCancel, onSave }) => {
  const [fileList, setFileList] = useState<any[]>([]);

  const handleDownloadTemplate = () => {
    // Logic for downloading the template file
    console.log("Template downloaded");
  };

  const handleBeforeUpload = (file: any) => {
    // Check if there is already a file uploaded
    if (fileList.length >= 1) {
      alert("Only one file can be uploaded");
      return Upload.LIST_IGNORE; // Prevent uploading more than one file
    }
    setFileList([file]); // Replace the current file with the new one
    return false; // Prevent automatic upload
  };

  const handleRemoveFile = () => {
    setFileList([]); // Clear the file list
  };

  const handleCancel = () => {
    setFileList([]); // Reset file list state
    onCancel(); // Call the onCancel prop function to close the modal
  };

  // const uploadFile = async () => {
  //   const formData = new FormData();
  //   formData.append("file", fileList[0]); // Append the file

  //   try {
  //     const response = await post("/product/importproductcsv", formData, true); // Set `hasFormData` to true
  //     if (response?.data?.success) {
  //       message.success("File uploaded successfully");
  //       onSave(); // Call the onSave prop function
  //     } else {
  //       message.error(response?.data?.message || "Failed to upload the file");
  //     }
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //     message.error("Error uploading the file");
  //   }
  // };

  const handleSave = () => {
    if (fileList.length === 0) {
      message.error("Please upload a file before saving");
      return;
    }
    onSave(fileList[0]);
  };

  return (
    <Modal
      title="Upload File"
      visible={visible}
      onCancel={onCancel}
      width={900}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave} disabled={fileList.length === 0}>
          Save
        </Button>,
      ]}
    >
      <div className="modal-description">
        Upload a CSV file with your service data, or download and fill the template below.
      </div>

      <div className="modal-section">
        <div className="modal-icon">
          <img src={ICON_FILL_TEMPLATE_FILE} alt="Template Icon" />
        </div>
        <div className="modal-text">
          For the best quality upload, input your data into the template below, then upload the file to import.
        </div>
        <Button
          type="default"
          icon={<DownloadOutlined />}
          onClick={handleDownloadTemplate}
          className="download-button"
        >
          Download template
        </Button>
      </div>

      <div className="modal-section">
        {fileList.length === 0 ? (
          <Upload.Dragger
            name="file"
            accept=".csv"
            beforeUpload={handleBeforeUpload}
            className="upload-dragger modal-section"
            showUploadList={false}
          >
            <div className="modal-icon">
              <img src={ICON_UPLOAD_TEMPLATE_FILE} alt="Upload Icon" />
            </div>
            <div className="upload-text">Upload Excel / CSV file</div>
          </Upload.Dragger>
        ) : (
          <div className="uploaded-file-info">
            <div className="modal-icon">
              <img src={ICON_FILL_TEMPLATE_EXCEL} alt="Excel Icon" />
            </div>
            <span className="file-name">{fileList[0].name}</span>
            <Button 
              icon={<DeleteOutlined />} 
              onClick={handleRemoveFile} 
              className="delete-file-button" 
              shape="circle"
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ImportModal;
