import React from "react";
import { Modal, Button, Typography } from "antd";

const { Title, Text } = Typography;

interface NoteModalProps {
  visible: boolean;
  onClose: () => void;
  message: string;
}

const NoteModal: React.FC<NoteModalProps> = ({ visible, onClose, message }) => {
  return (
    <Modal
      title={null}
      visible={visible}
      footer={null}
      centered
      width="20vw"
      bodyStyle={{ textAlign: "center" }}
      maskClosable={false}
      closable={false}
      className="notes-modal"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h3 className="modal-title">Note</h3>
        <p className="modal-message">{message}</p>
      </div>
      <div className="modal-footer">
        <Button
          className="confirm-button"
          type="link"
          onClick={onClose}
          style={{
            color: "#1890ff",
            width: "100%",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default NoteModal;
