import React, { useState } from "react";
import { Modal, Button, Form, Input } from "antd";
import { EyeInvisibleOutlined, EyeOutlined  } from '@ant-design/icons';

interface AuthorizationModelProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (data: { account: string; password: string; confirmpassword: string }) => void;
  //handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  account: string;
}

const EditLoginPasswordModel: React.FC<AuthorizationModelProps> = ({
  visible,
  onCancel,
  onSave,
  account,
}) => {
  const [formData, setFormData] = useState({
    account: account,
    password: "",
    confirmpassword: "",
  });

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    onSave(formData);
  };
  
  return (
    <Modal
      title="Edit Login Password"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
      width={400}
      className="add-model"
    >
      <Form layout="vertical" style={{ marginTop: "20px" }}>
        <Form.Item
          label="Account"
          style={{ marginLeft: "24px", marginRight: "24px" }}
        >
          <Input
            name="account"
            value={account}
            onChange={handleFieldChange}
            disabled
          />
        </Form.Item>
        <Form.Item
          label="Password"
          style={{ marginLeft: "24px", marginRight: "24px",fontSize: "12px",
            color: "#666" }}
          required
        >
          <Input.Password
            iconRender={(visible) => (visible ? <EyeOutlined  /> : <EyeInvisibleOutlined />)}
            name="password"
            placeholder="Please enter"
            value={formData.password}
            onChange={handleFieldChange}
            
          />
        </Form.Item>

        <Form.Item
          label="Confirm password"
          style={{ marginLeft: "24px", marginRight: "24px",fontSize: "12px",
            color: "#666" }}
          required
        >
          <Input.Password
            name="confirmpassword"
            placeholder="Please enter"
            value={formData.confirmpassword}
            onChange={handleFieldChange}
            
          />
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "12px",
            marginTop: "40px",
            paddingTop: "10px",
            paddingBottom: "10px",
            border: "1px solid #D9D9D9",
          }}
        >
          <Button
            type="primary"
            onClick={handleSave}
            style={{ marginRight: "24px",background:"#2e56f2",color:"#fff",padding:"5px 12px",minWidth:"72px" }}
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditLoginPasswordModel;
