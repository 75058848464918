import React, { useState } from "react";
import { Modal, Button, Form, Input, Tag, Typography } from "antd";
import ImageUpload from "../../../imageUpload/ImageUpload";

const { Text } = Typography;

interface GalleryModelProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (data: { title: string; pricing: string }) => void;
  title: string;
  pricing: string;
}

const GalleryModel: React.FC<GalleryModelProps> = ({
  visible,
  onCancel,
  onSave,
  title,
  pricing,
}) => {
  const [formData, setFormData] = useState({
    title: title,
    pricing: pricing,
  });

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    onSave(formData);
  };
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const toggleTagSelection = (tag: string) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag)
        ? prevTags.filter((t) => t !== tag)
        : [...prevTags, tag]
    );
  };

  const getTagStyle = (tag: string) => ({
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "34px",
    borderRadius: "105px",
    padding: "0 24px",
    fontSize: "13px",
    fontFamily: "PingFang SC-Medium, PingFang SC",
    fontWeight: 500,
    marginRight: "17px",
    marginBottom: "8px",
    background: selectedTags.includes(tag) ? "#2e56f2" : "#e6e9f5",
    color: selectedTags.includes(tag) ? "#fff" : "#a1aaca",
  });

  return (
    <Modal
      title="Add Works"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
      width={578}
      className="add-model"
      bodyStyle={{ padding: 0 }}
    >
      <Form
        layout="vertical"
        style={{ height: 508, display: "flex", flexDirection: "column" }}
      >
        {/* Scrollable content */}
        <div style={{ flex: 1, overflowY: "auto", padding: "0 24px" }}>
          <div style={{ display: "flex", gap: "24px" }}>
            <Form.Item
              label={<Text strong>Title</Text>}
              style={{ flex: 1 }}
              required
            >
              <Input
                name="title"
                placeholder="Please enter"
                value={title}
                onChange={handleFieldChange}
              />
            </Form.Item>
            <Form.Item
              label={<Text strong>Pricing</Text>}
              style={{ flex: 1 }}
              required
            >
              <Input
                name="pricing"
                placeholder="Please enter"
                value={pricing}
                onChange={handleFieldChange}
              />
            </Form.Item>
          </div>

          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Work Tags
            </h3>
          </div>

          {/* Color Tags */}
          <div>
            <Text>Color</Text>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginBottom: "12px",
                marginTop: "10px",
              }}
            >
              <Tag
                style={getTagStyle("Cool colors")}
                onClick={() => toggleTagSelection("Cool colors")}
              >
                Cool colors
              </Tag>
              <Tag
                style={getTagStyle("warm color")}
                onClick={() => toggleTagSelection("warm color")}
              >
                warm color
              </Tag>
            </div>

            <Text>Style</Text>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginBottom: "12px",
                marginTop: "10px",
              }}
            >
              <Tag
                style={getTagStyle("Fashion")}
                onClick={() => toggleTagSelection("Fashion")}
              >
                Fashion
              </Tag>
              <Tag
                style={getTagStyle("cute")}
                onClick={() => toggleTagSelection("cute")}
              >
                cute
              </Tag>
              <Tag
                style={getTagStyle("Fresh")}
                onClick={() => toggleTagSelection("Fresh")}
              >
                Fresh
              </Tag>
              <Tag
                style={getTagStyle("nature")}
                onClick={() => toggleTagSelection("nature")}
              >
                nature
              </Tag>
              <Tag
                style={getTagStyle("Retro")}
                onClick={() => toggleTagSelection("Retro")}
              >
                Retro
              </Tag>
              <Tag
                style={getTagStyle("Popularity")}
                onClick={() => toggleTagSelection("Popularity")}
              >
                Popularity
              </Tag>
              <Tag
                style={getTagStyle("modern")}
                onClick={() => toggleTagSelection("modern")}
              >
                modern
              </Tag>
              <Tag
                style={getTagStyle("elegant")}
                onClick={() => toggleTagSelection("elegant")}
              >
                elegant
              </Tag>
            </div>

            <Text>Face shape</Text>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginBottom: "12px",
                marginTop: "10px",
              }}
            >
              <Tag
                style={getTagStyle("Round Face")}
                onClick={() => toggleTagSelection("Round Face")}
              >
                Round Face
              </Tag>
              <Tag
                style={getTagStyle("Square face")}
                onClick={() => toggleTagSelection("Square face")}
              >
                Square face
              </Tag>
              <Tag
                style={getTagStyle("oval")}
                onClick={() => toggleTagSelection("oval")}
              >
                oval
              </Tag>
              <Tag
                style={getTagStyle("Long Face")}
                onClick={() => toggleTagSelection("Long Face")}
              >
                Long Face
              </Tag>
            </div>

            <Text>Hairstyle</Text>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginBottom: "12px",
                marginTop: "10px",
              }}
            >
              <Tag
                style={getTagStyle("Short hair")}
                onClick={() => toggleTagSelection("Short hair")}
              >
                Short hair
              </Tag>
              <Tag
                style={getTagStyle("Long hair")}
                onClick={() => toggleTagSelection("Long hair")}
              >
                Long hair
              </Tag>
              <Tag
                style={getTagStyle("curls")}
                onClick={() => toggleTagSelection("curls")}
              >
                curls
              </Tag>
              <Tag
                style={getTagStyle("Straight Hair")}
                onClick={() => toggleTagSelection("Straight Hair")}
              >
                Straight Hair
              </Tag>
            </div>
          </div>

          <div>
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Showcase
            </h3>
            <Text style={{ color: "#999999" }}>
              Supported formats: jpg, png, mp4, avi, flv, mov <br />
              Max size: 30MB Max upload: 4
            </Text>
            <Form.Item style={{ marginTop: "10px" }}>
              <ImageUpload />
            </Form.Item>
          </div>
        </div>

        {/* Fixed Save button */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "10px 24px",
            borderTop: "1px solid #D9D9D9",
          }}
        >
          <Button
            type="primary"
            onClick={handleSave}
            style={{
              background: "#2e56f2",
              color: "#fff",
              padding: "5px 12px",
              minWidth: "72px",
            }}
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default GalleryModel;
