import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, Switch, Row, Col, message } from "antd";
import * as Yup from "yup";
import "./Product.css";
import ImageUpload from "../../components/imageUpload/ImageUpload";
import { Content } from "antd/es/layout/layout";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { get, post, put, uploadImg } from "../../services/Apiclient";
import ReminderModal from "../../components/model/ReminderModal";
import TextArea from "antd/es/input/TextArea";
const { Option } = Select;

const ProductAdd = () => {
  const navigate = useNavigate();
  const { productId } = useParams();

  const isUpdate = Boolean(productId);
  const isDuplicate = window.location.pathname.includes("duplicate");

  // Define Yup Validation Schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter product name"),
    productCode: Yup.string().required("Please enter product code"),
    category: Yup.string().required("Please select a category"),
    sellingPrice: Yup.string().required("Please enter selling price"),
    defaultWarehouse: Yup.string().required(
      "Please select a default Warehouse"
    ),
  });

  const initialFormValues = {
    images: [],
    name: "",
    usageStatus: true,
    barcode: "",
    productCode: "",
    category: "",
    supplier: null,
    brand: null,
    productDescription: "",
    costPrice: 0,
    sellingPrice: 0,
    tax: "",
    loyaltyType: null,
    loyaltyPoints: 0,
    purchaseQuantity: 1,
    purchaseUnit: "",
    standardQuantity: 1,
    standardUnit: "",
    consumptionQuantity: 1,
    consumptionUnit: "",
    qualityGuarantee: 0,
    qualityUnit: "month",
    defaultWarehouse: "",
    alertQuantity: 0,
    retailSales: false,
    batchManagement: false,
    note: "",
    outlet_id: localStorage.getItem("outlet_id"),
  };

  // State for Form Values
  const [imageUrls, setImageUrls] = useState([]);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [isConsumptionModalVisible, setIsConsumptionModalVisible] =
    useState(false);
  const [consumptionReminders, setConsumptionReminders] = useState([
    { interval: "Day", value: "" },
  ]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [loyaltyPointData, setLoyaltyPointData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [storageData, setStorageData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [imageArray, setImageArray] = useState<string[]>([]);
  const [alertQuantityUnitName, setAlertQuantityUnitName] = useState("");

  const handleDeleteImage = (images: any) => {
    const filenames = images.map((url: any) => url.split("/").pop());
    setImageArray(filenames); // Update image array after deletion
  };

  const handleImagesChange = async (formData: any) => {
    setImageUrls(formData);
  };

  // Handle input change
  const handleInputChange = (name: any, value: any) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "purchaseUnit") {
      const selectedUnit: any = unitData.find((unit: any) => unit.id === value);
      setAlertQuantityUnitName(selectedUnit ? selectedUnit.typename : "");
    }
  };
  const getFormattedDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = String(today.getFullYear()).slice(-2);
    return `${day}${month}${year}`;
  };

  const getInitialCounter = () => {
    const savedCounter = localStorage.getItem("productCodeCounter");
    const savedDate = localStorage.getItem("productCodeDate");

    const currentDate = getFormattedDate();

    if (savedDate !== currentDate) {
      localStorage.setItem("productCodeDate", currentDate);
      return 1;
    }

    return savedCounter ? parseInt(savedCounter, 10) : 1;
  };

  const [globalCounter, setGlobalCounter] = useState(getInitialCounter);

  const generateProductCode = async () => {
    try {
      const response = await get(`/user/randomgenerated`);
      if (response?.data?.success) {
        const data = response.data.result;
        handleInputChange("productCode", data);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to load room details");
    }
  };

  // const generateProductCode = () => {
  //   const currentDate = getFormattedDate();

  //   const newProductCode = `${currentDate}${String(globalCounter).padStart(
  //     3,
  //     "0"
  //   )}`;

  //   handleInputChange("productCode", newProductCode);

  //   const nextCounter = globalCounter + 1;
  //   setGlobalCounter(nextCounter);
  //   localStorage.setItem("productCodeCounter", nextCounter.toString());
  //   localStorage.setItem("productCodeDate", currentDate);
  // };

  const handleConsumptionSave = () => {
    setIsConsumptionModalVisible(false);
  };

  const handleReminderChange = (
    index: number,
    field: string,
    value: string | number
  ) => {
    const newReminders = consumptionReminders.map((reminder, i) =>
      i === index ? { ...reminder, [field]: value } : reminder
    );
    setConsumptionReminders(newReminders);
  };

  const addReminder = () => {
    const newReminder = { interval: "Day", value: "" };
    setConsumptionReminders([...consumptionReminders, newReminder]);
  };

  const removeReminder = (index: number) => {
    setConsumptionReminders(consumptionReminders.filter((_, i) => i !== index));
  };

  const showConsumptionModal = () => setIsConsumptionModalVisible(true);
  const handleConsumptionModalClose = () => setIsConsumptionModalVisible(false);

  const consumptionReminderText = consumptionReminders
    .filter((reminder) => reminder.value)
    .map((reminder) => `${reminder.value}`)
    .join(" / ");

  // handle require lable
  const requiredLabel = (label: any) => (
    <span>
      <span style={{ color: "red" }}>* </span>
      {label}
    </span>
  );

  // Handle form submission
  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formValues, { abortEarly: false });

      let productimg = [];
      const dayreminder = consumptionReminders
        .filter((reminder) => reminder.value)
        .map((reminder) => String(reminder.value));

      //Upload image first
      if (imageUrls) {
        try {
          const response = await uploadImg(
            "/product/uploadimage/images",
            imageUrls
          );
          if (response && response.data) {
            if (response.data.success) {
              productimg = response.data.data.map((img: any) => img.imagename);
            } else {
              console.log("Error in Api call: ", response.data.message);
            }
          } else {
            console.log("Response not found.");
          }

          console.log("Upload response:", response.data);
        } catch (error) {
          message.error("Error uploading images.");
          console.error("Upload error:", error);
        }
      }

      if (formValues) {
        let url =
          isUpdate == true && isDuplicate == false
            ? `/product/${productId}`
            : "/product/addproduct";

        let body = {
          productimg: productimg,
          productname: formValues.name,
          usagestatus: formValues.usageStatus,
          productbarcode: formValues.barcode,
          productcode: formValues.productCode,
          categorycode: formValues.category,
          dayreminder: dayreminder,
          suppliercode: formValues.supplier,
          brandcode: formValues.brand,
          productdescription: formValues.productDescription,
          costprice: formValues.costPrice,
          sellprice: formValues.sellingPrice,
          taxunit: formValues.tax,
          pointcategory: formValues.loyaltyType,
          point: formValues.loyaltyPoints,
          purchaseunit: formValues.purchaseQuantity,
          purchaseunittype: formValues.purchaseUnit,
          standardunit: formValues.standardQuantity,
          standardunittype: formValues.standardUnit,
          consumptionunit: formValues.consumptionQuantity,
          consumptionunittype: formValues.consumptionUnit,
          qualitygurantee: formValues.qualityGuarantee,
          qualityguranteeunit: formValues.qualityUnit,
          storageunit: formValues.defaultWarehouse,
          alertqty: formValues.alertQuantity,
          salesenable: formValues.retailSales,
          batchenable: formValues.batchManagement,
          note: formValues.note,
          outlet_id: formValues.outlet_id,
        };

        const response =
          isUpdate == true && isDuplicate == false
            ? await put(url, body)
            : await post(url, body);

        if (response && response.data) {
          if (response.data.success) {
            //message.success("Images uploaded successfully.");
            message.success("submitted successfully");
            setFormValues(initialFormValues);
            productimg = [];
            navigate("/settings/product/list");
          } else {
            message.error(response.data.message);
            console.log("Error in api call: ", response.data.message);
          }
        } else {
          console.log("Response not found.");
        }
      }
    } catch (errors: any) {
      if (errors instanceof Yup.ValidationError) {
        const firstError = errors.inner[0];
        if (firstError) {
          message.error(firstError.message);
        }
      }
    }
  };

  // Fetch room details for editing
  const getProductDetailsById = async () => {
    if (isUpdate && productId) {
      try {
        const response = await get(`/product/${productId}`);
        if (response?.data?.success) {
          const data = response.data.product;

          const images = response.data.images.map((img: any) => img.imageUrl);

          // Map dayReminders to the format expected by consumptionReminders
          const reminders = response.data.dayReminders.map(
            (reminder: string) => ({
              interval: "Day",
              value: reminder,
            })
          );

          console.log(images, "images");

          setFormValues({
            images: images,
            name: data.productname,
            usageStatus: data.usagestatus,
            barcode: data.productbarcode,
            productCode: isDuplicate ? "" : data.productcode,
            category: data.categorycode,
            supplier: data.suppliercode,
            brand: data.brandcode,
            productDescription: data.productdescription,
            costPrice: data.costprice,
            sellingPrice: data.sellprice,
            tax: data.taxunit,
            loyaltyType: data.pointcategory,
            loyaltyPoints: data.point,
            purchaseQuantity: data.purchaseunit,
            purchaseUnit: data.purchaseunittype,
            standardQuantity: data.standardunit,
            standardUnit: data.standardunittype,
            consumptionQuantity: data.consumptionunit,
            consumptionUnit: data.consumptionunittype,
            qualityGuarantee: data.qualitygurantee,
            qualityUnit: data.qualityguranteeunit,
            defaultWarehouse: data.storageunit,
            alertQuantity: data.alertqty,
            retailSales: data.salesenable,
            batchManagement: data.batchenable,
            note: data.note,
            outlet_id: data.outlet_id,
          });

          setConsumptionReminders(reminders);
        }
      } catch (error) {
        message.error("Failed to load room details");
      }
    }
  };

  useEffect(() => {
    if (isUpdate) {
      getProductDetailsById();
    }
  }, [isUpdate, productId]);

  useEffect(() => {
    if (unitData.length > 0 && formValues.purchaseUnit) {
      const selectedUnit: any = unitData.find(
        (unit: any) => unit.id === formValues.purchaseUnit
      );
      setAlertQuantityUnitName(selectedUnit ? selectedUnit.typename : "");
    }
  }, [formValues.purchaseUnit, unitData]);

  const getProductCatDropdown = async () => {
    try {
      let url = "/category/dropdown/type/Product";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setCategoriesData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getSupplierDropdown = async () => {
    try {
      let url = "/supplier/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setSupplierData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getTaxDropdown = async () => {
    try {
      let url = `/tax/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setTaxData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getLoyaltypointDropdown = async () => {
    try {
      let url = "/loyaltypoint/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setLoyaltyPointData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getUnitDropdown = async () => {
    try {
      let url = `/productunit/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setUnitData(response.data.data);
          formValues.purchaseUnit = response.data.data[0].id;
          formValues.consumptionUnit = response.data.data[0].id;
          formValues.standardUnit = response.data.data[0].id;
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getStorageDropdown = async () => {
    try {
      let url = `/storage/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setStorageData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getBrandDropdown = async () => {
    try {
      let url = "/brand/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setBrandData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  useEffect(() => {
    getProductCatDropdown();
    getSupplierDropdown();
    getTaxDropdown();
    getLoyaltypointDropdown();
    getUnitDropdown();
    getStorageDropdown();
    getBrandDropdown();
  }, []);

  return (
    <Content className="mainContainer">
      <Row justify="space-between" align="middle">
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={() => navigate("/settings/product/list")}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>
              {" "}
              {isUpdate == true && isDuplicate == false
                ? "Edit Product"
                : "Add New Product"}
            </h2>
          </div>
        </Col>
      </Row>

      <Content
        style={{
          marginTop: "20px",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          maxWidth: "1250px",
          marginLeft: "auto",
          marginRight: "auto",
          background: "#fafafa",
        }}
      >
        {/* Scrollable Form Content */}
        <div className="form-scrollable-content">
          <Form layout="vertical" onFinish={handleSubmit}>
            {/* Basic Info section */}
            <h2 style={{ fontSize: "16px" }}>Basic Info.</h2>

            <Form.Item>
              <ImageUpload
                onImagesChange={handleImagesChange}
                image={formValues.images}
                onDeleteImage={handleDeleteImage}
              />
            </Form.Item>

            <div className="row">
              <Form.Item
                label={requiredLabel("Product Name")}
                style={{ flex: 0.3 }}
              >
                <Input
                  placeholder="Please enter"
                  value={formValues.name}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                  style={{ height: "40px", width: "361px" }}
                />
              </Form.Item>

              <div
                className="margin-auto flex-half"
                style={{ justifyContent: "flex-start" }}
              >
                <Form.Item style={{ flex: 0.8, marginLeft: "15px" }}>
                  <span style={{ marginRight: 8 }}>Usage Status</span>
                  <Switch
                    checked={formValues.usageStatus}
                    onChange={(value) =>
                      handleInputChange("usageStatus", value)
                    }
                  />
                </Form.Item>
              </div>
            </div>

            <div className="">
              <Row gutter={32}>
                <Col span={8}>
                  <Form.Item label={"Bar Code"} style={{ flex: 0.4 }}>
                    <Input
                      placeholder="Please enter"
                      value={formValues.barcode}
                      onChange={(e) =>
                        handleInputChange("barcode", e.target.value)
                      }
                      style={{ height: "40px", width: "364px" }}
                    />
                  </Form.Item>
                </Col>
                {/* <Form.Item
                label={requiredLabel("Product Code")}
                style={{ flex: 0.3 }}
              >
                <Input
                  placeholder="Generated automatically"
                  value={formValues.productCode}
                  onChange={(e) =>
                    handleInputChange("productCode", e.target.value)
                  }
                />
              </Form.Item> */}
                <Col span={8}>
                  <Form.Item label={null} style={{ flex: 0.4 }}>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "8px",
                      }}
                    >
                      <label style={{ fontWeight: "500" }}>
                        {requiredLabel("Product Code")}
                      </label>
                      <a
                        onClick={generateProductCode}
                        style={{ color: "#325df2" }}
                      >
                        Generate automatically
                      </a>
                    </div>
                    <Input
                      placeholder="Please enter"
                      value={formValues.productCode}
                      onChange={(e) =>
                        handleInputChange("productCode", e.target.value)
                      }
                      style={{ width: "364px" }}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={requiredLabel("Category")}
                    style={{ flex: 0.4, marginLeft: "7px" }}
                  >
                    <Select
                      placeholder="Select Category"
                      value={formValues.category}
                      onChange={(value) => handleInputChange("category", value)}
                      dropdownStyle={{ textAlign: "center" }}
                      style={{ height: "40px", width: "370px" }}
                    >
                      {categoriesData.length > 0 &&
                        categoriesData.map((cate: any) => (
                          <Option value={cate.id} key={cate.id}>
                            {cate.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div className="row">
              <Form.Item
                label="Consumption Interval Reminder"
                style={{ flex: 0.4 }}
              >
                <Input
                  onClick={showConsumptionModal}
                  readOnly
                  value={consumptionReminderText}
                  prefix={<span className="textPrifix">Day</span>}
                  suffix={<span className="textSuffix">&gt;</span>}
                  style={{ height: "40px", width: "364px" }}
                />
              </Form.Item>
              <Form.Item label={"Supplier"} style={{ flex: 0.4 }}>
                <Select
                  placeholder="Select Supplier"
                  value={formValues.supplier}
                  onChange={(value) => handleInputChange("supplier", value)}
                  dropdownStyle={{ textAlign: "center" }}
                  style={{ height: "40px", width: "364px" }}
                >
                  {supplierData.length > 0 &&
                    supplierData.map((supplier: any) => (
                      <Option key={supplier.id} value={supplier.id}>
                        {supplier.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label={"Brand"} style={{ flex: 0.3 }}>
                <Select
                  placeholder="Select Brand"
                  value={formValues.brand}
                  onChange={(value) => handleInputChange("brand", value)}
                  style={{ height: "40px", width: "364px" }}
                >
                  {brandData.length > 0 &&
                    brandData.map((brand: any) => (
                      <Option key={brand.id} value={brand.id}>
                        {brand.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>

            <Form.Item label="Product Description">
              {/* <Input.TextArea
                placeholder="Please enter"
                value={formValues.productDescription}
                onChange={(e) =>
                  handleInputChange("productDescription", e.target.value)
                }
                style={{height:"80px !important",width:"1210px"}}
              /> */}
              <TextArea
                rows={6}
                placeholder="Please enter"
                style={{ width: "1280px", minHeight: "90px" }}
                autoSize={{ minRows: 4 }}
                value={formValues.productDescription}
                onChange={(e) =>
                  handleInputChange("productDescription", e.target.value)
                }
              />
            </Form.Item>

            {/* Pricing Section */}
            <h2 style={{ fontSize: "16px" }}>Pricing</h2>
            <div className="row">
              <Form.Item label="Cost Price" style={{ flex: 0.4 }}>
                <Input
                  placeholder="0"
                  type="number"
                  prefix={<span className="textWithPrifix">$</span>}
                  value={formValues.costPrice}
                  onChange={(e) =>
                    handleInputChange("costPrice", e.target.value)
                  }
                  style={{ height: "40px", width: "364px" }}
                />
              </Form.Item>

              <Form.Item
                label={requiredLabel("Selling Price")}
                style={{ flex: 0.4 }}
              >
                <Input
                  placeholder="Please enter"
                  type="number"
                  prefix={<span className="textWithPrifix">$</span>}
                  value={formValues.sellingPrice}
                  onChange={(e) =>
                    handleInputChange("sellingPrice", e.target.value)
                  }
                  style={{ height: "40px", width: "364px" }}
                />
              </Form.Item>

              <Form.Item label="Tax" style={{ flex: 0.4 }}>
                <Select
                  placeholder="No tax (0%)"
                  value={formValues.tax}
                  onChange={(value) => handleInputChange("tax", value)}
                  dropdownStyle={{ textAlign: "center" }}
                  style={{ height: "40px", width: "364px" }}
                >
                  {taxData.length > 0 &&
                    taxData.map((tax: any) => (
                      <Option key={tax.id} value={tax.id}>
                        {tax.taxname}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>

            {/* Loyalty Points Section */}
            <h2 style={{ fontSize: "16px" }}>Loyalty Points</h2>
            <div className="row">
              <Form.Item style={{ flex: 0.3 }}>
                <Select
                  placeholder="Please select"
                  value={formValues.loyaltyType ?? ""}
                  onChange={(value) => handleInputChange("loyaltyType", value)}
                  dropdownStyle={{ textAlign: "center" }}
                  style={{ height: "40px", width: "362px" }}
                >
                  {loyaltyPointData.length > 0 &&
                    loyaltyPointData.map((point: any) => (
                      <Option key={point.id} value={point.id}>
                        {point.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item style={{ flex: 0.3, marginLeft: "15px" }}>
                <Input
                  type="number"
                  placeholder="0"
                  suffix={
                    <span className="textWithSuffix">
                      {formValues.loyaltyType === "fixed" ? "Points" : "%"}
                    </span>
                  }
                  value={formValues.loyaltyPoints}
                  onChange={(e) =>
                    handleInputChange("loyaltyPoints", e.target.value)
                  }
                  style={{ height: "40px", width: "364px" }}
                />
              </Form.Item>
            </div>

            {/* Inventory Section */}
            <h2 style={{ fontSize: "16px" }}>Inventory</h2>
            <div className="rowWithOutGap">
              <Form.Item label="Purchase Unit" style={{ flex: 0.4 }}>
                <Input
                  type="number"
                  value={formValues.purchaseQuantity}
                  onChange={(e) =>
                    handleInputChange("purchaseQuantity", e.target.value)
                  }
                  style={{ height: "40px", width: "364px" }}
                  addonAfter={
                    <Select
                      value={formValues.purchaseUnit}
                      onChange={(value) =>
                        handleInputChange("purchaseUnit", value)
                      }
                      style={{ width: 80 }}
                    >
                      {unitData.length > 0 &&
                        unitData.map((unit: any) => (
                          <Option key={unit.id} value={unit.id}>
                            {unit.typename}
                          </Option>
                        ))}
                    </Select>
                  }
                />
              </Form.Item>

              <span
                style={{
                  fontSize: 16,
                  lineHeight: "32px",
                  padding: "0 2px",
                  marginLeft: "10px",
                  marginRight: "10px",
                  color: "#2e56f2",
                }}
              >
                =
              </span>

              <Form.Item label="Standard Unit" style={{ flex: 0.4 }}>
                <Input
                  type="number"
                  value={formValues.standardQuantity}
                  onChange={(e) =>
                    handleInputChange("standardQuantity", e.target.value)
                  }
                  style={{ height: "40px", width: "364px" }}
                  addonAfter={
                    <Select
                      value={formValues.standardUnit}
                      onChange={(value) =>
                        handleInputChange("standardUnit", value)
                      }
                      style={{
                        width: 80,
                      }}
                    >
                      {unitData.length > 0 &&
                        unitData.map((unit: any) => (
                          <Option key={unit.id} value={unit.id}>
                            {unit.typename}
                          </Option>
                        ))}
                    </Select>
                  }
                />
              </Form.Item>

              <span
                style={{
                  fontSize: 16,
                  lineHeight: "32px",
                  padding: "0 2px",
                  marginLeft: "10px",
                  marginRight: "10px",
                  color: "#2e56f2",
                  flex: 0.2,
                }}
              >
                =
              </span>

              <Form.Item label="Consumption Unit" style={{ flex: 0.4 }}>
                <Input
                  type="number"
                  value={formValues.consumptionQuantity}
                  onChange={(e) =>
                    handleInputChange("consumptionQuantity", e.target.value)
                  }
                  style={{ height: "40px", width: "364px" }}
                  addonAfter={
                    <Select
                      value={formValues.consumptionUnit}
                      onChange={(value) =>
                        handleInputChange("consumptionUnit", value)
                      }
                      style={{ width: 80 }}
                    >
                      {unitData.length > 0 &&
                        unitData.map((unit: any) => (
                          <Option key={unit.id} value={unit.id}>
                            {unit.typename}
                          </Option>
                        ))}
                    </Select>
                  }
                />
              </Form.Item>
            </div>

            <div className="row">
              <Form.Item label="Quality Guarantee" style={{ flex: 0.4 }}>
                <Input
                  placeholder="Please enter"
                  value={formValues.qualityGuarantee}
                  onChange={(e) =>
                    handleInputChange("qualityGuarantee", e.target.value)
                  }
                  style={{ height: "40px", width: "364px" }}
                  addonAfter={
                    <Select
                      value={formValues.qualityUnit}
                      onChange={(value) =>
                        handleInputChange("qualityUnit", value)
                      }
                      style={{ width: 80 }}
                    >
                      <Option value="day">day</Option>
                      <Option value="month">month</Option>
                      <Option value="year">year</Option>
                    </Select>
                  }
                />
              </Form.Item>

              <Form.Item
                label={requiredLabel("Default Consumption Warehouse")}
                style={{ flex: 0.4 }}
              >
                <Select
                  placeholder="Follow the parameter settings"
                  value={formValues.defaultWarehouse}
                  onChange={(value) =>
                    handleInputChange("defaultWarehouse", value)
                  }
                >
                  {storageData.length > 0 &&
                    storageData.map((unit: any) => (
                      <Option key={unit.id} value={unit.id}>
                        {unit.type}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item label="Alert Quantity" style={{ flex: 0.4 }}>
                <Input
                  placeholder="Please enter"
                  suffix={
                    <span className="textWithSuffix">
                      {/* {formValues.purchaseUnit} */}
                      {alertQuantityUnitName}
                    </span>
                  }
                  value={formValues.alertQuantity}
                  onChange={(e) =>
                    handleInputChange("alertQuantity", e.target.value)
                  }
                  style={{ height: "40px", width: "364px" }}
                />
              </Form.Item>
            </div>

            <div className="row-full">
              <Form.Item
                style={{
                  flex: 0.3,
                  display: "flex",
                  alignItems: "center",
                  padding: "15px 0 15px 24px",
                  marginBottom: "0px",
                }}
              >
                <span style={{ marginRight: 8 }}>Enable Retail Sales</span>
                <Switch
                  checked={formValues.retailSales}
                  onChange={(checked) =>
                    handleInputChange("retailSales", checked)
                  }
                  style={{ marginLeft: "40px" }}
                />
              </Form.Item>

              <Form.Item
                style={{
                  flex: 0.3,
                  display: "flex",
                  alignItems: "center",
                  padding: "15px 0 15px 24px",
                  marginBottom: "0px",
                }}
              >
                <span style={{ marginRight: 8 }}>Batch Management</span>
                <Switch
                  checked={formValues.batchManagement}
                  onChange={(checked) =>
                    handleInputChange("batchManagement", checked)
                  }
                  style={{ marginLeft: "40px" }}
                />
              </Form.Item>
            </div>

            {/* Inventory Section */}
            <h2 style={{ fontSize: "16px" }}>Note</h2>
            {/* <div className="row-textarea"> */}
            <Form.Item>
              {/* <Input.TextArea
                  placeholder="Please enter"
                  value={formValues.note}
                  style={{ width: "1280px",height:"80px",resize:"vertical" }}
                  onChange={(e) => handleInputChange("note", e.target.value)}
                /> */}
              <TextArea
                rows={6}
                placeholder="Please enter"
                style={{ width: "1210px !important", minHeight: "90px" }}
                autoSize={{ minRows: 4 }}
                value={formValues.note}
                onChange={(e) => handleInputChange("note", e.target.value)}
              />
            </Form.Item>
            {/* </div> */}
          </Form>
        </div>

        {/* Sticky Save Section */}
        <div className="save-section">
          <Button
            type="default"
            onClick={() => navigate("/settings/product/list")}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </Content>

      {/* <ReminderModal
        title="Consumption Interval Reminder"
        reminders={consumptionReminders}
        visible={isConsumptionModalVisible}
        onCancel={handleConsumptionModalClose}
        onSave={handleConsumptionModalClose}
        handleReminderChange={handleReminderChange}
        addReminder={addReminder}
        removeReminder={removeReminder}
        type="consumption"
        maxReminders={5}
      /> */}

      <ReminderModal
        title="Consumption Interval Reminder"
        reminders={consumptionReminders}
        visible={isConsumptionModalVisible}
        onCancel={handleConsumptionModalClose}
        onSave={handleConsumptionSave}
        handleReminderChange={(index, field, value) =>
          handleReminderChange(index, field, value)
        }
        addReminder={() => addReminder()}
        removeReminder={(index) => removeReminder(index)}
        type="consumption"
        maxReminders={5}
      />
    </Content>
  );
};

export default ProductAdd;
