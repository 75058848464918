import React, { useState } from "react";
import { Modal, Typography, Tabs, Table, Avatar, Row, Col } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;

const { Text, Title } = Typography;
const { TabPane } = Tabs;

interface DebtModalProps {
  visible: boolean;
  onClose: () => void;
}

const DebtModal: React.FC<DebtModalProps> = ({ visible, onClose }) => {
  const columns = [
    { title: "Debt owned Store", dataIndex: "store", key: "store" },
    { title: "Debt code", dataIndex: "debtCode", key: "debtCode" },
    {
      title: "Accounting date",
      dataIndex: "accountingDate",
      key: "accountingDate",
    },
    { title: "Bill code", dataIndex: "billCode", key: "billCode" },
    { title: "Item", dataIndex: "item", key: "item" },
    { title: "Action", dataIndex: "action", key: "action" },
  ];

  return (
    <Modal
      visible={visible}
      title={
        <Title level={4} style={{ margin: 0 }}>
          Debts
        </Title>
      }
      onCancel={onClose}
      footer={null}
      centered
      width={800}
    >
      {/* Header with Customer Info */}
      <div
        style={{
          backgroundColor: "#4CAF50",
          color: "#fff",
          borderRadius: "8px",
          padding: "16px",
          marginBottom: "16px",
        }}
      >
        <Row align="middle">
          <Col span={16}>
            <Row align="middle">
              <Avatar size="large" style={{ backgroundColor: "#87d068" }}>
                II
              </Avatar>
              <div style={{ marginLeft: "10px" }}>
                <Title level={5} style={{ color: "#fff", margin: 0 }}>
                  II vv
                </Title>
                <Text style={{ color: "#fff", fontSize: "14px" }}>
                  Customer code: <strong>241106017</strong>
                </Text>
                <Text
                  style={{
                    color: "#fff",
                    marginLeft: "16px",
                    fontSize: "14px",
                  }}
                >
                  Phone Number: **1232{" "}
                  <EyeInvisibleOutlined style={{ marginLeft: "5px" }} />
                </Text>
              </div>
            </Row>
          </Col>
          <Col span={8} style={{ textAlign: "right" }}>
            <Text style={{ color: "#fff", fontSize: "14px" }}>Amount: $0</Text>
            <br />
            <Text style={{ color: "#fff", fontSize: "14px" }}>Paid: $0</Text>
            <br />
            <Text style={{ color: "#fff", fontSize: "14px" }}>
              Remaining: $0
            </Text>
          </Col>
        </Row>
      </div>

      {/* Tabs Section */}
      <Tabs defaultActiveKey="1">
        <TabPane tab="Debt" key="1">
          <Table
            columns={columns}
            dataSource={[]}
            locale={{
              emptyText: (
                <div style={{ textAlign: "center" }}>
                  <img
                    src={emptyImageUrl} // Replace with your icon URL
                    alt="No data available"
                    style={{ marginBottom: "8px" }}
                  />
                  <p>No data available</p>
                </div>
              ),
            }}
            pagination={false}
          />
        </TabPane>
        <TabPane tab="Repayment record" key="2">
          <Table
            columns={columns}
            dataSource={[]}
            locale={{
              emptyText: (
                <div style={{ textAlign: "center" }}>
                  <img
                    src={emptyImageUrl} // Replace with your icon URL
                    alt="No data available"
                    style={{ marginBottom: "8px" }}
                  />
                  <p>No data available</p>
                </div>
              ),
            }}
            pagination={false}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default DebtModal;
