// src/components/ProtectedRoute.tsx
import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { userRoles } from "../constants/userRoles";

interface ProtectedRouteProps {
  children: JSX.Element;
  allowedRoles: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, allowedRoles }) => {
  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  try {
    const decodedToken: any = jwtDecode(token);
    const userRole = decodedToken?.tokendata?.role;

    if (!allowedRoles.includes(userRole)) {
        localStorage.setItem("token", "");
      return <Navigate to="/login" replace />;
    }

    return children;
  } catch (error) {
    console.error("Token decoding failed:", error);
    localStorage.setItem("token", "");
    return <Navigate to="/login" replace />;
  }
};

export default ProtectedRoute;
