import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Switch,
  Row,
  Col,
  message,
  Modal,
  Layout,
  Table,
  Empty,
} from "antd";
import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import "./Service.css";
import ReminderModal from "./Model";
import ServiceProductModel from "../../components/model/service/ServiceProductModel";
import ImageUpload from "../../components/imageUpload/ImageUpload";
import {
  deleteData,
  get,
  post,
  put,
  uploadImg,
} from "../../services/Apiclient";
import ConfirmDeleteModal from "../../components/model/DeleteModal";

const { Option } = Select;
const { Content } = Layout;

const ServiceAdd: React.FC = () => {
  const navigate = useNavigate();
  const { serviceId } = useParams();

  const isUpdate = Boolean(serviceId);

  const [isConsumptionModalVisible, setIsConsumptionModalVisible] =
    useState(false);
  const [isFollowUpModalVisible, setIsFollowUpModalVisible] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [loyaltyPointData, setLoyaltyPointData] = useState([]);
  const [durationData, setDurationData] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [editableProductKey, setEditableProductKey] = useState<string | null>(
    null
  );
  const [editQuantity, setEditQuantity] = useState<number | null>(null);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [consumptionReminders, setConsumptionReminders] = useState<any[]>([]);
  const [performanceAttributeData, setPerformanceAttributeData] = useState<
    any[]
  >([]);
  const [designationData, setDesignationData] = useState<any[]>([]);
  const [followUpReminders, setFollowUpReminders] = useState<any[]>([]);
  const [followupReminderText, setFollowupReminderText] = useState("");
  const [consumptionReminderText, setConsumptionReminderText] = useState("");

  const [isDeleteStaffModalVisible, setIsDeleteStaffModalVisible] =
    useState<boolean>(false);
  const [isDeleteProductModalVisible, setIsDeleteProductModalVisible] =
    useState<boolean>(false);
  const [editingRecord, setEditingRecord] = useState<any>(null);

  const getFormattedDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = String(today.getFullYear()).slice(-2);
    return `${day}${month}${year}`;
  };

  const generateServiceCode = async () => {
    try {
      const response = await get(`/user/randomgenerated`);
      if (response?.data?.success) {
        const data = response.data.result;
        handleInputChange("servicecode", data);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to load room details");
    }
  };

  // const performanceAttributeData = [
  //   { id: "Skin", name: "Skin" },
  //   {
  //     id: "Doctor",
  //     name: "Doctor",
  //   },
  //   {
  //     id: "Nail",
  //     name: "Nail",
  //   },
  //   {
  //     id: "Beauty",
  //     name: "Beauty",
  //   },
  //   {
  //     id: "Head Therapy",
  //     name: "Head Therapy",
  //   },
  //   {
  //     id: "Hair Dressing",
  //     name: "Hair Dressing",
  //   },
  // ];

  const scopeData = [
    {
      id: "All",
      name: "All",
    },
    {
      id: "Staff",
      name: "Staff",
    },
    {
      id: "Designation",
      name: "Designation",
    },
  ];

  const validationSchema = Yup.object().shape({
    servicename: Yup.string().required("Please enter name"),
    servicecode: Yup.string().required("Please enter service code"),
    categorycode: Yup.string().required("Please select a category"),
    duration: Yup.string().required("Please select duration"),
    costprice: Yup.number().required("Please enter price"),
  });

  const initialFormValues = {
    serviceimage: [],
    servicename: "",
    usagestatus: true,
    servicecode: "",
    categorycode: "",
    performanceattribute: "",
    servicedescription: "",
    duration: "",
    costprice: 0,
    taxunit: null,
    setpricemember: false,
    servicestaff: [],
    loyaltypoint: null,
    loyaltypointper: 0,
    consumptiondays: [],
    followupdays: [],
    productdetails: [],
    onlinebooking: true,
    scope: "All",
    applicablefor: null,
    note: "",
    outlet_id: localStorage.getItem("outlet_id"),
  };

  const [formValues, setFormValues] = useState(initialFormValues);

  const openDeleteStaffModal = () => setIsDeleteStaffModalVisible(true);
  const closeDeleteStaffModal = () => {
    setIsDeleteStaffModalVisible(false);
  };

  const handleDeleteStaff = async () => {
    if (editingRecord) {
    }
  };

  const handleConfirmStaff = () => {
    setStaffList(staffList.filter((_, i) => i !== editingRecord));
    closeDeleteStaffModal();
  };

  const handleDeleteStaffApi = async (id: string) => {
    try {
    } catch (error) {
      message.error("Error deleting Service");
    }
  };

  const openDeleteProductModal = () => setIsDeleteProductModalVisible(true);
  const closeDeleteProductModal = () => {
    setIsDeleteProductModalVisible(false);
  };

  const handleConfirmProduct = () => {
    // handleDeleteApi(editingRecord.key);
    setSelectedProducts((prevProducts) =>
      prevProducts.filter((product) => product.productid !== editingRecord)
    );
    closeDeleteProductModal();
  };

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formValues, { abortEarly: false });

      let serviceimg: any[] = [];

      let newImageNames = [];
      try {
        const response = await uploadImg(
          "/service/uploadimage/images",
          imageUrls
        );
        if (response && response.data) {
          if (response.data.success) {
            newImageNames = response.data.data.map((img: any) => img.imagename);
            message.success("Images uploaded successfully.");
          } else {
            console.log("Error in Api call: ", response.data.message);
          }
        } else {
          console.log("Response not found.");
        }
        console.log("Upload response:", response.data);
      } catch (error) {
        message.error("Error uploading images.");
        console.error("Upload error:", error);
      }

      // Append new image names to the existing image arra
      const updatedImageArray = [...imageArray, ...newImageNames];

      const consumptiondayreminder = consumptionReminders
        .filter((reminder) => reminder.value)
        .map((reminder) => String(reminder.value));

      const followupreminder = followUpReminders
        .filter((reminder) => reminder.value)
        .map((reminder) => String(reminder.value));

      if (formValues) {
        let url = isUpdate ? `/service/${serviceId}` : "/service/addservice";

        let body = {
          serviceimage: updatedImageArray,
          servicename: formValues.servicename,
          usagestatus: formValues.usagestatus,
          servicecode: formValues.servicecode,
          categorycode: formValues.categorycode,
          performanceattribute: formValues.performanceattribute,
          servicedescription: formValues.servicedescription,
          duration: formValues.duration,
          costprice: formValues.costprice,
          taxunit: formValues.taxunit,
          setpricemember: formValues.setpricemember,
          servicestaff: staffList,
          loyaltypoint: formValues.loyaltypoint,
          loyaltypointper: formValues.loyaltypointper,
          consumptiondays: consumptiondayreminder,
          followupdays: followupreminder,
          productdetails: selectedProducts,
          onlinebooking: formValues.onlinebooking,
          scope: formValues.scope,
          applicablefor: formValues.applicablefor,
          note: formValues.note,
          outlet_id: formValues.outlet_id,
        };

        const response = isUpdate
          ? await put(url, body)
          : await post(url, body);

        if (response && response.data) {
          if (response.data.success) {
            message.success("Form submitted successfully!");
            setFormValues(initialFormValues);
            setImageUrls([]);
            setImageArray([]);
            navigate("/settings/service/list");
          } else {
            console.log("Error in api call: ", response.data.message);
          }
        } else {
          console.log("Response not found.");
        }
      }
    } catch (errors) {
      if (errors instanceof Yup.ValidationError) {
        const firstError = errors.inner[0];
        if (firstError) {
          message.error(firstError.message);
        }
      }
    }
  };

  const getServiceDetailsById = async () => {
    if (isUpdate && serviceId) {
      try {
        const response = await get(`/service/${serviceId}`);
        if (response?.data?.success) {
          const data = response.data.service;

          const prefilledImages = response.data.images.map(
            (img: any) => img.imageUrl
          );
          const prefilledImageNames = response.data.images.map(
            (img: any) => img.name
          );
          setImageUrls(prefilledImages);
          setImageArray(prefilledImageNames);

          // Map dayReminders to the format expected by consumptionReminders
          const consumptionday = response.data.consumptionday.map(
            (reminder: string) => ({
              interval: "Day",
              value: reminder,
            })
          );

          const followupday = response.data.followupday.map(
            (reminder: string) => ({
              interval: "Day",
              value: reminder,
            })
          );

          setFormValues({
            serviceimage: prefilledImages,
            servicename: data.servicename,
            usagestatus: data.usagestatus,
            servicecode: data.servicecode,
            categorycode: data.categorycode,
            performanceattribute: data.performanceattribute,
            servicedescription: data.servicedescription,
            duration: data.duration,
            costprice: data.costprice,
            taxunit: data.taxunit,
            setpricemember: data.setpricemember,
            servicestaff: [],
            loyaltypoint: data.loyaltypoint,
            loyaltypointper: data.loyaltypointper,
            consumptiondays: data.consumptiondays,
            followupdays: data.followupdays,
            productdetails: data.productdetails,
            onlinebooking: data.onlinebooking,
            scope: data.scope,
            applicablefor: data.applicablefor,
            note: data.note,
            outlet_id: data.outlet_id,
          });

          setStaffList(response.data.servicestaff);
          setSelectedProducts(response.data.productDetails);
          setConsumptionReminders(consumptionday);
          let consumptionReminderText = consumptionday
            .filter((reminder: any) => reminder.value)
            .map((reminder: any) => `${reminder.value}`)
            .join(" / ");
          setConsumptionReminderText(consumptionReminderText);
          setFollowUpReminders(followupday);
          let followupReminderText = followupday
            .filter((reminder: any) => reminder.value)
            .map((reminder: any) => `${reminder.value}`)
            .join(" / ");
          setFollowupReminderText(followupReminderText);
        }
      } catch (error) {
        console.log(error);
        message.error("Failed to load room details");
      }
    }
  };

  const handleInputChange = (name: string, value: string | boolean | null) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getStaffDropdown = async () => {
    try {
      let url = "/staff/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setStaffData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getDepartmentDropdown = async () => {
    try {
      let url = `/department/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setPerformanceAttributeData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getDesignationDropdown = async () => {
    try {
      let url = `/designation/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setDesignationData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getServiceCatDropdown = async () => {
    try {
      let url = "/category/dropdown/type/Service";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setCategoriesData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getLoyaltypointDropdown = async () => {
    try {
      let url = "/loyaltypoint/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setLoyaltyPointData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getDurationDropdown = async () => {
    try {
      let url = "/service/duration";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setDurationData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getTaxDropdown = async () => {
    try {
      let url = `/tax/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setTaxData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const requiredLabel = (label: string) => (
    <span>
      <span style={{ color: "red" }}>* </span>
      {label}
    </span>
  );

  const handleDeleteImage = (images: any) => {
    const filenames = images.map((url: any) => url.split("/").pop());
    setImageArray(filenames);
  };

  const handleImagesChange = (formData: any) => {
    setImageUrls(formData);
  };

  const showConsumptionModal = () => setIsConsumptionModalVisible(true);
  const showFollowUpModal = () => setIsFollowUpModalVisible(true);
  const handleConsumptionModalClose = () => {
    setIsConsumptionModalVisible(false);
    let consumptionReminderText = consumptionReminders
      .filter((reminder) => reminder.value)
      .map((reminder) => `${reminder.value}`)
      .join(" / ");
    setConsumptionReminderText(consumptionReminderText);
  };
  const handleFollowUpModalClose = () => {
    setIsFollowUpModalVisible(false);
    let followupReminderText = followUpReminders
      .filter((reminder) => reminder.value)
      .map((reminder) => `${reminder.value}`)
      .join(" / ");
    setFollowupReminderText(followupReminderText);
  };

  const handleReminderChange = (
    index: number,
    field: string,
    value: string | number,
    type: string
  ) => {
    if (typeof value !== "number") {
      return;
    }

    if (type === "consumption") {
      const newReminders: any = consumptionReminders.map((reminder: any, i) =>
        i === index ? { ...reminder, [field]: value } : reminder
      );
      setConsumptionReminders(newReminders);
    } else {
      const newReminders: any = followUpReminders.map((reminder: any, i) =>
        i === index ? { ...reminder, [field]: value } : reminder
      );
      setFollowUpReminders(newReminders);
    }
  };

  const addReminder = (type: string) => {
    const newReminder = { interval: "Day", value: "" };
    if (type === "consumption") {
      setConsumptionReminders([...consumptionReminders, newReminder]);
    } else {
      setFollowUpReminders([...followUpReminders, newReminder]);
    }
  };

  const removeReminder = (index: number, type: string) => {
    if (type === "consumption") {
      setConsumptionReminders(
        consumptionReminders.filter((_, i) => i !== index)
      );
    } else {
      setFollowUpReminders(followUpReminders.filter((_, i) => i !== index));
    }
  };

  const [staffList, setStaffList] = useState<any[]>([]);

  const handleSaveProducts = (selectedData: any[]) => {
    const updatedProducts = selectedData.map((product) => {
      const existingProduct = selectedProducts?.find(
        (p) => p.productid === product.productid
      );

      return {
        ...product,
        unit: existingProduct?.unit == null ? "" : existingProduct.unit,
        qty: existingProduct?.qty || 1, // If no existing product, set quantity to 1
      };
    });

    setSelectedProducts(updatedProducts);
  };

  // Handle quantity change for editable table
  const handleQuantityChange = (value: number, productid: string) => {
    const updatedProducts = selectedProducts.map((product) =>
      product.productid === productid ? { ...product, qty: value } : product
    );
    setSelectedProducts(updatedProducts);
  };

  const handleStaffChange = (index: number, field: string, value: string) => {
    const newStaffList = staffList.map((staff, i) =>
      i === index ? { ...staff, [field]: value } : staff
    );
    setStaffList(newStaffList);
  };

  const addStaff = () => {
    setStaffList([...staffList, { staffid: "", duration: "", price: "" }]);
  };

  const removeStaff = (index: number) => {
    setEditingRecord(index);
    openDeleteStaffModal();
  };

  const [isOnlineBookingEnabled, setIsOnlineBookingEnabled] = useState(false);
  const [scope, setScope] = useState("Staff");
  const [availableFor, setAvailableFor] = useState<string[]>([]);

  const handleBookingToggle = (checked: boolean) => {
    setIsOnlineBookingEnabled(checked);
  };

  const handleScopeChange = (value: string) => {
    setScope(value);
  };

  const handleAvailableForChange = (value: string[]) => {
    setAvailableFor(value);
  };

  // Handle start editing a product's quantity
  const handleEdit = (productid: string, currentQty: number) => {
    setEditableProductKey(productid);
    setEditQuantity(currentQty);
  };

  // Handle save edited quantity
  const handleSaveEdit = (productid: string) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.productid === productid
          ? { ...product, qty: editQuantity }
          : product
      )
    );
    setEditableProductKey(null);
    setEditQuantity(null);
  };

  // Handle cancel editing
  const handleCancelEdit = () => {
    setEditableProductKey(null);
    setEditQuantity(null);
  };

  const handleDeleteProduct = (productid: string) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.filter((product) => product.productid !== productid)
    );
  };

  const [data, setData] = useState([]);
  // Table columns definition
  const columns = [
    {
      title: "Product code",
      dataIndex: "productcode",
      key: "productcode",
    },
    {
      title: "Product name",
      dataIndex: "productname",
      key: "productname",
    },
    {
      title: "Qty.",
      dataIndex: "qty",
      key: "qty",
      render: (text: number, record: any) =>
        editableProductKey === record.key ? (
          <Input
            type="number"
            min={1}
            value={editQuantity !== null ? editQuantity : 0}
            onChange={(e) => setEditQuantity(Number(e.target.value))}
            style={{ width: 60 }}
          />
        ) : (
          text
        ),
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any): React.ReactNode => {
        const isEditing = editableProductKey === record.productid;
        return isEditing ? (
          <>
            <Button
              type="primary"
              style={{ fontWeight: 700, marginRight: "8px" }}
              onClick={() => handleSaveEdit(record.productid)}
            >
              Save
            </Button>
            <Button
              type="default"
              style={{ fontWeight: 700 }}
              onClick={handleCancelEdit}
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            <a
              style={{
                color: "#2e56f2",
                textDecoration: "underline",
                fontWeight: 700,
                marginRight: "16px",
              }}
              onClick={() => handleEdit(record.productid, record.qty)}
            >
              Edit
            </a>
            <a
              style={{
                color: "rgb(245, 63, 63)",
                fontWeight: 700,
                textDecoration: "underline",
              }}
              onClick={() => {
                setEditingRecord(record.productid);
                openDeleteProductModal();
                // handleDeleteProduct(record.productid)
              }}
            >
              Delete
            </a>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (isUpdate) {
      getServiceDetailsById();
    }
  }, [isUpdate, serviceId]);

  useEffect(() => {
    if (formValues.scope == "staff") {
      getServiceDetailsById();
    }
  }, [formValues.scope]);

  useEffect(() => {
    getServiceCatDropdown();
    getLoyaltypointDropdown();
    getDurationDropdown();
    getTaxDropdown();
    getStaffDropdown();
    getDepartmentDropdown();
    getDesignationDropdown();
  }, []);

  return (
    <Content className="mainContainer">
      <Row justify="space-between" align="middle">
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              onClick={() => navigate("/settings/service/list")}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>
              {" "}
              {isUpdate ? "Edit Service" : "Add New Service"}
            </h2>
          </div>
        </Col>
      </Row>

      <Content
        style={{
          margin: "10px 100px",
          backgroundColor: "hsla(0, 0%, 100%, .5)",
          borderRadius: "12px",
          boxSizing: "border-box",
          maxWidth: "1250px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Form layout="vertical" onFinish={handleSubmit}>
          <div className="form-scrollable-content">
            <h2 style={{ fontSize: "16px" }}>Basic Info.</h2>

            <Form.Item>
              <ImageUpload
                prefilledImages={imageUrls}
                onImagesChange={handleImagesChange}
                onDeleteImage={handleDeleteImage}
              />
            </Form.Item>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  label={requiredLabel("Service Name")}
                  style={{ flex: 0.3 }}
                >
                  <Input
                    placeholder="Please enter"
                    value={formValues.servicename}
                    onChange={(e) =>
                      handleInputChange("servicename", e.target.value)
                    }
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                <Form.Item>
                  <span style={{ marginRight: 8 }}>Usage Status</span>
                  <Switch
                    checked={formValues.usagestatus}
                    onChange={(value) =>
                      handleInputChange("usagestatus", value)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label={null}>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <label style={{ fontWeight: "500" }}>
                      {requiredLabel("Service Code")}
                    </label>
                    <a
                      onClick={generateServiceCode}
                      style={{ color: "#325df2" }}
                    >
                      Generate automatically
                    </a>
                  </div>
                  <Input
                    placeholder="Please enter"
                    value={formValues.servicecode}
                    onChange={(e) =>
                      handleInputChange("servicecode", e.target.value)
                    }
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={requiredLabel("Category")}>
                  <Select
                    placeholder="Please select category"
                    value={formValues.categorycode}
                    onChange={(value) =>
                      handleInputChange("categorycode", value)
                    }
                    dropdownStyle={{ textAlign: "center" }}
                  >
                    {categoriesData.length > 0 &&
                      categoriesData.map((cate: any) => (
                        <Option value={cate.id} key={cate.id}>
                          {cate.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Performance attribution">
                  <Select
                    placeholder="Please select"
                    value={formValues.performanceattribute}
                    onChange={(value) =>
                      handleInputChange("performanceattribute", value)
                    }
                    dropdownStyle={{ textAlign: "center" }}
                  >
                    {performanceAttributeData.length > 0 &&
                      performanceAttributeData.map((unit: any) => (
                        <Option key={unit.id} value={unit.id}>
                          {unit.departmenttype}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={24}>
                <Form.Item label="Service Description">
                  <Input.TextArea
                    rows={3}
                    placeholder="Please enter"
                    value={formValues.servicedescription}
                    style={{ minHeight: "80px" }}
                    onChange={(e) =>
                      handleInputChange("servicedescription", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <h2 style={{ fontSize: "16px", marginBottom: "20px" }}>Pricing</h2>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label={requiredLabel("Duration")}>
                  <Select
                    placeholder="Select duration"
                    value={formValues.duration}
                    onChange={(value) => handleInputChange("duration", value)}
                    dropdownStyle={{ textAlign: "center" }}
                  >
                    {durationData.length > 0 &&
                      durationData.map((point: any) => (
                        <Option key={point.id} value={point.id}>
                          {point.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={requiredLabel("Price")}>
                  <Input
                    prefix="$"
                    placeholder="Please enter"
                    value={formValues.costprice}
                    onChange={(e) =>
                      handleInputChange("costprice", e.target.value)
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Tax">
                  <Select
                    placeholder="No tax (0%)"
                    value={formValues.taxunit}
                    onChange={(value) => handleInputChange("taxunit", value)}
                    dropdownStyle={{ textAlign: "center" }}
                  >
                    {taxData.length > 0 &&
                      taxData.map((tax: any) => (
                        <Option key={tax.id} value={tax.id}>
                          {tax.taxname}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <div className="booking-toggle">
              <h3 className="label">Set Price By Team Member</h3>
              <Switch
                checked={formValues.setpricemember}
                onChange={(checked) => {
                  handleInputChange("setpricemember", checked);
                  setStaffList([]);
                }}
              />
            </div>

            {formValues.setpricemember && (
              <div className="staff-table">
                <Row gutter={24}>
                  <Col span={8}>
                    <h4>Staff name</h4>
                  </Col>
                  <Col span={8}>
                    <h4>Duration</h4>
                  </Col>
                  <Col span={8}>
                    <h4>Price</h4>
                  </Col>
                </Row>
                {staffList.map((staff, index) => (
                  <Row gutter={24} key={index} className="staff-row">
                    <Col span={8}>
                      <Select
                        value={staff.staffid}
                        placeholder="Select Staff"
                        onChange={(value) =>
                          handleStaffChange(index, "staffid", value)
                        }
                        style={{ width: "100%" }}
                        dropdownStyle={{ textAlign: "center" }}
                      >
                        {staffData.length > 0 &&
                          staffData.map((point: any) => (
                            <Option key={point.id} value={point.id}>
                              {point.name}
                            </Option>
                          ))}
                      </Select>
                    </Col>

                    <Col span={8}>
                      <Select
                        value={staff.duration}
                        placeholder="Select duration"
                        onChange={(value) =>
                          handleStaffChange(index, "duration", value)
                        }
                        style={{ width: "100%" }}
                        dropdownStyle={{ textAlign: "center" }}
                      >
                        {durationData.length > 0 &&
                          durationData.map((point: any) => (
                            <Option key={point.id} value={point.id}>
                              {point.name}
                            </Option>
                          ))}
                      </Select>
                    </Col>
                    <Col span={7}>
                      <Input
                        value={staff.price}
                        placeholder="Please enter"
                        onChange={(e) =>
                          handleStaffChange(index, "price", e.target.value)
                        }
                        style={{ width: "100%" }}
                      />
                    </Col>
                    <Col span={1} className="delete-icon">
                      <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={() => removeStaff(index)}
                      />
                    </Col>
                  </Row>
                ))}
                <Button
                  type="link"
                  style={{ color: "#2e56f2" }}
                  onClick={addStaff}
                  className="add-staff-button"
                >
                  + Add staff
                </Button>
              </div>
            )}

            <h2 style={{ fontSize: "16px", marginBottom: "20px" }}>
              Loyalty Points
            </h2>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item>
                  <Select
                    placeholder="Please select"
                    dropdownStyle={{ textAlign: "center" }}
                    value={formValues.loyaltypoint}
                    onChange={(value) =>
                      handleInputChange("loyaltypoint", value)
                    }
                    allowClear
                  >
                    {loyaltyPointData.length > 0 &&
                      loyaltyPointData.map((point: any) => (
                        <Option key={point.id} value={point.id}>
                          {point.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  <Input
                    placeholder="Please enter"
                    value={formValues.loyaltypointper}
                    onChange={(e) =>
                      handleInputChange("loyaltypointper", e.target.value)
                    }
                    suffix={formValues.loyaltypoint == "fixed" ? "points" : "%"}
                  />
                </Form.Item>
              </Col>
            </Row>

            <h2 style={{ fontSize: "16px", marginBottom: "20px" }}>
              Customer Service
            </h2>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="Consumption Interval Reminder">
                  <Input
                    onClick={showConsumptionModal}
                    readOnly
                    value={consumptionReminderText}
                    prefix={<span className="textPrifix">Day</span>}
                    suffix={<span className="textSuffix">&gt;</span>}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Follow-up Interval">
                  <Input
                    onClick={showFollowUpModal}
                    readOnly
                    value={followupReminderText}
                    prefix={<span className="textPrifix">Day</span>}
                    suffix={<span className="textSuffix">&gt;</span>}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <h2 style={{ fontSize: "16px", margin: 0 }}>
                Product Consumption
              </h2>
              <a
                href="#"
                onClick={showModal}
                style={{ color: "#325df2", marginLeft: "10px" }}
              >
                +Add
              </a>
            </div>

            <Table
              columns={columns}
              dataSource={selectedProducts}
              locale={{
                emptyText: (
                  <div style={{ textAlign: "center" }}>
                    <Empty description="No data available" />
                  </div>
                ),
              }}
              pagination={false}
            />
            <div
              className="service-add-container booking-toggle"
              style={{ marginTop: "40px" }}
            >
              <h3 className="label">Enable Online Booking</h3>

              <Switch
                checked={formValues.onlinebooking}
                onChange={(checked) =>
                  handleInputChange("onlinebooking", checked)
                }
              />
            </div>
            <div className="service-add-container">
              <Row gutter={24} className="staff-row">
                <Col span={8}>
                  <div className="form-group">
                    <label className="label">Scope of application</label>
                    <Select
                      className="select-input"
                      value={formValues.scope}
                      onChange={(value) => handleInputChange("scope", value)}
                      style={{ width: "100%" }}
                      dropdownStyle={{ textAlign: "center" }}
                    >
                      {scopeData.length > 0 &&
                        scopeData.map((point: any) => (
                          <Option key={point.id} value={point.id}>
                            {point.name}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </Col>

                {formValues.scope !== "All" && (
                  <Col span={8}>
                    <div className="form-group">
                      <label className="label">Available for</label>
                      <Select
                        mode="multiple"
                        allowClear
                        className="select-input"
                        placeholder="Please select"
                        value={formValues.applicablefor}
                        onChange={(value) =>
                          handleInputChange("applicablefor", value)
                        }
                        style={{ width: "100%" }}
                        dropdownStyle={{ textAlign: "center" }}
                        maxTagCount={2}
                        maxTagPlaceholder={(omittedValues) =>
                          `+ ${omittedValues.length}...`
                        }
                      >
                        {designationData &&
                          formValues.scope == "Designation" &&
                          designationData.length > 0 &&
                          designationData.map((designation: any) => (
                            <Option value={designation.id} key={designation.id}>
                              {designation.designationtype}
                            </Option>
                          ))}
                        {staffData &&
                          formValues.scope == "Staff" &&
                          staffData.length > 0 &&
                          staffData.map((staff: any) => (
                            <Option value={staff.id} key={staff.id}>
                              {staff.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
            <Row gutter={24} className="staff-row">
              <Col span={24}>
                <div className="form-group note-section">
                  <label className="label">Note</label>
                  <Input.TextArea
                    placeholder="Please enter"
                    className="textarea-input"
                    style={{ width: "100%", minHeight: "80px" }}
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div
            style={{
              textAlign: "right",
              padding: "24px",
              borderTop: "1px solid #e0e7fe",
            }}
          >
            <Button type="primary" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </Form>
      </Content>

      <ReminderModal
        title="Consumption Interval Reminder"
        reminders={consumptionReminders}
        visible={isConsumptionModalVisible}
        onCancel={handleConsumptionModalClose}
        onSave={handleConsumptionModalClose}
        handleReminderChange={handleReminderChange}
        addReminder={addReminder}
        removeReminder={removeReminder}
        type="consumption"
        maxReminders={5}
      />

      <ReminderModal
        title="Follow-up Interval"
        reminders={followUpReminders}
        visible={isFollowUpModalVisible}
        onCancel={handleFollowUpModalClose}
        onSave={handleFollowUpModalClose}
        handleReminderChange={handleReminderChange}
        addReminder={addReminder}
        removeReminder={removeReminder}
        type="followup"
        maxReminders={5}
      />
      <ServiceProductModel
        title="Product"
        visible={isModalVisible}
        onCancel={handleCancel}
        onSave={handleSaveProducts}
        selectedProducts={selectedProducts}
        footer={null}
      />
      <ConfirmDeleteModal
        visible={isDeleteStaffModalVisible}
        onCancel={closeDeleteStaffModal}
        onConfirm={handleConfirmStaff}
        message="Delete the Staff?"
      />
      <ConfirmDeleteModal
        visible={isDeleteProductModalVisible}
        onCancel={closeDeleteProductModal}
        onConfirm={handleConfirmProduct}
        message="Delete the Product?"
      />
    </Content>
  );
};

export default ServiceAdd;
