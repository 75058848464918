import React, { useEffect, useState } from "react";
import { Modal, Typography, Input, Button } from "antd";
import { put } from "../../../services/Apiclient";
import { useParams } from "react-router-dom";

const { TextArea } = Input;
const { Text } = Typography;

interface RemarkModalProps {
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  value: string | null | undefined;
}

const RemarkModal: React.FC<RemarkModalProps> = ({
  visible,
  onClose,
  onConfirm,
  value,
}) => {
  const { id } = useParams();
  const [remark, setRemark] = useState<string>("");
  const maxLength = 2000;
  useEffect(() => {
    setRemark(value || "");
  }, [value]);

  const handleRemarkChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRemark(e.target.value);
  };

  const handleConfirm = async () => {
    let payload = {
      notes: remark,
    };
    await put(`/customer/updatedata/${id}`, payload);
    console.log("Remark:", remark);
    onConfirm();
    onClose(); // Close the modal after confirming
  };

  return (
    <Modal
      visible={visible}
      title="Remark"
      onCancel={onClose}
      footer={null}
      centered
      bodyStyle={{ paddingBottom: "0" }}
    >
      <TextArea
        placeholder="Please enter"
        value={remark}
        onChange={handleRemarkChange}
        maxLength={maxLength}
        autoSize={{ minRows: 6, maxRows: 6 }}
        style={{ marginBottom: "10px", resize: "none" }}
      />
      <div style={{ textAlign: "right", marginBottom: "20px" }}>
        <Text type="secondary">
          {remark.length} / {maxLength}
        </Text>
      </div>
      <div style={{ textAlign: "right" }}>
        <Button type="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default RemarkModal;
