import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Radio,
  Button,
  Switch,
  Row,
  Col,
  message,
} from "antd";
import * as Yup from "yup";
import "./Room.css";
import ImageUpload from "../../components/imageUpload/ImageUpload";
import { Content } from "antd/es/layout/layout";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import CategoryModal from "../../components/selectCategoryModal/SelectCategoryModal";
import { get, post, put, uploadImg } from "../../services/Apiclient";
import TextArea from "antd/es/input/TextArea";
import ServiceCategoryModel from "../../components/model/ServiceCategoryModel";
const { Option } = Select;

const RoomAdd = () => {
  const navigate = useNavigate();
  const { roomId } = useParams();

  const isUpdate = Boolean(roomId);
  const isDuplicate = window.location.pathname.includes("duplicate");

  // Define Yup Validation Schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter room name"),
    roomCode: Yup.string().required("Please enter room code"),
    category: Yup.string().required("Please select a category"),
    applicableFor: Yup.string().required("Please select an applicable option"),
  });

  const initialFormValues = {
    name: "",
    usageStatus: true,
    roomCode: "",
    category: "",
    roomDescription: "",
    applicableFor: "All Service",
    categories: [],
    images: [],
  };

  // State for Form Values
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [imageArray, setImageArray] = useState([]);

  const [isServiceModalVisible, setIsServiceModalVisible] = useState(false);
  const [selectedService, setSelectedService] = useState<any[]>([]);
  const [roomTypeData, setRoomTypeData] = useState<any[]>([]);

  const showModal = () => {
    setIsServiceModalVisible(true);
  };

  const handleCancel = () => {
    setIsServiceModalVisible(false);
  };

  const handleSaveServices = (selectedData: any[]) => {
    const updatedService = selectedData.map((service) => ({
      serviceid: service.key, // Assuming productid is equivalent to serviceid
      servicename: service.servicename,
      servicecode: service.servicecode, // Assuming you have a `code` property in the service data
      price: service.price || "0", // Assuming price is included or setting to "0" by default
    }));

    setSelectedService(updatedService);
    handleInputChange("services", updatedService); // Store services data in formValues
    setIsServiceModalVisible(false);
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formValues, { abortEarly: false });

      let newImageNames = [];
      //Upload image first
      try {
        const response = await uploadImg("/room/uploadimage/images", imageUrls);
        if (response && response.data) {
          if (response.data.success) {
            newImageNames = response.data.data.map((img: any) => img.imagename);
            message.success("Images uploaded successfully.");
          } else {
            console.log("Error in Api call: ", response.data.message);
          }
        } else {
          console.log("Response not found.");
        }
        console.log("Upload response:", response.data);
      } catch (error) {
        message.error("Error uploading images.");
        console.error("Upload error:", error);
      }

      // Append new image names to the existing image arra
      const updatedImageArray = [...imageArray, ...newImageNames];

      if (formValues) {
        let body = {
          name: formValues.name,
          status: formValues.usageStatus ? "Active" : "InActive",
          code: formValues.roomCode,
          category_id: formValues.category,
          applicable: formValues.applicableFor,
          description: formValues.roomDescription,
          roomimage: updatedImageArray,
          categories: formValues.categories,
          services: selectedService,
        };

        let url =
          isUpdate == true && isDuplicate == false
            ? `/room/${roomId}`
            : "/room/addroom";

        const response =
          isUpdate == true && isDuplicate == false
            ? await put(url, body)
            : await post(url, body);

        if (response && response.data) {
          if (response.data.success) {
            message.success("Saved successfully");
            setFormValues(initialFormValues);
            setImageUrls([]);
            setImageArray([]);
            navigate("/settings/room/list");
          } else {
            console.log("Error in api call: ", response.data.message);
          }
        } else {
          console.log("Response not found.");
        }
      }
    } catch (errors: any) {
      // Handle validation errors
      if (errors instanceof Yup.ValidationError) {
        const firstError = errors.inner[0];
        if (firstError) {
          message.error(firstError.message);
        }
      }
    }
  };

  const getCategoryDropdown = async () => {
    try {
      let url = "/category/dropdown/type/Room";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setCategoriesData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getCategoryAllList = async () => {
    try {
      let url = "/category/getlistwithoutpagination/Service";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setCategoriesList(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getRoomTypesDropdown = async () => {
    try {
      let url = `/roomtype/dropdown/list/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setRoomTypeData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  // Fetch room details for editing
  const fetchRoomDetails = async () => {
    if (isUpdate && roomId) {
      try {
        const response = await get(`/room/${roomId}`);
        if (response?.data?.success) {
          const roomData = response.data.data;
          const prefilledImages = roomData.images.map(
            (img: any) => img.imageUrl
          );
          const prefilledImageNames = roomData.images.map(
            (img: any) => img.name
          );
          setImageUrls(prefilledImages);
          setImageArray(prefilledImageNames);

          // Map services for pre-filling in the modal
          const prefilledServices = roomData.services.map((service: any) => ({
            key: service.id,
            serviceid: service.id,
            servicename: service.servicename,
            servicecode: service.servicecode,
            price: service.costprice,
          }));
          // Set selected services for the modal
          setSelectedService(prefilledServices);

          setFormValues({
            name: roomData.name,
            usageStatus: roomData.status === "Active",
            roomCode: isDuplicate ? "" : roomData.code,
            category: roomData.category_id,
            roomDescription: roomData.description,
            applicableFor: roomData.applicable,
            categories: roomData.categories,
            images: prefilledImages,
          });
        }
      } catch (error) {
        message.error("Failed to load room details");
      }
    }
  };

  useEffect(() => {
    getCategoryDropdown();
    getCategoryAllList();
    getRoomTypesDropdown();
  }, []);

  useEffect(() => {
    getCategoryDropdown();
    if (isUpdate) {
      fetchRoomDetails();
    }
  }, [isUpdate, roomId]);

  //save selected categories
  const handleSaveCategories = (categories: any) => {
    const formattedCategories = categories.map((cat: any) => ({
      categoryid: cat.id,
      categoryname: cat.name,
    }));
    setFormValues((prev) => ({
      ...prev,
      categories: formattedCategories,
    }));
  };

  const handleDeleteImage = (images: any) => {
    const filenames = images.map((url: any) => url.split("/").pop());
    setImageArray(filenames);
  };

  const handleImagesChange = (formData: any) => {
    setImageUrls(formData);
  };

  const getFormattedDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = String(today.getFullYear()).slice(-2);
    return `${day}${month}${year}`;
  };

  const getInitialCounter = () => {
    const savedCounter = localStorage.getItem("roomCodeCounter");
    const savedDate = localStorage.getItem("roomCodeDate");

    const currentDate = getFormattedDate();

    if (savedDate !== currentDate) {
      localStorage.setItem("roomCodeDate", currentDate);
      return 1;
    }

    return savedCounter ? parseInt(savedCounter, 10) : 1;
  };

  // Handle input change
  const handleInputChange = (name: any, value: any) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [globalCounter, setGlobalCounter] = useState(getInitialCounter);

  const generateRoomCode = async () => {
    try {
      const response = await get(`/user/randomgenerated`);
      if (response?.data?.success) {
        const data = response.data.result;
        handleInputChange("roomCode", data);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to load room details");
    }
  };

  // const generateRoomCode = () => {
  //   const currentDate = getFormattedDate();

  //   const newRoonCode = `${currentDate}${String(globalCounter).padStart(
  //     3,
  //     "0"
  //   )}`;

  //   handleInputChange("roomCode", newRoonCode);

  //   const nextCounter = globalCounter + 1;
  //   setGlobalCounter(nextCounter);
  //   localStorage.setItem("roomCodeCounter", nextCounter.toString());
  //   localStorage.setItem("roomCodeDate", currentDate);
  // };

  // handle require lable
  const requiredLabel = (label: any) => (
    <span>
      <span style={{ color: "red" }}>* </span>
      {label}
    </span>
  );

  return (
    <Content className="mainContainer">
      <Row justify="space-between" align="middle">
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>
              {isUpdate ? "Update Room" : "Add New Room"}
            </h2>
          </div>
        </Col>
      </Row>

      <Content
        style={{
          marginTop: "10px",
          backgroundColor: "#fafafa",
          borderRadius: "12px",
          border: "1px solid #e0e7fe",
          boxSizing: "border-box",
          height: "600px",
          display: "flex",
          flexDirection: "column",
          maxWidth: "1100px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {/* Scrollable Form Content */}
        <div className="form-scrollable-content">
          <Form
            style={{ paddingTop: "15px" }}
            layout="vertical"
            onFinish={handleSubmit}
          >
            <h2 style={{ fontSize: "16px" }}>Basic Info.</h2>

            {/* Image Upload */}
            <Form.Item>
              <ImageUpload
                prefilledImages={imageUrls}
                onImagesChange={handleImagesChange}
                onDeleteImage={handleDeleteImage}
              />
            </Form.Item>

            <div className="row">
              {/* Room Name */}
              <Form.Item
                label={requiredLabel("Room Name")}
                style={{ flex: 0.3 }}
              >
                <Input
                  placeholder="Please enter"
                  value={formValues.name}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                />
              </Form.Item>

              {/* Usage Status */}
              <div
                className="margin-auto flex-half"
                style={{ justifyContent: "flex-start" }}
              >
                <Form.Item style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: 8 }}>Usage Status</span>
                  <Switch
                    checked={formValues.usageStatus}
                    onChange={(value) =>
                      handleInputChange("usageStatus", value)
                    }
                  />
                </Form.Item>
              </div>
            </div>

            <div className="row">
              {/* Room Code */}
              {/* <Form.Item
                label={requiredLabel("Room Code")}
                style={{ flex: 0.3 }}
              >
                <Input
                  placeholder="Generated automatically"
                  value={formValues.roomCode}
                  onChange={(e) =>
                    handleInputChange("roomCode", e.target.value)
                  }
                />
              </Form.Item> */}
              <Col span={7}>
                <Form.Item label={null}>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <label>{requiredLabel("Room Code")}</label>
                    <a onClick={generateRoomCode} style={{ color: "#325df2" }}>
                      Generate automatically
                    </a>
                  </div>
                  <Input
                    placeholder="Please enter"
                    value={formValues.roomCode}
                    onChange={(e) =>
                      handleInputChange("roomCode", e.target.value)
                    }
                    allowClear
                  />
                </Form.Item>
              </Col>

              {/* Category */}
              <Form.Item
                label={requiredLabel("Category")}
                style={{ flex: 0.3 }}
              >
                <Select
                  placeholder="Select Category"
                  value={formValues.category}
                  onChange={(value) => handleInputChange("category", value)}
                >
                  {roomTypeData.length > 0 &&
                    roomTypeData.map((room: any) => (
                      <Option key={room.id} value={room.id}>
                        {room.roomtype}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </div>

            <Form.Item label="Room Description">
              <TextArea
                rows={6}
                placeholder="Please enter"
                style={{ width: "1210px !important", minHeight: "90px" }}
                autoSize={{ minRows: 4 }}
                value={formValues.roomDescription}
                onChange={(e) =>
                  handleInputChange("roomDescription", e.target.value)
                }
              />
            </Form.Item>

            <h2 style={{ fontSize: "16px" }}>Applicable For</h2>
            <div className="row-full">
              <Form.Item
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "15px 0 15px 24px",
                  marginBottom: "0px",
                }}
              >
                <Radio.Group
                  value={formValues.applicableFor}
                  onChange={(e) =>
                    handleInputChange("applicableFor", e.target.value)
                  }
                >
                  <Radio value="All Service">All services</Radio>
                  <Radio value="By Category">By category</Radio>
                  <Radio value="By item">By item</Radio>
                </Radio.Group>
              </Form.Item>
              <Row
                justify="start"
                style={{ padding: "0px 0px 0px 10px", marginBottom: "10px" }}
              >
                {formValues.applicableFor === "By Category" && (
                  <Button
                    type="link"
                    onClick={() => setIsModalVisible(true)}
                    // className="catBtn"
                    style={{
                      fontSize: "14px",
                      boxShadow: "none",
                      color: "#2e56f2",
                      textDecoration: "underline",
                      textUnderlineOffset: "4px",
                      fontWeight: "400",
                    }}
                  >
                    Select Category
                  </Button>
                )}

                {formValues.applicableFor === "By Category" && (
                  <label style={{ marginTop: "5px" }}>
                    Selected: {formValues.categories.length} items
                  </label>
                )}

                {/* {formValues.applicableFor === "By Category" && formValues.categories.length > 0 && (
    <Button
      type="link"
      onClick={() => setIsModalVisible(true)}
      style={{
        marginTop: "5px",
        fontSize: "14px",
        boxShadow: "none",
        color: "#2e56f2",
        textDecoration: "underline",
        textUnderlineOffset: "4px",
        fontWeight: "400",
      }}
    >
      Selected: {formValues.categories.length} items
    </Button>
  )} */}

                {formValues.applicableFor === "By item" && (
                  <Button
                    type="link"
                    onClick={() => showModal()}
                    style={{
                      fontSize: "14px",
                      boxShadow: "none",
                      color: "#2e56f2",
                      textDecoration: "underline",
                      textUnderlineOffset: "4px",
                      fontWeight: "400",
                    }}
                  >
                    Select item
                  </Button>
                )}

                {formValues.applicableFor === "By item" &&
                  selectedService.length > 0 && (
                    <label style={{ marginTop: "5px" }}>
                      Selected: {selectedService.length} items
                    </label>
                  )}
              </Row>
            </div>
          </Form>
        </div>

        {/* Sticky Save Section */}
        <div className="save-section">
          <Button
            type="default"
            onClick={() => navigate(-1)}
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </Content>
      {/* Category Modal */}
      <CategoryModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onSave={handleSaveCategories}
        selectedCategories={formValues.categories}
        categoriesList={categoriesList}
      />

      <ServiceCategoryModel
        title="Services"
        visible={isServiceModalVisible}
        onCancel={handleCancel}
        onSave={handleSaveServices}
        selectedServices={selectedService}
        footer={null}
      />
    </Content>
  );
};

export default RoomAdd;
