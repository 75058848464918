import React from "react";
import { Empty, Typography } from "antd";
const emptyIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noResults.png`;

const { Text } = Typography;

const FormSection: React.FC = () => {
  return (
    <div
      style={{
        padding: "40px",
        backgroundColor: "#f5f7fa",
        borderRadius: "8px",
        textAlign: "center",
      }}
    >
      <Empty
        image={emptyIcon}
        imageStyle={{
          height: 80,
        }}
        description={
          <Text type="secondary" style={{ fontSize: "16px" }}>
            No results found
          </Text>
        }
      />
    </div>
  );
};

export default FormSection;
