import React, { useEffect, useState } from "react";
import { Layout, Button, Input, Row, Col, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { deleteData, get } from "../../services/Apiclient";
import Datatable from "../../components/Datatable";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "../../styles/global.css";
import { log } from "console";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
const { Content } = Layout;

interface MerchantData {
  key: React.Key;
  username: string;
  email: string;
  name: string;
  parentstore: string;
  address: string;
}

function MerchantList() {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<MerchantData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [editingRecord, setEditingRecord] = useState<any>(null);

  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);

  const openDeleteModal = () => setIsDeleteModalVisible(true);
  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const handleDelete = async () => {
    if (editingRecord) {
      try {
        openDeleteModal();
      } catch (error) {
        message.error(`Error deleting merchant`);
      }
    }
  };

  const handleConfirm = () => {
    handleDeleteApi(editingRecord.key);
    closeDeleteModal();
  };

  const handleDeleteApi = async (id: string) => {
    try {
      const response = await deleteData(`/merchant/${id}`);
      if (response.data.success) {
        message.success("Merchant deleted successfully!");
        fetchData();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error("Error deleting Merchant");
    }
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      width: 150,
      sorter: (a: MerchantData, b: MerchantData) =>
        a.username.localeCompare(b.username),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    // {
    //   title: "Parent Store",
    //   dataIndex: "parentstore",
    //   key: "parentstore",
    //   width: 150,
    // },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 300,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right" as "right",
      width: 100,
      render: (text: any, record: any): React.ReactNode => (
        <>
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
            }}
            onClick={() => navigate(`/merchant/edit/${record.key}`)}
          >
            Edit
          </a>
          {/* <a style={{ color: 'red' }} onClick={() => handleDeleteClick(record)} > */}
          <a
            style={{
              color: "rgb(245, 63, 63)",
              fontWeight: 700,
              textDecoration: "underline",
            }}
            onClick={() => {
              setEditingRecord(record);
              handleDelete();
            }}
          >
            Delete
          </a>
        </>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      const url = `/merchant/list/${currentPage}/${pageSize}`;
      const response = await get(url);
      if (response?.data?.success) {
        const apiData = response.data.data.map((item: any) => ({
          key: item.id,
          username: item.username,
          email: item.email,
          name: item.name,
          parentstore: item.parentstore,
          address: item.address,
        }));
        setDataSource(apiData);
        setTotalRecords(response.data.totalRecords);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize]);

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  return (
    <Content className="mainContainer">
      {/* Header Section */}
      <Row justify="space-between" align="middle">
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
              />
            </div> */}
            <h2 style={{ margin: 0 }}>
              <span className="vertical-line"></span>Merchant Management
            </h2>
          </div>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate("/merchant/add")}
          >
            Add
          </Button>
        </Col>
      </Row>

      {/* Content Section */}
      <Content
        style={{
          marginTop: "10px",
          backgroundColor: "#f6f7f9",
          borderRadius: "12px",
          padding: "24px 24px 10px",
          border: "1px solid #e0e7fe",
          boxSizing: "border-box",
        }}
      >
        {/* Filters */}
        <Row gutter={16} style={{ marginBottom: "16px" }}>
          <Col flex="auto">
            <Input.Search
              placeholder="Search by customer code, name, phone number, email"
              allowClear
              style={{ width: "30%" }}
              className="custom-search-input"
            />
          </Col>
        </Row>

        {/* Table */}
        <Datatable<MerchantData>
          columns={columns}
          dataSource={dataSource}
          currentPage={currentPage}
          pageSize={pageSize}
          totalRecords={totalRecords}
          onPageChange={handlePageChange}
          scroll={{ y: 500 }}
          showCheckbox={true} // Custom scroll settings for this instance
        />

        {/* Bottom Buttons */}
        <Row justify="start">
          <Col>
            <Button disabled type="default" style={{ marginRight: "10px" }}>
              Delete all selected
            </Button>
          </Col>
        </Row>
      </Content>
      <ConfirmDeleteModal
        visible={isDeleteModalVisible}
        onCancel={closeDeleteModal}
        // onConfirm={() => handleDeleteApi(editingRecord.key)}
        onConfirm={handleConfirm}
        message="Delete the Merchant?"
      />
    </Content>
  );
}

export default MerchantList;
